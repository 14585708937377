import {safe_get} from "../../report-visuals";
import {get_booth_node_by_boothnumber} from "../../custom-hooks/useBoothNumber";

export type BeneficiaryType = {
  name: string;
  phone: string;
  whatsapp: string;
  voter_id: string;
  aadhar_id: string;
  address: string;
  caste: string;
  dob: string;
  age: number;
  booth_number: object;
  village: object;
  gender: string;
  is_outsider: boolean;
  ac_name: string;
  oacv_name: string;
  oacv_phone: string;
};

export const beneficiaryDefaultValues: BeneficiaryType = {
  name: "",
  phone: "",
  whatsapp: "",
  voter_id: "",
  aadhar_id: "",
  address: "",
  caste: "",
  dob: "",
  age: 0,
  booth_number: {},
  village: {},
  gender: "",
  is_outsider: false,
  ac_name: "",
  oacv_name: "",
  oacv_phone: "",
};

//TODO: If user selected from voter list, then the beneficiary form should pre-fill with voter data.
export const getBeneficiaryDefaultValues = (voter) => {
    console.log(voter)
    return {
        name: safe_get(voter, "profile.name_eng", ""),
        phone: safe_get(voter, "phone", ""),
        whatsapp: safe_get(voter, "whatsapp", null),
        voter_id: safe_get(voter, "profile.voterid", ""),
        aadhar_id:  safe_get(voter, "aadhar_id", ""),
        address:  safe_get(voter, "profile.address", ""),
        caste:  safe_get(voter, "caste", ""),
        dob:  safe_get(voter, "profile.dob", ""),
        age:  safe_get(voter, "profile.age", ""),
        booth_number: get_booth_node_by_boothnumber(safe_get(voter, "part", {})),
        village: get_booth_node_by_boothnumber(safe_get(voter, "part", {})),
        gender:  safe_get(voter, "profile.gender", "").toLowerCase(),
        is_outsider: false,
        ac_name: "",
        oacv_name: "",
        oacv_phone: ""
    }
}

export const GENDERS = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Others", value: "others" },
];
