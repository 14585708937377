import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useStore } from "../../../helpers";
import { toast } from "react-toastify";
import {useBeneficiaryMutationSuccess} from "./useBeneficiaryMutationSuccess";

export const useAddBeneficiary = () => {

    const { projectid } = useParams();
    const rootStore = useStore();
    const { beneficiaryStore,beneficiaryStoreV2} = rootStore;
    const {onMutationSuccess} = useBeneficiaryMutationSuccess();

    const { mutate: addBeneficiary, isLoading } = useMutation({
        mutationFn: async (values: any) => {
            const payload = {
                key: "beneficiary",
                renderkey: "beneficiary",
                properties: {
                    projectid: parseInt(projectid),
                    summary: {
                        profile: values,
                        tracing_id: `P${projectid}B`,
                    },
                    r1: {
                        logs: [],
                        family: beneficiaryStoreV2.familyMembers,
                        requests: beneficiaryStoreV2.requests,
                    },
                },
            };
            await beneficiaryStore.createBeneficiary(payload);
        },
        onSuccess: async () => {
            await onMutationSuccess();
            toast.success("Beneficiary added successfully");
            beneficiaryStoreV2.update_add_beneficiary_modal(false);
        },
        onError: async () => {
            toast.error("Something went wrong");
        },
    });

    return {
        addBeneficiary,
        isLoading,
    };
};
