import * as yup from 'yup';

export const userEditValidationSchema = yup.object().shape({
	name: yup.string().required('Name is required'),
	phonenum: yup
		.string()
		.required('Phone Number is Required')
		.test('phone', 'Please Enter a valid phone number', (value) => {
			return /^[0-9]{10}$/.test(value);
		}),
	role: yup.string().required('Role is Required'),
	tags: yup.array().of(yup.string())
});
