import React, {useRef, useState} from 'react';
import {MapContainer, GeoJSON, Marker, useMap, Popup, Tooltip, useMapEvents} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import L from 'leaflet';
import {toJS} from 'mobx';
import {useWindowSize} from "react-use";
import {Switch} from "@headlessui/react";
import {observer} from "mobx-react-lite";
import {useStore, classNames} from "../helpers";
import {useTranslation} from 'react-i18next';
import MarkerClusterGroup from 'react-leaflet-cluster'
import _ from "lodash"
import {safe_get} from "../report-visuals";
import {DataNotAvailable} from "../flexistore/renderkeys/mh-2024-field";
import {Typography} from "@mui/material";


export const custom_icon = new L.DivIcon({
    className: 'booth-icon',
    html: `<i class="fa-solid fa-map-pin text-3xl cursor-pointer" style="color: #B03A2E"/>`
});


const SetBounds = ({bounds}) => {
    const map = useMap();
    map.fitBounds(bounds);
    return <></>;
}

const RenderMarker = props => {
    const {poly} = props;
    const polygon = poly["geometry"];
    const region = poly["properties"]["region"]
    const booth_no = poly["properties"]["PS_NO"];
    if (!polygon) return;

    const PS_Name = safe_get(poly, "properties.PS_NO", "")

    const booth_number_label = new L.DivIcon({
        className: 'booth-icon',
        html: `<div class="booth-icon cursor-pointer">${PS_Name ? PS_Name : region}</div>`
    });

    const pos = L.geoJSON(polygon).getBounds().getCenter();

    return (
        <Marker
            position={pos}
            icon={booth_number_label}
        >
            <Popup>{region}<br/>{`Booth #${booth_no}`}</Popup>
            <Tooltip>{region}<br/>{`Booth #${booth_no}`}</Tooltip>
        </Marker>
    )
}

const RenderMarkerCluster = observer((props: any) => {
    const rootStore = useStore();
    const {marker_data} = props;

    return (
        <MarkerClusterGroup>
            {marker_data && marker_data.length > 0 && marker_data.map(item => {
                return (
                    <>
                        {item.lat && item.long &&
                        <Marker
                            key={item.id}
                            icon={custom_icon}
                            position={{lat: item.lat, lng: item.long}}
                            eventHandlers={{
                                click: (e) => {
                                    rootStore.surveyResponseStore.setActiveSurveyResponseId(item.id);
                                }
                            }}
                        >
                            {/*<Popup>{item.id}</Popup>*/}
                            <Tooltip>{item.id} ({item.lat}, {item.long})</Tooltip>
                        </Marker>}
                    </>
                )
            })}
        </MarkerClusterGroup>
    )
})

const RenderColorMapping = props => {
    const {color, region} = props;
    return (
        <div className='flex flex-row items-center mx-2'>
            <div style={{backgroundColor: color, width: 40, height: 20}}/>
            <div className='ml-2'>{region}</div>
        </div>
    )
}

const ShowBoothNumbers = observer((props: any) => {
    const rootStore = useStore();
    const {flexiStore} = rootStore;
    const {show_booth_numbers} = flexiStore;
    const {t} = useTranslation("leafletacMap");

    return (
        <>
            <div className='flex items-center py-2'>
                <label className="font-bold">{t("show_booth_numbers")} :</label>
                <Switch
                    checked={show_booth_numbers}
                    onChange={() => flexiStore.set_show_booth_numbers(!show_booth_numbers)}
                    className={classNames(
                        show_booth_numbers ? 'bg-indigo-600' : 'bg-gray-200',
                        'mx-3 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                >
                        <span
                            aria-hidden="true"
                            className={classNames(
                                show_booth_numbers ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                        />
                </Switch>
            </div>
        </>
    )
})

//TODO: This component is to get the current location, when user click on the map.
const LocationFinder = () => {
    const [locations, setLocations] = useState([]);
    const map = useMapEvents({
        click(e) {
            setLocations([...locations, {...e.latlng}])
            console.log(e.latlng);
        },
    });
    console.log(locations)
    navigator.clipboard.writeText(JSON.stringify(locations))
    const custom_icon = new L.DivIcon({
        className: 'booth-icon',
        html: `<i class="fa-solid fa-map-pin text-3xl cursor-pointer" style="color: #0b41e5"/>`
    });
    return (
        <>
            {
                locations.length > 0 ? locations.map(location => {
                    return (
                        <Marker
                            position={location}
                            icon={custom_icon}
                        >
                        </Marker>
                    )
                }) : null
            }
        </>
    );

};

export const LeafletACMap = observer((props: any) => {

    const {geojson: geoJson, config, data = []} = props;
    const rootStore = useStore();
    const {flexiStore} = rootStore;
    const geojson = toJS(geoJson)
    const mapRef = useRef();
    const {width} = useWindowSize();
    const isMobile = width <= 766;
    const _bounds = !_.isEmpty(geojson) ? L.geoJSON(geojson).getBounds() : {
        "_southWest": {
            "lat": 29.7157459816,
            "lng": 73.7498960269
        },
        "_northEast": {
            "lat": 29.9551902461,
            "lng": 74.040621631
        }
    };

    const color_mapping = safe_get(geojson, "color_mapping", {});

    const region_feature_styler = (ft) => {
        const {properties} = ft
        const {stroke, fill} = properties
        const hide = (fill === '#fff')
        const weight = hide ? 0 : 1
        const strokeColor = hide ? '#fff' : '#000'

        const hasColorMappingProperty = Object.keys(geojson).includes('color_mapping');

        const defaultOpacity = (hasColorMappingProperty) ? 0 : 0.5
        return {
            fillColor: fill ? fill : "#AB96F2",
            weight: weight ? weight : 1,
            color: strokeColor,
            strokeColor: strokeColor,
            strokeWidth: weight,
            strokeOpacity: properties["stroke-opacity"] ? properties["stroke-opacity"] : defaultOpacity,
            fillOpacity: properties["fill-opacity"] ? properties["fill-opacity"] : defaultOpacity,
        };
    }

    const features = safe_get(geojson, "features", [])

    if(_.isEmpty(geojson)) return <DataNotAvailable />;
    // @ts-ignore
    return (
        <>
            {config["show_booth_numbers"] && <ShowBoothNumbers/>}
            <Typography variant={"h5"} className={"pb-4 uppercase"} color={"primary"}>Assembly Constituency Map</Typography>
            <MapContainer
                style={{height: isMobile ? 350 : config["height"]}}
                center={{lat: 21.1458, lng: 79.0882}}
                zoom={5}
                scrollWheelZoom={true}
                // @ts-ignore
                bounds={_bounds}
                ref={mapRef}
            >
                <ReactLeafletGoogleLayer apiKey='AIzaSyCuZfb3JFcDYrKECKU91FKqjK764nvsFGI' type="roadmap"/>

                {flexiStore.show_booth_numbers && geojson && features.map(poly => <RenderMarker poly={poly}/>)}

                {
                    !_.isEmpty(geojson) &&
                    <>
                        <GeoJSON data={geojson} style={region_feature_styler}/>
                        <SetBounds bounds={_bounds}/>
                    </>
                }

                <RenderMarkerCluster marker_data={data}/>

                <div className='p-2 legend flex flex-row flex-wrap justify-center'
                     style={{
                         "position": "fixed",
                         "bottom": "0",
                         "width": "100vw",
                         backgroundColor: 'rgba(256,256,256, 0.75)'
                     }}>
                    {color_mapping && Object.keys(color_mapping).map(x => <RenderColorMapping color={color_mapping[x]} region={x}/>)}
                </div>
                {rootStore.userStore.isRoot() ? <LocationFinder/> : null}
            </MapContainer>
        </>

    )
})