import {Route, Routes} from "react-router-dom";
import {GrievanceManagement, ViewGrievance} from "../../features";

import React from "react";

export const GrievanceRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<GrievanceManagement/>}/>
            
            <Route path="/:fsdid/view" element={<ViewGrievance/>}/>
        </Routes>
    )
}