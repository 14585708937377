import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import Select from "react-select";
import {FormHelperText, Typography} from "@mui/material";

export const RNSelect = ({name, options, label, isMulti = false, ...props}) => {

    const {control,trigger, formState: {errors},} = useFormContext();

    const getSingleOption = (value) => {
        return options.find((option) => option.value === value);
    };

    const getMultiOptions = (value) => {
        return options.filter((option) => value.includes(option.value));
    };

    return (
        <div>
            <Typography variant={"caption"} color={"primary"}>{label}</Typography>
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                    <>
                        <Select
                            isMulti={isMulti}
                            value={!isMulti ? getSingleOption(value) : getMultiOptions(value)}
                            options={options}
                            onChange={(val) => {
                                if (isMulti) {
                                    onChange(val.map((x) => x.value));
                                } else {
                                    onChange(val.value);
                                }
                            }}
                            onBlur={e => trigger(name)}
                            {...props}
                            closeMenuOnSelect={!isMulti}
                        />
                        {error && <FormHelperText error>{error?.message}</FormHelperText>}
                    </>
                )}
            />
        </div>
    );
};
