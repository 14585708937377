import React from "react";
import {Routes, Route} from "react-router-dom";
import {PrivateRoute} from "./helpers";
import {Login, ProjectDashboardPage, SettingsPage,} from "../pages";
import {BYRBJP} from "../pages/ad-hoc-features";
import {Enquiries} from "../components";
import {RenderFile} from "../features/digital-repository";
import DeleteUserPage from "../pages/DeleteUserPage";
import {DashBoardRoutes} from "./analytical-dashboard-routes";
import {DataBankRoutes} from "./databank-routes";
import {ReportRoutes} from "./report-routes";
import {FeatureRoutes} from "./all-features-routes";
import { ToolsRoutes } from "./tools-routes";
import {SLAR} from "../pages/ad-hoc-features/somali-land-president/ar-landing-page";

export const MainRoutes = () => {
    return (
        <Routes>
            <Route path={"/login"} element={<Login/>}/>
            <Route path={"/delete_user_info"} element={<DeleteUserPage/>}/>
            <Route path={"/enquiries"} element={<Enquiries/>}/>
            <Route path={"/landing/BYRBJP"} element={<BYRBJP/>}/>
            <Route path={"/musebixi"} element={<SLAR/>}/>

            <Route path="/dashboard/*" element={<DashBoardRoutes />} />

            <Route path={"/"} element={<PrivateRoute/>}>
                <Route index path="/" element={<ProjectDashboardPage/>}/>
                <Route path="/project/:projectid/*" element={<FeatureRoutes/>}/>
                <Route path="/settings" element={<SettingsPage/>}/>
                <Route path="/tools/*" element={<ToolsRoutes />} />
                <Route path="/databank/*" element={<DataBankRoutes />} />
                {/*<Route path="/reports/*" element={<ReportRoutes/>}/>*/}
                <Route path="/reports/*" element={<ReportRoutes/>}/>
                <Route path="/view-file/:key" element={<RenderFile/>}/>
            </Route>
        </Routes>
    );
};
