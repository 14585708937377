import { useParams } from "react-router-dom";
import { BreadCrumbs } from "../../components";
import React from "react";

export const GrievanceCrumbs = (props) => {
  const { projectid } = useParams();

  const crumbs = [
    { name: "DashBoard", href: `/project/${projectid}`, current: true },
    {
      name: "Grievance Management",
      href: "/project/" + `${projectid}` + "/grievance_management",
      current: true,
    },
  ];
  return <BreadCrumbs crumbs={crumbs} />;
};
