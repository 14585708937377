import {action, makeAutoObservable} from "mobx";
import {safe_get} from "../report-visuals/report-utils";
import _ from "lodash";

export class ProjectStore {
    // Define properties of the ProjectStore.
    rootStore: any;
    hasCheckedAuth: boolean = false;
    isAuthorized: boolean = false;
    activeProject: any = null;
    voter_reg_tracker_config: any = {};
    active_project_id: any = -1;
    addNewProjectModal: boolean = false;

    constructor(RootStore: any) {
        makeAutoObservable(this);
        this.rootStore = RootStore;
        this.isAuthorized = false;
        this.hasCheckedAuth = false;
        this.hydrate_project(); // Load the project data from localStorage when initializing.
    }

    @action
    set_active_project_id(id) {
        this.active_project_id = id;
    }

    @action
    set_addNewProjectModal(val) {
        this.addNewProjectModal = val;
    }

    @action
    getFullProject = async (projectid: string) => {
        const payload = {
            projectid: parseInt(projectid),
        };
        const response = await this.rootStore.api.post(
            "project/getfullproject",
            payload
        );
        this.dehydrate_project(response.data);
        return response;
    };

    // Function to get a survey by its ID from the active project stored in localStorage.
    getSurveyByID(surveyid) {
        const all_surveys = JSON.parse(localStorage.getItem("__active_project"))[
            "surveys"
            ];
        const matches = _.filter(all_surveys, {id: surveyid}); // Filter the surveys to find the one with the matching ID.
        if (matches.length === 1) {
            return matches[0];
        } else {
            return null;
        }
    }

    // Action to get the status of a specific feature in the project.
    @action
    getFeatureStatus(featureName) {
        const features = this.getProjectProperty("features"); // Get the features property from the project.
        return safe_get(features, featureName, "disabled"); // Safely retrieve the status of the feature.
    }

    // Action to get a specific property of the project from localStorage.
    @action
    getProjectProperty(propName, defaultValue = {}) {
        const ls_project = JSON.parse(localStorage.getItem("__active_project"));
        if (ls_project && ls_project["id"]) {
            return safe_get(
                ls_project["properties"],
                `${propName}.value`,
                defaultValue
            );
        } else return defaultValue;
    }

    // Action to get a permission status for the current user in the active project.
    @action
    getPermission(permission, defaultValue) {
        try {
            const __activeProject = JSON.parse(
                localStorage.getItem("__active_project")
            );
            if (__activeProject !== null || __activeProject !== "null") {
                return safe_get(
                    __activeProject["user_permissions"],
                    `${permission}`,
                    defaultValue
                );
            } else return defaultValue;
        } catch (e) {
            console.log(e);
        }
    }

    // Action to fetch a list of all surveys available in the project.
    @action
    getAllSurveyList = async () => {
        const response = await this.rootStore.api.post(
            "project/projectsandsurveys",
            {}
        );
        return response.data;
    };

    // Action to translate a given text to a specified language.
    @action
    getTranslation = async (text, tolang) => {
        const payload = {text, tolang};
        const response = await this.rootStore.api.post("utils/translate", payload);
        return response.data; // Return the translated text.
    };

    // Action to clone an existing survey in the project.
    @action
    cloneSurvey = async (projectid, surveyid) => {
        const payload = {projectid, surveyid};
        await this.rootStore.api.post("project/newsurvey-clone", payload);
    };

    // Action to create a new blank survey in the project.
    @action
    blankSurvey = async (projectid) => {
        const payload = {projectid};
        await this.rootStore.api.post("project/newsurvey-blank", payload);
    };

    // Action to delete a survey from the project.
    @action
    deleteSurvey = async (projectid, surveyid) => {
        const payload = {projectid, surveyid};
        await this.rootStore.api.post("project/deletesurvey", payload);
    };

    // Action to either edit an existing survey or create a new one.
    @action
    editOrNewSurvey = async (payload) => {
        await this.rootStore.api.post("project/editornewsurvey", payload);
    };

    // Action to add a new project to the system.
    @action
    addNewProject = async (payload) => {
        return await this.rootStore.api.post("project/addproject", payload);
    };

    // Action to edit the metadata of an existing project.
    @action
    editProject = async (payload) => {
        return await this.rootStore.api.post("project/editmeta", payload);
    };

    // Action to delete an existing project.
    @action
    deleteProject = async (payload, id) => {
        payload["id"] = id;
        return await this.rootStore.api.post("project/delete", payload);
    };

    // Action to edit the properties of an existing project.
    @action
    editProjectProperties = async (payload) => {
        return await this.rootStore.api.post(
            "project/editprojectproperties",
            payload
        );
    };

    // Action to add a new user to the project.
    @action
    addNewUser = (payload) => {
        return this.rootStore.api.post("user/inviteuserbulk", payload);
    };

    // Action to fetch all users associated with the project.
    @action
    getAllUsers = (payload) => {
        return this.rootStore.api.post("projectshelf/searchusers", payload);
    };

    // Action to fetch all user tags associated with the project.
    @action
    getAllUserTags = (payload) => {
        return this.rootStore.api.post("projectshelf/allusertags", payload);
    };

    // Action to load the project data from localStorage.
    @action
    hydrate_project = () => {
        try {
            const project_info_in_storage =
                window.localStorage.getItem("__active_project");
            this.update_active_project(JSON.parse(project_info_in_storage))
        } catch (e) {
            this.update_active_project({})
        }
    };

    // Action to save the project data to localStorage.
    @action
    dehydrate_project = async (full_project_payload) => {
        try {
            await window.localStorage.setItem(
                "__active_project",
                JSON.stringify(full_project_payload)
            );
            this.update_active_project(full_project_payload)
        } catch (e) {
            this.update_active_project({})
        }
    };

    // Action to fetch GOTV (Get Out The Vote) data for a project.
    @action
    get_GoTV_data = (projectid) => {
        return this.rootStore.api.post("project/gotvsummary", projectid);
    };

    // Action to set the voter registration tracker configuration.
    @action
    set_voter_reg_tracker_config(config) {
        this.voter_reg_tracker_config = config;
    }

    // Action to update the active project in the store.
    @action
    update_active_project(project) {
        this.activeProject = project;
    }
}
