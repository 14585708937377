import {Route, Routes} from "react-router-dom";
import {NewProjectWorksHome} from "../../features/project-works-v2";
import {ProjectWorkInfo} from "../../features/project-works-v2/project-work-info";
import React from "react";

export const PWV2Routes = () => {
    return (
        <Routes>
            <Route path="/" element={<NewProjectWorksHome/>}/>
            <Route element={<ProjectWorkInfo/>} path="/:activeProjectId"/>
        </Routes>
    )
}