import React, {useState} from "react"
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../helpers";
import {safe_get} from "../../../../report-visuals";
import {FilterHeader} from "../../filterHeader";
import {useLocalStorage} from "react-use";
import {RNCheckBoxTree} from "../../../../components";

export const DFSingleSelect = observer(({data, onChangeHandler}: any) => {

    const { voterListStore: {voter_filters} } = useStore();
    const [value] = useLocalStorage('__active_project', {});
    const [selectedOption, setSelectedOption] = useState(safe_get(voter_filters, `df.${data.key}`, []));

    const getOptions = () => {
        if("survey_id" in data?.options) {
            const survey = value["surveys"].find(survey => survey.id === data?.options?.survey_id);
            const question = survey?.questions.find(question => question.name ===  data?.options?.question_name);
            const options = safe_get(question, "options", {});
            return options[Object.keys(options)[0]]
        }
        return []
    };

    const options = Array.isArray(data.options) ? data.options : getOptions();

    return(
        <>
            <FilterHeader title={data.display} content={data.description} />
            <RNCheckBoxTree
                data={options.map(option => ({label:option, value: option }))}
                on_node_selection={(nodes) => {
                    setSelectedOption(nodes);
                    onChangeHandler(nodes, data.key);
                }}
                checked_nodes={selectedOption}
            />
        </>
    )
})