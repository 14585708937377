import React from "react";
import _ from "lodash";

export const makeid_alpha = (length) => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const makeid_numeric = (length) => {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export function TextError(errorMessage) {
    return (
        <div className="error text-sm text-red-600">
            <i className="text-sm fa-solid fa-triangle-exclamation text-red-600 pr-2"/>
            {errorMessage}
        </div>
    );
}

export const file_icon = (file_type) => {
    switch (file_type) {
        case "image":
            return <i className="fa-solid fa-image text-indigo-600"/>;
        case "audio":
            return <i className="fa-solid fa-file-music text-green-600"/>;
        case "video":
            return <i className="fa-solid fa-video text-indigo-600"/>;
        case "text":
            return <i className="fa-solid fa-file-lines text-yellow-600"/>;
        default:
            return <i className="fa-solid fa-file text-red-600"/>;
    }
};

export const SubHeading = ({title}) => {
    return <div className="text-lg font-bold uppercase fp-text">{title}</div>;
};

export const AttachmentsList = ({file, onDelete}) => {
    console.log({file});
    return (
        <div key={file.id} className="flex flex-row items-center cursor-pointer">
            {file_icon(file.type.split("/")[0])}
            <p
                className="pl-2 lg:mr-5 text-sm"
                onClick={(e) => window.open(file.path, "_blank")}
            >
                {file.path.split("/")[file.path.split("/").length - 1]}
            </p>
            <i
                className="fa-solid fa-trash-can text-red-600 px-4 "
                onClick={() => {
                    onDelete(file.path);
                }}
            />
        </div>
    );
};

// export const AttachmentsList = ({ file, file_type, onDelete }) => {
//     return (
//         <div key={file.id} className="flex flex-row items-center cursor-pointer">
//             {file_icon(file_type)}
//             <p className="pl-2 lg:mr-5 text-sm">
//                 {file_type} - {file.path.split('/')[file.path.split('/').length - 1]}
//             </p>
//             <i
//                 className="fa-solid fa-trash-can text-red-600 px-4"
//                 onClick={() => {
//                     onDelete(file.path);
//                 }}
//             />
//         </div>
//     );
// };

export const remove_item_from_array = (items, key, match) => {
    console.log(items);
    const tmp = _.clone(items);
    const index = tmp.findIndex((a) => a[key] === match);
    tmp.splice(index, 1);
    return tmp;
};

export const update_item_in_array = (items, key, match) => {
    const index = items.findIndex((item) => {
        return item[key] === match[key];
    });
    if (index === -1) {
        items.push(match);
    } else {
        items.splice(index, 1, match);
    }

    return items;
};

export const makeid_alpa_numeric = (length) => {
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const makeid_task_location = () => {
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz";
    var nos = "123456789";
    var charactersLength = characters.length;
    var nosLength = nos.length;

    for (let i = 0; i < 2; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    for (let i = 0; i < 2; i++) {
        result += nos.charAt(Math.floor(Math.random() * nosLength));
    }
    return result;
};

export const maketoken = (length) => {
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const htmlValidation = (event) => {
    event.preventDefault();
    event.stopPropagation();
};

export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export const get_color_code = (index) => {
    const colors = [
        "#FF3CC7",
        "#4C1A57",
        "#F0F600",
        "#00E5E8",
        "#FFA686",
        "#A30B37",
        "#6FFFE9",
        "#D8CBC7",
        "#CC3F0C",
        "#9A6D38",
        "#33673B",
        "#19231A",
        "#0C0A3E",
        "#7B1E7A",
        "#B33F62",
        "#F9564F",
        "#F3C677",
        "#56A3A6",
        "#484538",
        "#CAD49D",
        "#D4EAC8",
        "#03045E",
        "#0077B6",
        "#00B4D8",
        "#F6F4F3",
    ];
    return colors[index];
};

export const formatDate = (date) => {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
};

export const tConvert = (time) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
        time,
    ];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
};

export const float_to_percent_str = (num) => {
    try {
        const percent = Math.round(num * 10000, 2) / 100;
        return percent.toString() + "%";
    } catch (e) {
    }
    return "";
};

export const has_project_in_cache = (projectid) => {
    const str_project = localStorage.getItem("__active_project");
    if (!str_project) return false;
    try {
        const project = JSON.parse(str_project);
        if (project.id === projectid) return true;
    } catch (e) {
    }
    return false;
};

export const zero_pad = (num, numZeros) => {
    var n = Math.abs(num);
    var zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
    var zeroString = Math.pow(10, zeros).toString().substr(1);
    if (num < 0) {
        zeroString = "-" + zeroString;
    }

    return zeroString + n;
};

export const get_booth_village = (name) => {
    const chars = [",", ".", "(", ")", "-", ".", ":", ","];
    for (let c of chars) {
        name = name.replace(c, " ");
    }

    const blacklist = [
        "govt",
        "kannada",
        "boys",
        "higher",
        "primary",
        "school",
        "left",
        "side",
        "government",
        "english",
        "middle",
        "right",
        "model",
        "high",
        "room",
        "no",
        "east",
        "west",
        "north",
        "south",
        "wing",
        "entrance",
        "new",
        "old",
        "bldg",
        "building",
        "lower",
        "southside",
        "northside",
        "eastside",
        "westside",
        "urdu",
        "girls",
        "no",
        "1",
        "2",
        "3",
        "p",
        "u",
        "pu",
        "composite",
        "composite-jr",
        "4",
        "5",
        "6",
        "gram",
        "panchayat",
        "office",
        "s",
        "college",
        "centre",
        "center",
        "grama",
        "anganawadi",
        "wing",
        "central",
        "to",
        "cross",
        "road",
        "eastside",
        "wastside",
        "westside",
        "jr",
        "sr",
        "u",
        "bld",
        "wing.",
        "kannda",
        "primaryschool",
        "godown",
        "shed",
        "ps",
    ];
    const parts = name.trim().split(" ");
    const filtered = [];
    for (let part of parts) {
        if (part.trim() === "") continue;
        if (blacklist.includes(part.trim().toLowerCase())) {
        } else {
            filtered.push(part);
        }
    }
    return filtered.join(" ");
};

export const truncateText = (text, maxLength = 20) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
    }
    return text;
};

export const refineText = (text) => {
    //splits based on comma
    text = text.split(",");
    //splits based on enter i.e \n newline, creates individual arrays
    let new_arr = text.map((str) => {
        let temp_arr = str.split(/\r?\n/);
        temp_arr = _.remove(temp_arr, (arr_vals) => arr_vals !== "");
        return temp_arr;
    });
    //converts 2D array to 1D array
    let flattened_arr = _.flatten(new_arr);
    let final_arr = flattened_arr.map((curr_data) => {
        return _.trim(curr_data);
    });
    final_arr = final_arr.filter((curr_data) => curr_data !== "");
    return final_arr;
};

export const getObjByName = (name) => {
    return {
        label: name,
        value: name,
    };
}
