import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {useStore} from "../../../helpers";
import {RNCheckBoxTree} from "../../../components";
import {safe_get} from "../../../report-visuals";


export const BoothFilters = observer((props: any) => {
    const {projectStore, voterListStore} = useStore()
    const hierarchy = projectStore.getProjectProperty("hierarchy", []);
    const [selected, setSelected] = useState(safe_get(voterListStore.voter_filters, "booths", []))
    return (
        <>
            <RNCheckBoxTree
                data={hierarchy}
                checked_nodes={selected}
                on_node_selection={nodes => {
                    setSelected(nodes);
                    props.onBoothChange(nodes)
                }}
            />
        </>
    )
})