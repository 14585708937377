import * as Yup from 'yup';

export const addBeneficiaryValidations = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone is required'),
    whatsapp: Yup.string(),
    voter_id: Yup.string().required('Voter ID is required'),
    aadhar_id: Yup.string().required('Aadhar ID is required'),
    address: Yup.string().required('Address is required'),
    caste: Yup.string(),
    dob: Yup.string(),
    age: Yup.string(),
    booth_number: Yup.object().required("Please select Booth/Village"),
    village: Yup.object().required("Please select Booth/Village"),
    gender: Yup.string(),
    is_outsider: Yup.boolean(),
    ac_name: Yup.string(),
    oacv_name: Yup.string(),
    oacv_phone: Yup.string()
});
