import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useStore} from "../../helpers";
import {useNavigate} from "react-router-dom";
import Modal from 'react-modal';
import {FlexiStoreTemplate_Abridged} from "../../stores/fs/fst";
import {FsFilterPane} from "../fsFilterPane";
import { useTranslation } from "react-i18next";
import {BreadCrumbs, LoadingState} from "../../components";
import {safe_get} from "../../report-visuals/report-utils";
import {toast} from "react-toastify";
import {Typography} from "@mui/material";

Modal.setAppElement('#root');

const crumbs = [
    {name: 'DataBank', href: '#', current: true}
];

export const FSTListPage = observer(props => {

    const rootStore = useStore();
    const {flexiStore} = rootStore;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation("pages_root");

    useEffect(() => {
      return () =>  flexiStore.reset_fst_search();
    }, []);


    useEffect(() => {
        getFSTTemplates();
    }, [flexiStore.fst_query]);

    const getFSTTemplates = async () => {
        setLoading(true)
        const response = await flexiStore.get_search_fs_templates();
        if(!response.ok) {
            toast.error("Something went wrong, please try again later")
        }
        setLoading(false)
    }

    const handleSearch = (query) => {
        flexiStore.set_fst_search_query(query);
    }

    const render_fst = (fst: FlexiStoreTemplate_Abridged) => {
        // @ts-ignore
        const {state, name, color, icon, iconcolor, iconbgcolor, description} = fst.summary;
        const hideFST = safe_get(fst, "summary.hide", false)
        if(hideFST) return null;
        return (
            <div key={`fst_${fst.id}`}
                 className="flex flex-row rounded-lg shadow-lg overflow-hidden cursor-pointer bg-white p-3"
                 style={{borderLeft: `4px solid ${color}`, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                 onClick={(evt) => {
                     navigate(`/databank/repo/${fst["id"]}/${fst["key"]}`);
                     sessionStorage.setItem("fst", JSON.stringify(fst))
                 }}
            >
                <div className="rounded-full flex flex-row items-center justify-center"
                     style={{backgroundColor: `${iconbgcolor}`, width: 60, height: 60}}>
                    <i className={`text-3xl ${icon}`} style={{color: `${iconcolor}`}}/>
                </div>
                <div className="flex-1 flex flex-col ml-2 justify-between">
                    <div className="font-bold text-gray-700">{name}</div>
                    <div className="text-gray-600 text-md">{description}</div>
                    <div className="font-bold text-gray-500">{t("state")}: {state}</div>
                </div>
            </div>
        )
    }

    const FSTListRenderer = () => {
        return (
            <div className={"px-4"}>
                <div className='italic'>{t("showing")}  {flexiStore.fst_results.length} {t("templates")}.</div>
                <div className="mt-5 max-w-none mx-auto grid gap-2 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2">
                    {
                        flexiStore.fst_results.map(x => {
                            return render_fst(x);
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            {loading ? <LoadingState /> : null}
            <BreadCrumbs crumbs={crumbs}/>
            <div className="px-4 flex flex-row items-center justify-between gap-x-4">
               <div className={"flex flex-1 flex-col"}>
                   <Typography variant={"h4"} className="title">{t("data_bank_repositories")}</Typography>
                   <Typography variant={"body1"}>{t("contains_several_data_series")}</Typography>
               </div>
                <div className="flex flex-1">
                    <FsFilterPane key={1} onSearch={handleSearch}/>
                </div>
            </div>
            {flexiStore.fst_results ? <FSTListRenderer /> : null}
        </>
    );
});
