import {action, makeAutoObservable} from "mobx";
import {makePersistable, hydrateStore} from "mobx-persist-store";

type DAFilters = {
    date: string;
};

export class DigitalArchiveStore {
    rootStore: any;

    addNewModal: boolean = false;
    activeParentId: string = "";
    folderBreadCrumbs: any[] = [];
    activeFileorFolder: any = {};
    searchQuery: string = "";

    daFilters: DAFilters = {
        date: "",
    };

    da_filter_modal: boolean = false;

    constructor(rootStore: any) {
        makeAutoObservable(this);
        makePersistable(this, {
            name: "folderBreadCrumbs",
            properties: ["folderBreadCrumbs"],
            storage: window.localStorage,
        });
        this.rootStore = rootStore;
        hydrateStore(this);
    }

    @action
    resetFolderBreadCrumbs = () => {
      this.folderBreadCrumbs = []
    }

    @action
    update_DAStoreProp(prop, value) {
        switch (prop) {
            case "addNewModal":
                this.addNewModal = value;
                break;
            case "activeParentId":
                this.activeParentId = value;
                break;
            case "activeFileorFolder":
                this.activeFileorFolder = value;
                break;
            case "da_filter_modal":
                this.da_filter_modal = value;
                break;
            case "searchQuery":
                this.searchQuery = value;
                break;
        }
    }

    @action
    update_da_filters(data: DAFilters) {
        this.daFilters = data;
    }

    @action
    update_folder_breadcrumbs = (crumbs) => {
        this.folderBreadCrumbs = crumbs;
    };

    async get_files_and_folders(payload) {
        return await this.rootStore.api.post("dropbox/search", payload);
    }

    async add_file_to_digital_archive(payload) {
        return await this.rootStore.api.post("dropbox/add_doc_list", payload);
    }

    async edit_file_to_digital_archive(payload) {
        return await this.rootStore.api.post("dropbox/update_doc", payload);
    }

    async delete_file_or_folder(payload) {
        return await this.rootStore.api.post("dropbox/del_doc", payload);
    }
}
