import * as Yup from "yup";

export const beneficiaryRequestValidations = Yup.object().shape({
  department: Yup.string().required("Department is required"),
  type: Yup.string().required("Scheme is required"),
  status: Yup.string().required("Request Status is required"),
  additional: Yup.string(),
  assigned_to: Yup.mixed(),
  documents: Yup.mixed().test("file", "Please select a file", (value) => {
    return value["length"] > 0;
  }),
});