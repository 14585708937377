import * as yup from "yup";

export const addEditGrievanceFormSchema = yup.object().shape({
    title: yup
        .string()
        .min(3, "Min 3 characters")
        .required("Complaint Title is required"),
    category_level_1: yup.string().required("Deparment is required"),
    category_level_2: yup.string().required("Complaint Type is required"),
    specific_complaint: yup.string(),
    piv_level_1: yup.string().required("Hobli is required"),
    piv_level_2: yup.mixed().test(
        'mix',
        'Please select village',
        (value) =>

            yup.string().isValidSync(value) ||
            (Array.isArray(value) && value.every((item) => yup.string().isValidSync(item)))
    ),
    description: yup.string(),
    current_status: yup.string().required("Status is required"),
    priority: yup.string().required("Status is required"),
    assigned: yup.string().required("Concerned person is required"),
    assigned_office_person: yup.string(),
    reported_by: yup.object().shape({
        name: yup
            .string()
            .min(3, "Min 3 characters")
            .matches(/^[A-Za-z\s]+$/, "Person Name must contain letters only")
            .required("Person Name is required"),
        voter_id: yup.string(),
        aadhar: yup.string(),
        age: yup.number(),
        dob: yup.string(),
        caste: yup.string(),
        gender: yup.string(),
        address: yup
            .string()
            .required("Address is required"),
        phone: yup
            .string()
            .min(10, "Phone number should be 10 digits")
            .max(10)
            .matches(/^[0-9]+$/, "Phone number must be numeric")
            .required("Phone number is required"),
        whatsapp: yup
            .string()
            .min(10, "Phone number should be 10 digits")
            .max(10)
            .matches(/^[0-9]+$/, "Phone number must be numeric")
            .required("Phone number is required")
    }),
    attachments: yup
        .mixed()
        .test("required", "Attachments are required", (value) => {
            // @ts-ignore
            return value && value.length > 0;
        })
        .required("attachments are needed !"),
    media: yup
        .mixed()
        .test("required", "Media is required", (value) => {
            // @ts-ignore
            return value && value.length > 0;
        })
        .required("media is needed !"),
});
