import {useParams} from "react-router-dom";
import {BreadCrumbs} from "../../components";
import React from "react";

export const BMHomeCrumbs = (props) => {
    const {projectid} = useParams();

    const crumbs = [
        {name: "DashBoard", href: `/project/${projectid}`, current: true},
        {name: 'Beneficiary Management', href: `/project/${projectid}/beneficiary_v2`, current: true},
    ];
    return (
        <BreadCrumbs crumbs={crumbs}/>
    )
}