import React from "react"
import {observer} from "mobx-react-lite";
import {useStore} from "../helpers";
import {Pagination, Stack} from "@mui/material";

export const FSDPagination = observer((props:any) => {

    const {totalPages} = props;

    if(!totalPages) return null;

    const rootStore = useStore();
    const {flexiStore} = rootStore;
    const {fsd_search_current_page} = flexiStore;

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        flexiStore.update_flexistore_property("fsd_search_current_page", value)
    };

    return(
        <>
            <Stack spacing={2} className='w-full' direction={"row"} alignItems={"center"}>
                <div className="font-bold text-gray-500">Page: {fsd_search_current_page}</div>
                <Pagination color={"primary"} size={"medium"} variant={"outlined"} count={totalPages} page={fsd_search_current_page} onChange={handlePageChange}/>
            </Stack>
        </>
    )
})