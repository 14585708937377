import React, {useState} from "react";
import {
    FilterButton,
    FSDPagination,
    LoadingState,
    ProjectHeader
} from "../../components";
import {Link, useParams} from "react-router-dom";
import {useStore} from "../../helpers";
import {Button, Stack} from "@mui/material";
import {useGetFullProject, useIsMobile} from "../../custom-hooks";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {RNSearchBar} from "../influential-leaders";
import {AddBeneficiaryV2} from "./add-beneficiary-v2";
import {BMHomeCrumbs} from "./beneficiary-crumbs";
import {BeneficiaryFiltersV2} from "./beneficiary-filters";
import {BeneficiaryVoterFilters, BeneficiaryVoterList} from "./beneficiary-voters";
import {TableLoadingSkeleton} from "../beneficiary-management";
import {BeneficiaryViewV2} from "./beneficiary-view-v2";
import {useGetBeneficiaryList} from "./beneficiary-hooks";
import {BeneficiariesTableV2} from "./beneficiary-table-v2";

export const BeneficiaryManagementV2 = observer(props => {

    const {t} = useTranslation(["beneficiary", "voter_list"]);
    const {projectid} = useParams();
    const isMobile = useIsMobile();
    const rootStore = useStore();
    const {data, isLoading} = useGetBeneficiaryList();
    const {beneficiaryStore, flexiStore, userStore} = rootStore;
    const {fsd_search_page_size} = flexiStore;
    const totalPages = Math.ceil(data?.data?.total / fsd_search_page_size);
    const {isLoading: fullProjectLoading} = useGetFullProject();

    const showAddBeneficiaryOverLay = () => {
        beneficiaryStore.update_add_beneficiary_modal(true);
    }

    const handleSearchBarChange = (value) => {
        flexiStore.set_fsd_search_query(value);
    }

    // const downloadable_format = beneficiaryStore.beneficiaries.length >= 0 && beneficiaryStore.beneficiaries.map(beneficiary => beneficiary["summary"].profile)

    // if (isLoading || fullProjectLoading) return <TableLoadingSkeleton/>

    return (
        <>
            {!isMobile &&
                <>
                    <BMHomeCrumbs/>
                    <ProjectHeader/>
                </>
            }
            <div className='p-4 overflow-hidden primary-text'>
                <div className="flex align-center items-start gap-x-2">
                    <div className='flex flex-row justify-between items-center mb-4 flex-1'>
                        <h2 className='font-bold text-3xl text-indigo-500 pr-14'>{t("b_m")}</h2>
                        <div className={"flex items-start justify-between gap-x-4"}>
                            <RNSearchBar onSearch={handleSearchBarChange}/>
                            <Button
                                variant={"contained"}
                                startIcon={<i className="fas fa-plus-circle"/>}
                                onClick={showAddBeneficiaryOverLay}
                            >
                                {t("n_b")}
                            </Button>
                        </div>
                    </div>

                    <FilterButton
                        filterOnClickHandler={() => beneficiaryStore.setBeneficiarieFilter(true)}
                    />

                    {(userStore.isRoot() || userStore.isClient()) &&
                        <Link to={`/project/${projectid}/beneficiary_management/admin`} className="flex ml-4">
                            <i className={"fa-solid fa-gear text-4xl"}/>
                        </Link>
                    }
                </div>

                <Stack spacing={2} className='w-full' direction={"row"} alignItems={"center"}
                       justifyContent={"space-between"}>
                    <FSDPagination key={"Beneficiary"} totalPages={totalPages}/>
                    {/*<DownloadAsExcelSheet data={downloadable_format} cols={excel_col_def} name={`S`} />*/}
                </Stack>

                {isLoading || fullProjectLoading ? <TableLoadingSkeleton/> : <BeneficiariesTableV2/>}
                <BeneficiaryViewV2 />
                <AddBeneficiaryV2/>
                <BeneficiaryFiltersV2 />
                <BeneficiaryVoterList />
                <BeneficiaryVoterFilters />
            </div>
        </>
    );
});


export default BeneficiaryManagementV2