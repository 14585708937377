import React, { useState, useEffect } from "react";
import { useStore } from "../../helpers";
import AgeFilter from "./filters/ageFilter";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {GendersFilter, RichSearch, VotersDynamicFilters} from "./filters";
import {Booths} from "../user-management";
import {FormButtons} from "../../components/cm-components";
import { DateSearch } from "./filters/date-searchFilter";
import moment from "moment";
import {useWindowSize} from "react-use"
import {BoothFilters} from "./filters/booth-filter";

const FILTER_TYPES = ["Rich Search", "Gender", "Age", "Booths","Date of Birth", "More Filters"];

export const FilterOverLay = observer((props) => {

    const rootStore = useStore();
    const {projectid} = useParams();
    const {height} = useWindowSize();
    const { beneficiaryStore, voterListStore, userStore } = rootStore;
    const [t] = useTranslation("voter_list");
    const { voter_filters } = voterListStore;
    const [activeFilter, setActiveFilter] = useState(FILTER_TYPES[0]);
    const { serialno, name, relation, voterid, page, house, df } = voter_filters;

    const [filters, setFilters] = useState({
        projectid: parseInt(projectid),
        serialno: serialno,
        name: name,
        relation: relation,
        voterid: voterid,
        page: page,
        house: house,
        genders: voter_filters["genders"],
        age: voter_filters["age"],
        booths: voter_filters.booths,
        dob: voter_filters["dob"],
        df: voter_filters.df,
        offset: voter_filters.offset,
        count: voter_filters.count
    })

    // Callback function to receive data from child
    const handleDateSearch= (date) => {
        setFilters({...filters, dob:moment(date).format('DD-MM-YYYY') })
    };

    useEffect(() => {
        const activeUserId = JSON.parse(localStorage.getItem("__userprofile"));
        const UserId = activeUserId?.id;
        userStore.get_user_permissions_and_parts({
            projectid: Number(projectid),
            userid: UserId,
        });
    }, []);

    const handleBoothFilter = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setFilters({...filters, genders: [...filters.genders, value]})
        } else {
            setFilters({...filters, genders: filters.genders.filter((e) => e !== value)})
        }
    };

    const handleCancel = () => {
        voterListStore.setShowVoterlist(true);
        voterListStore.setFilterStatus(false);
        voterListStore.setFilterVal(null);
        voterListStore.setSelectedBooths([]);
    };

    const setBoothVal = (data: any, name) => {
        beneficiaryStore.setSelectedBooths(data);
        setFilters({...filters, booths: data})
    }

    const handleAgeFilter = (event) => {
        const { value, name } = event.target;
        if (name === "reset") {
            setFilters({...filters, age: [18,120]})
        } else {
            const age = value.split(",").map(c => {
                return Number(c)
            })
            setFilters({...filters, age: age})
        }
    };

    //TODO: update local filter variables to central store
    const apply_filters = async() => {
        voterListStore.update_filters(filters);
        voterListStore.setShowVoterlist(true);
        voterListStore.setFilterStatus(false);
    }

    return (
        <div className="w-full h-full flex">
            <div className="w-1/4 h-full border-black border-r-2 flex flex-col gap-y-4">
                {FILTER_TYPES.map((tab) => (
                    <p key={tab} onClick={() => {
                        setActiveFilter(tab)
                    }}>
                        <span className={activeFilter == tab ? "text-purple-600 text-lg font-medium" : "text-lg font-medium"}>{tab}</span>
                    </p>
                ))}
            </div>

           <div className={"flex flex-col px-2 w-3/4 h-full"}>
               <div className="overflow-x-hidden overflow-y-auto" style={{height: height * 0.85}}>
                   {activeFilter == "Rich Search" && <RichSearch handleRichSearch={(key,value) => setFilters({...filters, [key]: value})} />}

                   {activeFilter == "Gender" &&  <GendersFilter genders={filters.genders} handleBoothFilter={handleBoothFilter} />}

                   {activeFilter == "Age" &&  <AgeFilter handleAgeFilter={handleAgeFilter} />}

                   {activeFilter == "Booths" && <BoothFilters onBoothChange={setBoothVal}/>}

                   {activeFilter == "Date of Birth" && <DateSearch handleDateSearch = {handleDateSearch} />}

                   {
                       activeFilter == "More Filters" &&
                       <VotersDynamicFilters
                           habdleDF={(options, key) => {
                                setFilters({...filters, df: {...filters.df, [key]: options}})
                           }}
                       />
                   }

               </div>
               <FormButtons onSave={apply_filters} onCancel={handleCancel} />
           </div>
        </div>
    );
});
