import { useLocalStorage } from "react-use";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useStore } from "../../../helpers";
import { toast } from "react-toastify";

export const useAddNewComplaint = () => {
	const { projectStore } = useStore();
	const [activeProject] = useLocalStorage("__active_project", {});
	const { projectid } = useParams();
	const queryClient = useQueryClient();

	const onAddNewComplaint = async ({ complaint, department }) => {
		const payload = {
			id: parseInt(projectid),
			properties: {
				...activeProject["properties"],
				grievances: {
					type: "json",
					value: {
						...activeProject["properties"].grievances.value,
						categories: {
							...activeProject["properties"].grievances.value.categories,
							[department]: [...activeProject["properties"].grievances.value.categories[department], complaint],
						},
					},
				},
			},
		};

		const result = await projectStore.editProjectProperties(payload);
		if (!result.ok) {
			throw new Error("Not able to add new Complaint, Please try again later.");
		} else return result;
	};

	return useMutation({
		mutationFn: onAddNewComplaint,
		onSuccess: () => {
			setTimeout(async () => {
				await queryClient.invalidateQueries(["full-project", projectid]);
			}, 1000);
			queryClient.invalidateQueries(["view-grievance"]);
			toast.success("grievance updated sucessfully");
		},
		onError: (e) => {
			toast.error(e["message"]);
		},
	});
};
