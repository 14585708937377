import {useEffect, useState} from "react";
import TrieSearch from 'trie-search';

//TODO: This hook is used to filter the projects based on search query

export const useFilteredProjects = ({projects, searchQuery} : any) => {

    const [trieStore, setTrieStore] = useState(null);
    const [filteredProjects, setFilteredProjects] = useState([]);

    useEffect(() => {
        const newTs = new TrieSearch(['id', 'name'], {
            splitOnRegEx: /[\s\/()]/,
            min: 2,
            ignoreCase: true,
            indexField: 'id',
        });
        newTs.addAll(projects);
        setTrieStore(newTs);

        if (searchQuery.trim() === '') {
            setFilteredProjects(projects);
        } else {
            setFilteredProjects(newTs.get(searchQuery));
        }
    }, [projects])

    useEffect(() => {
        if (searchQuery.trim() === '') {
            setFilteredProjects(projects);
        } else {
            setFilteredProjects(trieStore.get(searchQuery));
        }
    }, [searchQuery])

    return {filteredProjects}
}