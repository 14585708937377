import {observer} from "mobx-react-lite";
import {FilterOverLay} from "../../voter-list";
import {SideOverlay} from "../../../components";
import React from "react";
import {useTranslation} from "react-i18next";
import {useStore} from "../../../helpers";


export const BeneficiaryVoterFilters = observer((props: any) => {

    const {t} = useTranslation(["beneficiary", "voter_list"]);
    const {voterListStore} = useStore();

    const close_Filter_modal = () => {
        voterListStore.setFilterStatus(false)
        voterListStore.setShowVoterlist(true)
        voterListStore.setFilterVal(null);
    }

    return (
        <SideOverlay
            onClose={close_Filter_modal}
            show={voterListStore.FilterStatus}
            title={t("filter_votersearch")}
        >
            <FilterOverLay/>
        </SideOverlay>
    )
})