import React, {useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {ChevronDownIcon, PencilSquareIcon, PlusIcon as PlusIconOutline, TrashIcon,} from "@heroicons/react/24/outline";
import {Disclosure} from "@headlessui/react";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {PhoneIcon, UserIcon} from "@heroicons/react/24/solid";
import {error_toast, useStore, classNames} from "../../helpers";
import {useTranslation} from "react-i18next";
import {DeleteModal} from "../../components";
import {useIsMobile} from "../../custom-hooks";
import {Avatar, Button} from "@mui/material";

const TeamDetails = observer((props: any) => {

    const rootStore = useStore();
    const [t] = useTranslation("user_management")
    const params = useParams();
    const {userDetails, ActiveList, hydrate} = props;
    const {userStore} = rootStore;
    const [accordionData, setAccordionData] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteID] = useState("")
    const TeamList = ActiveList;
    const isMobile = useIsMobile();

    console.log("Team Details")

    const getData = (userDetails) => {
        if (
            userDetails &&
            userDetails["users"] &&
            userDetails["users"].length > 0 &&
            TeamList
        ) {
            const accData = [];
            const userData = userDetails["users"];
            for (const ownId in TeamList) {
                const ownDetails = userData.find((details) => details?.id == ownId);
                const memberDetails =
                    Array.isArray(TeamList[ownId]) &&
                    TeamList[ownId]?.length > 0 &&
                    TeamList[ownId]
                        .map((memberId) =>
                            userData.find((details) => details?.id == memberId)
                        )
                        .filter((member) => member != undefined);
                if (ownDetails && memberDetails && memberDetails?.length > 0) {
                    accData.push({owner: ownDetails, members: memberDetails});
                }
            }
            setAccordionData(accData);
        }
    };

    const sendData = (data: object) => {
        userStore.setAddTeamStatus(true);
        userStore.setEditTeamStatus(true);
        props.selectData(data);
    };

    const deleteHandler = async (id) => {

        const activeProjectProperties = JSON.parse(
            localStorage.getItem("__active_project")
        );
        delete TeamList[id];
        const payload = {
            id: params.projectid,
            properties: {
                ...activeProjectProperties.properties,
                user_hierarchy: {
                    type: "json",
                    value: TeamList,
                },
            },
        };


        const result = await userStore.add_Team(payload);
        if (result.status === 200) {
            toast.success("Team Deleted Successfully");
            getData(userDetails);
            hydrate();
            setIsDeleteModalOpen(false);
        } else {
            error_toast();
        }

        setIsDeleteModalOpen(false);
    };

    useEffect(() => {
        hydrate();
        getData(userDetails);
    }, [TeamList, userDetails]);

    return (
        <div className="bg-white rounded-lg shadow-lg">
            <div className="flex flex-row justify-between items-center p-4">
                <h2 className="font-bold text-2xl text-indigo-500">{t("teams")}</h2>
                <div className="flex flex-row justify-between items-center">
                    <Button
                        startIcon={<i className="fa-solid fa-circle-plus"/>}
                        color={"primary"}
                        onClick={(event) => {
                            userStore.setAddTeamStatus(true);
                            userStore.setEditTeamStatus(false);
                        }}
                        variant={"contained"}
                    >
                        Add Team
                    </Button>
                </div>
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-4 mb-2 sm:mb-4">
                {accordionData &&
                accordionData.length > 0 &&
                accordionData.map((accordion, accIndex) => {
                    const List = accordion["members"];
                    return (
                        <div
                            className="cursor-pointer relative group rounded-lg border border-gray-300 bg-white sm:px-4 shadow-sm flex items-center space-x-3 bg-gray-50"
                            style={{height: "fit-content"}}>
                            <dl className="flex-1 min-w-0">
                                <Disclosure as="div" className="">
                                    {({open}) => (
                                        <>
                                            <dt className="text-lg flex flex-row">
                                                <div
                                                    className="text-left w-full flex flex-row justify-between items-center text-grey-500 mt-2">
                                                    <div className="flex-shrink-0">
                                                      <Avatar sx={{width: 100, height: 100}} src={accordion.owner.profileimageuri} alt={accordion.owner.name}/>
                                                    </div>
                                                    <div className="flex flex-row items-center justify-between">
                                                        <div className="flex flex-col">
                                                            <p className="mt-1 font-bold flex items-center text-md text-purple-600 mr-2 flex-nowrap">
                                                                <UserIcon
                                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                                <span
                                                                    className="text-base"
                                                                    style={{whiteSpace: "break-spaces"}}
                                                                >{accordion.owner.name}</span>
                                                            </p>
                                                            <p className="mt-1 flex items-center text-smtext-gray-500 mr-2 flex-wrap">
                                                                <PhoneIcon
                                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                                <span className="text-base">{accordion.owner.phonenum}</span>
                                                            </p>
                                                        </div>
                                                        {/*<span*/}
                                                        {/*    className="block text-base font-small font-bold m-2">({surveyResponseStore.regions.length} highlights found)</span>*/}
                                                    </div>

                                                    <div className="flex flex-row justify-around flex-wrap">
                                                        <PencilSquareIcon
                                                            onClick={() => {
                                                                sendData(accordion);
                                                            }}
                                                            className={`h-5 w-5 mx-1  transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" : ""}`}
                                                            aria-hidden="true"
                                                            color="blue"
                                                        />

                                                        <TrashIcon
                                                            onClick={() => {
                                                                setIsDeleteModalOpen(true);
                                                                setDeleteID(accordion.owner.id);
                                                            }}
                                                            className={`h-5 w-5 mx-1  transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" : ""}`}
                                                            aria-hidden="true"
                                                            color="red"
                                                        />
                                                    </div>
                                                </div>
                                            </dt>
                                            <dt className="w-full justify-end items-end">
                                                <Disclosure.Button className="float-right right-2 m-3">
                                                    <ChevronDownIcon
                                                        className={classNames(
                                                            open ? "-rotate-180" : "rotate-0",
                                                            "h-6 w-6 transform"
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </Disclosure.Button>
                                            </dt>

                                            <Disclosure.Panel as="dd" className="mt-2">
                                                <div className="flex flex-col ml-3">
                                                    {List.map((content) => (
                                                        <span className="justify-between items-center bg-white-500 p-2 text-sm">{`${content.id} - ${content.name} - ${content.phonenum}`}</span>
                                                    ))}
                                                </div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            </dl>
                            {isDeleteModalOpen && (
                                <DeleteModal
                                    isOpen={isDeleteModalOpen}
                                    desc={`Are you sure you want to delete ?`}
                                    onDeleteClick={() => deleteHandler(deleteId)}
                                    onCancelClick={() => setIsDeleteModalOpen(false)}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

export default React.memo(TeamDetails)
