import React, {useState, useEffect, useRef} from "react";
import Select from 'react-select';
import {
    useStore,
    uploadFile,
    file_icon,
    htmlValidation,
    makeid_alpa_numeric,
    remove_item_from_array,
    TextError
} from "../../helpers";
import {observer} from "mobx-react-lite";
import {label_style} from "./add-beneficiary";
import {useParams} from "react-router-dom";
import {requestModel} from "./beneficiary-utils";
import {useTranslation} from 'react-i18next';
import Autocomplete from "@mui/material/Autocomplete";
import {safe_get} from "../../report-visuals";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {FormButtons, TailwindModal, UploadButton} from "../../components";
import {IconButton} from "@mui/material";

export const AddRequests = observer((props: any) => {
    const params = useParams();
    const projectid = params.projectid;
    const {t} = useTranslation(["beneficiary", "grievance_management"]);
    const {onRequestAddHandler, buffer, setBuffer, concernedPersons} = props;
    const rootStore = useStore();
    const {beneficiaryStore, projectStore} = rootStore
    const {add_request_modal} = beneficiaryStore;
    const beneficiary_property = projectStore.getProjectProperty("beneficiary");
    const categories = safe_get(beneficiary_property, "categories", {})
    const departments = Object.keys(categories).map(x => {
        return {
            label: x,
            value: x
        }
    })
    const [schems, setSchems] = useState([])
    const status_config = safe_get(beneficiary_property, "status_config", [])
    const [selectedCatergory1, setSelectedCatefory1] = useState("")
    const [selectedCatergory2, setSelectedCategory2] = useState('')
    const [newComplaint, setNewComplaint] = useState("")
    const [category_level_2_options, setCategoryLevel2Options] = useState([])
    const [loading, setLoading] = useState(false)
    const [beneficiaryRequest, setBeneficiaryRequest] = useState(beneficiaryStore.activeRequest);
    const [inputKey, setInputKey] = useState('');
    const [file, setFile] = useState({});

    const [isFormSubmit, setFormSUbmit] = useState(false)

    const saveBeneficRequest = () => {
        setFormSUbmit(true)
        if (beneficiaryRequest.status !== "") {
            let id
            if (beneficiaryRequest.id === "") {
                id = makeid_alpa_numeric(8)
            } else {
                id = beneficiaryRequest.id
            }
            let Attachements = []
            if (buffer.length > 0) {
                for (let ele of buffer) {
                    Attachements.push({path: ele.path, id: ele.id, type: ele.type})
                }
            }
            onRequestAddHandler({...beneficiaryRequest, documents: [...Attachements], id: id});
            setBeneficiaryRequest(requestModel)
            setFormSUbmit(false)
            beneficiaryStore.resetActiveFamily()
            beneficiaryStore.resetActiveRequest()
        }
    }

    const addNewComplaintHandler = async () => {
        setLoading(true)
        setCategoryLevel2Options([...category_level_2_options, {
            label: newComplaint,
            value: newComplaint
        }])
        const activeProjectProperties = JSON.parse(localStorage.getItem("__active_project"))
        const id = safe_get(activeProjectProperties, "id", 1)
        const payload = {
            id: id,
            properties: {
                ...activeProjectProperties.properties,
                beneficiary: {
                    type: "json",
                    value: {
                        ...activeProjectProperties.properties.beneficiary.value,
                        categories: {
                            ...activeProjectProperties.properties.beneficiary.value.categories,
                            [selectedCatergory1]: [...activeProjectProperties.properties.beneficiary.value.categories[selectedCatergory1], newComplaint]
                        }
                    }
                }
            }
        }
    }

    const path = `project-${projectid}/beneficiary/request`

    useEffect(() => {
        setBeneficiaryRequest(beneficiaryStore.activeRequest)
        setBuffer(beneficiaryStore.activeRequest.documents)
    }, [beneficiaryStore.activeRequest])

    useEffect(() => {
        const options = safe_get(categories, `${beneficiaryRequest.department}`, [])
        setSchems(options.length > 0 && options.map(x => {
            return {
                label: x,
                value: x
            }
        }))
    }, [beneficiaryRequest.department])

    const delete_file = name => {
        const updated_values = remove_item_from_array(buffer, "path", name)
        setBuffer(updated_values)
    }

    const new_person = useRef(null);
    const cancelButtonRef = useRef(null);

    // const requestTypes =  service_types.map(x => {
    //     return { "value": x, "label": x };
    // });

    const beneficRequestStatus = status_config.map(x => {
        return {"value": x["status"], "label": x["status"]};
    });

    const uploadFileToS3 = (e) => {
        htmlValidation(e)
        if (!file["file"]) return false;
        const id = makeid_alpa_numeric(8)

        const s3Path = uploadFile(path, file, "urn-assets");
        if (s3Path) {
            setFile({})
            const randomString = Math.random().toString(6);
            setInputKey(randomString);
        }
        const file_type = file["file"]["type"]
        setBuffer([...buffer, {path: s3Path, type: file_type, id: id}])
    }

    const addNewRequestHandler = () => {
        beneficiaryStore.update_add_request_modal(true)
        beneficiaryStore.resetActiveFamily()
        beneficiaryStore.resetActiveRequest()
    }

    // @ts-ignore
    return (
        <>
            <div className="flex flex-row items-center justify-between ">
                <div className="text-indigo-500 font-bold text-xl fp-text">{t("add_request")}</div>
                <IconButton
                    color={"primary"}
                    size={"large"}
                    aria-label="add new request"
                    onClick={addNewRequestHandler}
                >
                    <i className={"fas fa-plus-circle"}/>
                </IconButton>
            </div>
           <TailwindModal isOpen={add_request_modal}>
               <div
                   className="inline-block align-bottom bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                   <div className="p-4 flex flex-col">
                       <div className="text-xl font-semibold text-gray-900 fp-text">{t("add_request")}</div>
                       <div className='w-full mb-2'>
                           <div className={label_style}>Department</div>
                           <Select
                               name={t("Requesttypes")}
                               options={departments}
                               value={{label: beneficiaryRequest.department, value: beneficiaryRequest.department}}
                               onChange={(val, action) => {
                                   setBeneficiaryRequest({...beneficiaryRequest, department: val["value"]})
                               }}
                               placeholder={t("select_request_type")}
                               className="w-full h-auto rounded z-999 focus:shadow outline-none basic-multi-select "
                               classNamePrefix="select"
                               required={true}
                           />
                           <div>
                               {isFormSubmit && beneficiaryRequest.department === "" && TextError(t("request_type"))}
                           </div>
                       </div>

                       <div className='w-full mb-2'>
                           <div className={label_style}>Schemes</div>
                           <Select
                               name={t("Requesttypes")}
                               options={schems}
                               value={{label: beneficiaryRequest.type, value: beneficiaryRequest.type}}
                               onChange={(val, action) => {
                                   setBeneficiaryRequest({...beneficiaryRequest, type: val["value"]})
                               }}
                               placeholder={t("select_request_type")}
                               className="w-full h-auto rounded z-999 focus:shadow outline-none basic-multi-select "
                               classNamePrefix="select"
                               required={true}
                           />
                           <div>
                               {isFormSubmit && beneficiaryRequest.type === "" && TextError(t("request_type"))}
                           </div>
                       </div>

                       {/*{selectedCatergory1 !== "" &&*/}
                       {/*    <div className={'w-full mb-2'}>*/}
                       {/*        <label className={label_style}>Complaint Type</label>*/}
                       {/*        <Select*/}
                       {/*            name="Complaint Type"*/}
                       {/*            options={category_level_2_options}*/}
                       {/*            value={{label: selectedCatergory2, value: selectedCatergory2}}*/}
                       {/*            onChange={(val, action) => {*/}
                       {/*                setSelectedCategory2(val?.value)*/}
                       {/*            }}*/}
                       {/*            placeholder="Select complaint..."*/}
                       {/*            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"*/}
                       {/*            menuPortalTarget={document.querySelector('body')}*/}
                       {/*        />*/}
                       {/*    </div>}*/}
                       {/*{(selectedCatergory2 === "Others" || selectedCatergory2 === "Other") &&*/}
                       {/*    <div className={"form-control p-2 flex flex-row items-center justify-between text-left"}>*/}
                       {/*        <input className="w-full h-auto rounded z-999 focus:shadow outline-none basic-multi-select"*/}
                       {/*               type="text" value={newComplaint}*/}
                       {/*               onChange={e => setNewComplaint(e.target.value)}/>*/}
                       {/*        <button type={"button"} className="btn btn-sm fp-bg mx-2"*/}
                       {/*                onClick={addNewComplaintHandler}>Add*/}
                       {/*        </button>*/}
                       {/*    </div>*/}
                       {/*}*/}


                       {/*<div className='w-full mb-2'>*/}
                       {/*    <div className={label_style}>{t("request_status")}</div>*/}
                       {/*    <Select*/}
                       {/*        value={{label: beneficiaryRequest.status, value: beneficiaryRequest.status}}*/}
                       {/*        name="RequestStatus"*/}
                       {/*        options={beneficRequestStatus}*/}
                       {/*        onChange={(val, action) => {*/}
                       {/*            setBeneficiaryRequest({...beneficiaryRequest, status: val["value"]})*/}
                       {/*        }}*/}
                       {/*        placeholder={t("status_1")}*/}
                       {/*        className="w-full h-auto rounded z-999 focus:shadow outline-none basic-multi-select"*/}
                       {/*        classNamePrefix="select"*/}
                       {/*    />*/}
                       {/*    <div>*/}
                       {/*        {isFormSubmit && beneficiaryRequest.status === "" && TextError(t("request_status"))}*/}
                       {/*    </div>*/}
                       {/*</div>*/}
                       <div className='w-full mb-2'>
                           <div className={label_style}>{t("request_status")}</div>
                           <Select
                               value={{label: beneficiaryRequest.status, value: beneficiaryRequest.status}}
                               name="RequestStatus"
                               options={beneficRequestStatus}
                               onChange={(val, action) => {
                                   setBeneficiaryRequest({
                                       ...beneficiaryRequest,
                                       status: val["value"],
                                       additional: ""// Reset additional information
                                   });
                               }}
                               placeholder={t("status_1")}
                               className="w-full h-auto rounded z-999 focus:shadow outline-none basic-multi-select"
                               classNamePrefix="select"
                           />
                           <div>
                               {isFormSubmit && beneficiaryRequest.status === "" && TextError(t("request_status"))}
                           </div>
                       </div>
                       <div className="mb-2">
                           <label className={label_style}>{"Requested Date"}</label>
                           <input
                               type="date"
                               className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                               value={beneficiaryRequest.date || ""}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                   setBeneficiaryRequest({...beneficiaryRequest, date: e.target.value});
                               }}
                           />
                       </div>

                       <div className="mb-2">
                           <label htmlFor="additional"
                                  className={label_style}>{t("additional_information")}</label>
                           <textarea
                               className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                               rows={3}
                               value={beneficiaryRequest.additional}
                               onChange={event => {
                                   const val = event.target.value
                                   setBeneficiaryRequest({...beneficiaryRequest, additional: val})
                               }}
                           >
                        </textarea>
                       </div>

                       <div className="bg-white rounded-lg mb-2">
                           <label htmlFor="additional"
                                  className={label_style}>{t("assign_to", {ns: "grievance_management"})}</label>
                           <Autocomplete
                               ref={cancelButtonRef}
                               id="select-user"
                               freeSolo
                               size={"small"}
                               value={safe_get(beneficiaryRequest, "assigned_to", {})}
                               options={concernedPersons}
                               autoHighlight={true}
                               filterOptions={(options, {inputValue}) => options.filter(item => safe_get(item, 'name', '').includes(inputValue) || safe_get(item, 'phonenum', '').includes(inputValue))}
                               getOptionLabel={(option) => safe_get(option, "name", "")}
                               onChange={(event, v) => {
                                   htmlValidation(event);
                                   setBeneficiaryRequest({...beneficiaryRequest, "assigned_to": v})
                               }}
                               renderOption={(props, option) => (
                                   <Box key={option["id"]} component="li"
                                        sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                       <div>
                                           <p className={"text-purple-600"}>{option["name"]}</p>
                                           <p>{option["phone"]}</p>
                                           <p>{option["designation"]}</p>
                                       </div>
                                   </Box>
                               )
                               }
                               renderInput={(params) => (
                                   <TextField
                                       {...params}
                                       label="Select User"
                                       ref={new_person}
                                       onChange={event => {
                                           htmlValidation(event);
                                       }}
                                       onBlur={() => console.log(new_person.current.value)}
                                       inputProps={{
                                           ...params.inputProps,
                                           autoComplete: 'new-password',
                                       }}
                                   />
                               )}
                           />
                       </div>

                       <div className="p-2 flex flex-row justify-between items-end flex-wrap ml-1">
                           <div className="flex flex-col">
                               <label htmlFor="project-name"
                                      className="block text-sm font-medium text-gray-700 pt-4 rounded-lg text-left "/>
                               <input
                                   key={inputKey}
                                   type="file"
                                   name="attachments"
                                   id="attachments"
                                   accept="image/*,application/pdf"
                                   className="py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   //     onChange={e => {
                                   //         const file = e.target.files[0];
                                   //         setFile({...file, file})
                                   //     }}
                                   // />
                                   onChange={(e) => {
                                       const selectedFile = e.target.files[0];
                                       if (selectedFile) {
                                           // Prepare the file object for upload
                                           setFile({
                                               file: selectedFile,
                                               name: selectedFile.name,
                                               size: selectedFile.size,
                                               type: selectedFile.type
                                           });
                                       }
                                   }}
                               />
                           </div>
                           <UploadButton uploadHandler={uploadFileToS3}/>

                       </div>
                       {/*<div className="flex flex-col justify-between itesm-center">*/}
                       {/*    {buffer.length > 0 &&*/}
                       {/*        <div className="font-bold"> {t("uploaded_documents")}</div>}*/}
                       {/*    {buffer.map((x, index) => {*/}
                       {/*        return (*/}
                       {/*            <div key={index}*/}
                       {/*                 className="flex flex-row items-center px-3 flex justify-between lg:w-7/12 ">*/}
                       {/*                {file_icon(x.type.split('/')[0])}*/}
                       {/*                <p className="pl-2 lg:mr-5">{x.path.split('/')[3]}</p>*/}
                       {/*                <i className="fa-solid fa-trash-can text-red-600 px-4"*/}
                       {/*                   onClick={() => {*/}
                       {/*                       delete_file(x.path)*/}
                       {/*                   }}/>*/}
                       {/*            </div>*/}
                       {/*        )*/}
                       {/*    })}*/}
                       {/*</div>*/}
                       <div className="mt-2">
                           {buffer.length > 0 && buffer.map((item, index) => (
                               <div key={index} className="flex items-center justify-between">
                                   <span className="text-sm">{item.path.split('/').pop()}</span>
                                   <button
                                       type="button"
                                       onClick={() => delete_file(item.path)}
                                       className="text-red-600 hover:text-red-800"
                                   >
                                       {t("delete")}
                                   </button>
                               </div>
                           ))}
                       </div>
                   </div>
                   <FormButtons
                       onSave={saveBeneficRequest}
                       onCancel={(e) => {
                           beneficiaryStore.update_add_request_modal(false)
                           setFormSUbmit(false)
                           beneficiaryStore.resetActiveFamily()
                           beneficiaryStore.resetActiveRequest()
                       }}
                   />
               </div>
           </TailwindModal>
        </>
    )
})
