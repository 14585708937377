import {useMutation} from "@tanstack/react-query";
import {useStore} from "../../../helpers";
import {toast} from "react-toastify";
import {useBeneficiaryMutationSuccess} from "./useBeneficiaryMutationSuccess";


export const useDeleteBeneficiary = () => {

    const rootStore = useStore();
    const {flexiStore, beneficiaryStore} = rootStore;
    const {onMutationSuccess} = useBeneficiaryMutationSuccess();

    const onDeleteBeneficiary = async (id) => {
        const res = await flexiStore.delete_fsd(id);
        if (!res.ok) {
            throw new Error("Something went wrong...");
        } else {
            return res;
        }
    };

    const {mutate: deleteBeneficiary, isLoading} = useMutation({
        mutationFn: onDeleteBeneficiary,
        onSuccess: async () => {
            await onMutationSuccess();
            toast.success("Beneficiary Deleted successfully")
            beneficiaryStore.update_beneficiary_delete(false)
            beneficiaryStore.update_show_benefic(false);
        },
        onError: ({message}) => {
            toast.error(message);
        },
    });

    return {
        deleteBeneficiary, isLoading
    }
}