import CheckboxTree from "react-checkbox-tree";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState, useMemo} from "react";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faCheckSquare,
    faSquare,
    faChevronRight,
    faChevronDown,
    faPlusSquare,
    faMinusSquare,
    faFolder,
    faFolderOpen,
    faFile
} from "@fortawesome/free-solid-svg-icons";

library.add(faCheckSquare, faSquare, faChevronRight, faChevronDown, faPlusSquare, faMinusSquare, faFolder, faFolderOpen, faFile);

type RNCheckBoxTreeProps = {
    data: any[],
    checked_nodes: any[],
    on_node_selection: (nodes: any[]) => void
}

const filterNodes = (nodes, query) => {
    const queryLower = query.toLowerCase();
    return nodes.filter(node => {
        if (node.label.toLowerCase().includes(queryLower)) {
            return true;
        }
        if (node.children) {
            const matchedChildren = filterNodes(node.children, query);
            if (matchedChildren.length > 0) {
                return true;
            }
        }
        return false;
    }).map(node => {
        if (node.children) {
            return { ...node, children: filterNodes(node.children, query) };
        }
        return node;
    });
};


export const RNCheckBoxTree = (props: RNCheckBoxTreeProps) => {

    const {checked_nodes, data, on_node_selection} = props;
    const [expanded, setExpanded] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");

    // Memoized filtered data
    const filteredData = useMemo(() => {
        return filterNodes(data, searchQuery);
    }, [data, searchQuery]);

    return (
        <>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                className="border p-2 my-2 w-full"
            />
            <CheckboxTree
                nodes={filteredData}
                checked={checked_nodes}
                expanded={expanded}
                checkModel={"leaf"}
                onCheck={(check) => {
                    on_node_selection(check);
                }}
                onExpand={(expand) => {
                    setExpanded(expand);
                }}
                showExpandAll
                icons={{
                    check: <FontAwesomeIcon icon="check-square"/>,
                    uncheck: <FontAwesomeIcon icon="square"/>,
                    halfCheck: <FontAwesomeIcon icon="check-square" className="rct-icon rct-icon-half-check"/>,
                    expandClose: <FontAwesomeIcon icon="chevron-right" className="rct-icon rct-icon-expand-close"/>,
                    expandOpen: <FontAwesomeIcon icon="chevron-down" className="rct-icon rct-icon-expand-open"/>,
                    expandAll: <FontAwesomeIcon icon="plus-square" className="rct-icon rct-icon-expand-all"/>,
                    collapseAll: <FontAwesomeIcon icon="minus-square" className="rct-icon rct-icon-collapse-all"/>,
                    parentClose: <FontAwesomeIcon icon="folder" className="rct-icon rct-icon-parent-close"/>,
                    parentOpen: <FontAwesomeIcon icon="folder-open" className="rct-icon rct-icon-parent-open"/>,
                    leaf: <FontAwesomeIcon icon="file" className="rct-icon rct-icon-leaf-close"/>,
                }}
            />
        </>
    );
};
