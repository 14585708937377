import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {BoothTree} from "../BoothTree";
import {FormHelperText, Typography} from "@mui/material";

type RNBoothSelectorPropTypes = {
    name: string;
    label?: string;
    isMulti: boolean;
    selector: string;
};

export const RNBoothSelector = (props: RNBoothSelectorPropTypes) => {

    const {label, name, isMulti, selector} = props;
    const {control, formState: {errors}} = useFormContext();

    return (
        <div>
            <Typography variant={"body1"} color={"primary"} className={"font-bold"}>
                {label}
            </Typography>
            <Controller
                name={name}
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                        <BoothTree
                            onBoothSelection={(data) => {
                                onChange(data);
                            }}
                            selected_booth={value}
                            selector={selector}
                            isMulti={isMulti}
                        />
                        {error && <FormHelperText error>{error?.message}</FormHelperText>}
                    </>
                )}
            />
        </div>
    );
};
