import { useParams } from "react-router-dom";
import { BreadCrumbs } from "../../components";
import React from "react";

export const GrievanceCrumbs = (props) => {
	const { projectid, fsdid } = useParams();
	console.log(fsdid);
	const crumbs = [
		{ name: "DashBoard", href: `/project/${projectid}`, current: true },
		{
			name: "Grievance Management V2",
			href: "/project/" + `${projectid}` + "/grievance_management_v2",
			current: true,
		},
	];
	return <BreadCrumbs crumbs={crumbs} />;
};
export const ViewGrievanceCrumbs = (props) => {
	const { projectid, fsdid } = useParams();
	console.log(fsdid);
	const crumbs = [
		{ name: "DashBoard", href: `/project/${projectid}`, current: true },
		{
			name: "Grievance Management V2",
			href: "/project/" + `${projectid}` + "/grievance_management_v2",
			current: true,
		},
		{
			name: "View Grievance",
			href: "/project/" + `${projectid}` + "/grievance_management_v2/" + fsdid + "/view",
			current: true,
		},
	];
	return <BreadCrumbs crumbs={crumbs} />;
};
