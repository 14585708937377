import {observer} from "mobx-react-lite";
import {remove_item_from_array, useStore} from "../../../helpers";
import {useTranslation} from "react-i18next";
import {useIsMobile} from "../../../custom-hooks";
import {ViewAttachment} from "../../../components";
import React from "react";
import {safe_get} from "../../../report-visuals";

export const RenderFamilyMembersV2 = observer((props) => {

    const { beneficiaryStore, beneficiaryStoreV2} = useStore();
    const { t } = useTranslation("beneficiary");
    const isMobile = useIsMobile();
    const {add_beneficiary_modal} = beneficiaryStore;
    const fsdid = safe_get(beneficiaryStore.activeBeneficiary, "id", null)
    const familyMembers = fsdid ?
        safe_get(beneficiaryStore.activeBeneficiary, "r1.family", []) :
        safe_get(beneficiaryStoreV2, "familyMembers", [])
    if (familyMembers.length <= 0) return null;
    if(beneficiaryStoreV2.familyMembers.length <= 0) return null;

    return (

        <div className='my-2 bg-white py-2 rounded-lg'>
            {!add_beneficiary_modal? <div className="flex flex-row items-center px-2 ">
                <i className="fas fa-users text-xl pr-4 text-purple-500"/>
                <h2 className='text-xl text-purple-500 font-bold py-2'>{t("family_members")}</h2>
            </div> : null}
            <ul role="list" className="grid grid-cols-1">
                {familyMembers.map((member) => {
                    //@ts-ignore
                    const { profile, profile_photo } = member;

                    if (!profile) return;
                    return (
                        <li key={member["id"]}>
                            <div className="rounded-lg flex flex-row items-center bg-gray-100 p-2 mb-2 mx-2 group gap-x-2">
                                {(("profile_photo" in member) && profile_photo.length > 0) ?
                                    <div className="avatar">
                                        <div className="mr-3 h-16 w-16 rounded-full">
                                            <ViewAttachment
                                                attachment={profile_photo[0]}
                                                s3_bucket="urn-assets"
                                                label={false}
                                            />
                                        </div>
                                    </div> :
                                    <div className="flex flex-nowrap flex-row items-center">
                                        <div className="rounded-full bg-blue-200 text-4xl h-16 w-16 flex items-center justify-center">
                                            <i className={"fa-solid fa-user-large"} />
                                        </div>
                                    </div>
                                }
                                <div className="flex flex-col w-full">
                                    <div className="flex flex-row justify-between items-center">
                                        <h3 className="font-bold text-indigo-600">{profile.name_eng}</h3>
                                        {<div className="flex flex-row items-center justify-between flex-wrap mr-1.5">
                                            <i className={`fas fa-pencil text-gray-600 px-1 cursor-pointer pr-3.5 transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" :"" }`}
                                               onClick={async () => {
                                                   await beneficiaryStore.setActiveFamilyMember(member)
                                                   await beneficiaryStore.update_add_familymember_modal(true)
                                               }} />
                                            <i className={`fas fa-trash text-red-600 px-1 cursor-pointer transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" :"" } `}
                                               onClick={() =>{
                                                   const updated_values = remove_item_from_array(
                                                       beneficiaryStoreV2.familyMembers,
                                                       "id",
                                                       member
                                                   );
                                                   beneficiaryStoreV2.update_family_members(updated_values)
                                               }
                                               }
                                            />
                                        </div>}
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                                        <p className="text-gray-600">{profile.age} Yrs</p>
                                        <p className="">{member["phone"]}</p>
                                        {profile?.additional && <p className="">{profile.additional}</p>}
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
})