import React, {useLayoutEffect, useRef} from 'react'
import {get_color} from "../../report-visuals";
import _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_sliceGrouper from "@amcharts/amcharts4/plugins/sliceGrouper";
import {useIsMobile} from "../../custom-hooks";
import {theme} from "../../pages";

export const DonutChart3d = (props: any) => {

    const {data,_label,value, colors=[], title=""} = props
    const donut_chart = useRef(null);
    const isMobile = useIsMobile();

    useLayoutEffect(() => {
        let chart_data = data
        const defaultColorsList = chart_data.map((x, ind) => {
            return get_color(ind);
        })

        chart_data = _.orderBy(chart_data, value,  "desc");

        let chart = am4core.create(title, am4charts.PieChart);
        chart.data = chart_data;
        chart.responsive.enabled = true;

        const pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = value;
        pieSeries.dataFields.category = _label;

        pieSeries.slices.template.propertyFields.fill = 'color';
        pieSeries.slices.template.stroke = am4core.color('#fff');
        pieSeries.slices.template.strokeWidth = 1;
        pieSeries.slices.template.strokeOpacity = 0.05;

        // Set colors from the data directly if the color property is present
        pieSeries.adapter.add("fill", (fill, target) => {
            // Make sure to check if target.dataItem.dataContext exists and is an object
            if (target.dataItem.dataContext && typeof target.dataItem.dataContext === "object") {
                // Check if the color property exists on the dataContext object
                if (target.dataItem.dataContext.hasOwnProperty("color")) {
                    // @ts-ignore
                    return target.dataItem.dataContext.color;
                }
                else {
                    return undefined
                }
            }
        });

        pieSeries.innerRadius = am4core.percent(50);
        let rgm = new am4core.RadialGradientModifier();
        rgm.brightnesses.push(-0.85, -0.85, -0.15, 0, -0.15);
        pieSeries.slices.template.fillModifier = rgm;
        pieSeries.slices.template.strokeModifier = rgm;
        pieSeries.slices.template.strokeOpacity = 0.3;
        pieSeries.slices.template.strokeWidth = 0;

        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        //Disable Labels
        pieSeries.labels.template.disabled = isMobile;
        pieSeries.labels.template.fontSize = 12;
        pieSeries.ticks.template.disabled = false;
        pieSeries.labels.template.text = `{category}: {value} ({value.percent.formatNumber("#.0")}% )`
        pieSeries.labels.template.maxWidth = 100; // Set a maximum width for the labels (adjust the value as needed)
        pieSeries.labels.template.truncate = true; // Truncate labels if they exceed the maximum width
        pieSeries.labels.template.wrap = true; // Enable text wrapping for labels
        pieSeries.labels.template.maxWidth = 150; // Set a maximum width for the labels (adjust the value as needed)


        //Legends
        chart.legend = new am4charts.Legend();
        chart.legend.fontSize = isMobile ? 12 : 14; // Customize the legend font size
        chart.legend.markers.template.width = isMobile ? 12 : 14; // Set the width of the legend markers
        chart.legend.markers.template.height = isMobile ? 12 : 14; // Set the height of the legend markers
        chart.legend.labels.template.fontSize = isMobile ? 12 : 14; // Set the font size of the legend labels

        //Disabled
        pieSeries.tooltip.disabled = false;


        let label = pieSeries.createChild(am4core.Label);
        label.text = title;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = 18;
        label.fontWeight = "bold";
        label.fill = am4core.color(theme);
        // label.text.toUpperCase() = title;


        // Group Others
        let grouper = pieSeries.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
        grouper.threshold = 1;
        grouper.groupName = "Others";
        grouper.clickBehavior = "break";

        // @ts-ignore
        donut_chart.current = chart;

    }, [data, isMobile]);
    return (
        <div style={{"width": "100%", "height": "600px"}} className={"flex flex-col mx-auto"}>
            <div id={title} className='w-full h-full'/>
        </div>
    )
}