import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const FilterButton = ({ filterOnClickHandler, ...props }) => {
  const { t } = useTranslation("survey_management");
  return (
    <Button
      variant={"contained"}
      endIcon={<i className="fas fa-filter" />}
      onClick={filterOnClickHandler}
      {...props}
      sx={{ backgroundColor: "orange" }}
      className={"self-start"}
    >
      {t("filters")}
    </Button>
  );
};
