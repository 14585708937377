import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers";
import {useWindowSize} from 'react-use';
import {safe_get} from "../../report-visuals";
import {toJS} from "mobx";
import _ from "lodash";
import {VoterRegistrationTable} from "./voter-reg-table";
import {VoterRegistrationResponseView} from "./voter-reg-response-view";
import {UpdateApplicationStatus} from "./update-application-status";
import {ApplicationSummary} from "./application-summary";
import { useTranslation } from "react-i18next";
import {FieldTeamReportMaps, FTRFilterPane} from "../survey-management";
import {useParams} from "react-router-dom";
import {BreadCrumbs, LoadingState, ProjectHeader} from "../../components";

export const VoterRegistrationTracker = observer((props: any) => {

    const { projectid } = useParams();

    const crumbs = [
        {name: 'Projects', href: '/projects', current: false},
        {name: 'Manage Project', href: '/project/' + projectid, current: false},
        {name: 'Voter Registration Tracker', href: '#', current: true},
    ];

    const {surveyResponseStore, projectStore} = useStore();
    const {activeSurveyId, searchHighlights, allSurveyResponses, allSurveyResponsesSummary, activeSurveyResponseId, new_filters} = surveyResponseStore;
    const {width} = useWindowSize();
    const isMobile = width <= 766;

    const [voter_reg_tracker_config, setVoter_reg_tracker_config] = useState(toJS(projectStore.voter_reg_tracker_config));
    const voter_reg_surveys = safe_get(voter_reg_tracker_config, "surveys", []);
    const [query, setQuery] = useState('');
    const {t} = useTranslation("new_voter_registration");

    const voter_reg_surveys_by = voter_reg_surveys && voter_reg_surveys.map(surveyid => {
        return projectStore.getSurveyByID(surveyid);
    });

    useEffect(() => {
        const config = projectStore.getProjectProperty("track_voter_reg_eci", {})
        setVoter_reg_tracker_config(config)
        const surveys = safe_get(config, "surveys", [])
        surveyResponseStore.setActiveSurveyId(surveys[0])
    }, [])

    useEffect(() => {
        get_es_voter_data();
    }, [activeSurveyId, query, new_filters]);


    const get_es_voter_data = async () => {
        const payload = {
            "projectid": parseInt(projectid),
            "surveyid": activeSurveyId,
            "search": query,
            "only_highlights": searchHighlights,
        }
        await surveyResponseStore.getFieldReport(payload)
    }

    const set_query = query => {
        setQuery(query)
    }

    const fpProps = {
        surveys: voter_reg_surveys_by,
        onSearch: set_query,
        highlights: searchHighlights
    }

    return (
        <>
            {!isMobile ? <>
                <BreadCrumbs crumbs={crumbs}/>
                <ProjectHeader/>
            </> : null}

            <div className="px-4">
                {surveyResponseStore.loading ? <LoadingState/> : null}
                {voter_reg_surveys_by ? <FTRFilterPane {...fpProps}/> : null}
                {
                    allSurveyResponses ?
                        <>
                            <div className="block text-lg font-medium text-indigo-500 font-bold uppercase">{t("voter_registration_tracker")}</div>
                            {!_.isEmpty(allSurveyResponsesSummary) && <VoterRegistrationTable/>}
                            {
                                activeSurveyResponseId ?
                                <div className="mb-6">
                                    <VoterRegistrationResponseView/>
                                    <ApplicationSummary survey={surveyResponseStore.activeSurveyResponse}/>
                                    <UpdateApplicationStatus config={voter_reg_tracker_config}/>
                                </div>
                                : null
                            }
                            { <FieldTeamReportMaps />}

                        </>
                        : <div className="font-bold p-4 text-2xl">{t("surveys_not_found")}....</div>
                }
            </div>

        </>
    )
})