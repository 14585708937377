import React, { useEffect } from "react";
import "./App.css";
import { MainRoutes } from "./routes/all";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReactGA from "react-ga4";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

import { ModuleRegistry } from "@ag-grid-community/core";

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

export const Theme = createTheme({
  // Create a custom MUI theme for consistent styling across the app.
  palette: {
    primary: {
      main: "#009EA6",
    },
    secondary: {
      main: "#8590a5",
    },
    success: {
      main: "#38c786",
    },
    info: {
      main: "#4e9def",
    },
    warning: {
      main: "#f4ba40",
    },
    error: {
      main: "#ed5e49",
    },
    background: {
      default: "#ffffff",
    },
  },
  typography: {
    // Define typography styles for different heading levels.
    fontFamily: "MukthaVani",
    h1: {
      fontFamily: "Palanquin",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: "Palanquin",
      fontWeight: "bold",
    },
    h3: {
      fontFamily: "Palanquin",
      fontWeight: "bold",
    },
    h4: {
      fontFamily: "Palanquin",
      fontWeight: "bold",
    },
    h5: {
      fontFamily: "Palanquin",
      fontWeight: 700,
    },
    h6: {
      fontFamily: "Palanquin",
      fontWeight: "bold",
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={Theme}>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <MainRoutes />
          <ToastContainer />
          <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
        </QueryClientProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
