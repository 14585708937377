import React from "react";
import { Route, Routes } from "react-router";
import { GrievanceManagementV2 } from "../../features/grievance-management-v2/grieviance-management-v2";
import { ViewGrievanceV2 } from "../../features/grievance-management-v2/view-grievance-v2";

export const GrievanceV2Routes = () => {
  return (
    <Routes>
      <Route path="/" element={<GrievanceManagementV2 />} />
      <Route path="/:fsdid/view" element={<ViewGrievanceV2 />} />
    </Routes>
  );
};
