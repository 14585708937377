import React from "react";
import {
    SideOverlay,
    LoadingState,
    ProjectHeader,
    } from "../../components";
import {useStore} from "../../helpers";
import {useIsMobile} from "../../custom-hooks";
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {AddEditGrievanceV2} from "./add-edit-grievance-v2";
import {GrievanceCrumbs} from "./grievance-crumbs";
import {GrievanceFilter} from "../grievance-management/grievanceFilter";
import {observer} from "mobx-react-lite";
import {DataNotAvailable} from "../../flexistore/renderkeys/mh-2024-field";
import GrievanceTable from "./grievance-table";
import {GrievanceHomeHeader} from "./grievance-v2-home-header";

export const GrievanceManagementV2 = observer((props: any) => {

    const {projectid} = useParams();
    const {grievanceStore, flexiStore, grievanceStoreV2} = useStore();
    const {searchQuery} = grievanceStoreV2;
    const {FilterStatus, filters} = grievanceStore;
    const isMobile = useIsMobile();

    const getGrievanceList = async () => {
        await flexiStore.set_fsd_search_query(searchQuery);
        const res = await flexiStore.fetch_fsdlist(
            "grievance",
            parseInt(projectid),
            filters
        );
        if (!res.ok) {
            throw new Error("Something went wrong");
        }
        return res;
    };

    const {data, isError, isLoading} = useQuery({
        queryKey: ["grievance", searchQuery, projectid],
        queryFn: getGrievanceList,
    });

    const closeFilterModal = () => {
        grievanceStore.setFilterStatus(false);
    };

    if (isLoading) return <LoadingState/>;

    return (
        <div>
            {!isMobile ?
                <>
                    <GrievanceCrumbs/>
                    <ProjectHeader/>
                </>
            : null}

            <GrievanceHomeHeader />
            <div className="mx-4">
                {data?.data?.results.length > 0 ?
                    <GrievanceTable data={data?.data?.results}/>
                    :
                    <DataNotAvailable/>
                }
            </div>
            <AddEditGrievanceV2 />
            <SideOverlay
                children={<GrievanceFilter/>}
                onClose={closeFilterModal}
                show={FilterStatus}
                title={"Grievance Filters"}
            />
        </div>
    );
});
