import React, { useCallback, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import _ from "lodash";
import ProfileQuestionOptions from "./ProfileQuestionOptions";
import CreateANDEditOptionsDialog from "../components/CreateANDEditOptionsDialog";
// import questionStore from "../store/QuestionStore";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { useStore } from "../../helpers";
import {formatName} from "../utitlity/formatName";
interface OptionsTabProps {
  handleSave: () => void;
}

const OptionsTab: React.FC<OptionsTabProps> = observer(({ handleSave }) => {
  const rootStore = useStore();
  const { questionStore } = rootStore;
  const current_data = questionStore.activeTab;
  const questiontitle = formatName(current_data.title);
  const [showModal, setShowModal] = useState(false);
  function addOption(options) {
    console.log(options);
    questionStore.addOption(current_data.title, options);
  }

  function updateOption(option, id) {
    current_data.data[id] = { label: option, value: option };
    questionStore.updateOption(current_data.title, current_data.data);
  }
  function deleteOption(option) {
    const isConfirmed = window.confirm(
      "Are you sure you want to remove [" + option + "] option ?"
    );
    if (isConfirmed) {
      questionStore.deleteOption(current_data.title, option);
    }
  }
  return (
    <>
      <CreateANDEditOptionsDialog
        show={showModal}
        type={"Add"}
        title={questiontitle}
        currentValue={""}
        id=""
        onSubmit={addOption}
        setShow={setShowModal}
      />
      <Card className="h-full">
        <CardContent>
          <div className="flex flex-wrap justify-between">
            <div>
              <Typography gutterBottom variant="h5" component="div">
                List of Options
              </Typography>
            </div>
            <div>
              {current_data.title && (
                <div className="flex justify-between gap-2">
                  <Button
                    variant="contained"
                    startIcon={<i className="fa-regular fa-plus-circle" />}
                    sx={{ color: "white" }}
                    color="warning"
                    onClick={() => setShowModal(!showModal)}
                  >
                    {questiontitle}
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<i className="fa-regular fa-floppy-disk" />}
                    color="success"
                    sx={{ color: "white" }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
          <ul className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-2">
            {current_data.data.map(({ label, value }, id) => {
              return (
                <ProfileQuestionOptions
                  data={label}
                  id={id}
                  title={current_data.title}
                  onUpdate={updateOption}
                  onDelete={deleteOption}
                />
              );
            })}
          </ul>
        </CardContent>
      </Card>
    </>
  );
});

export default OptionsTab;
