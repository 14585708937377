import {FormHelperText, TextField} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import React from "react";

export const RNInput = ({name, label, ...props}) => {

    const {control,trigger, formState: {errors},} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <div>
                    <TextField
                        sx={{marginTop: 0, marginBottom: 0, zIndex: 0}}
                        {...props}
                        {...field}
                        label={label}
                        variant="filled"
                        fullWidth
                        margin="normal"
                        error={!!errors[`${name}`]}
                        onBlur={(e) => {
                            trigger(name);
                            field.onBlur(); // Trigger the react-hook-form's onBlur
                            if (props.onBlur) props.onBlur(e); // Trigger your custom onBlur
                        }}
                    />
                    {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </div>
            )}
        />
    );
};
