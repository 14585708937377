import {observer} from "mobx-react-lite";
import {SearchVoterList, VoterListWithRadioButton} from "../../voter-list";
import {SideOverlay} from "../../../components";
import React from "react";
import {useTranslation} from "react-i18next";
import {useStore} from "../../../helpers";
import {safe_get} from "../../../report-visuals";


export const BeneficiaryVoterList = observer((props:any) => {

    const {beneficiaryStore, voterListStore, beneficiaryStoreV2} = useStore();
    const {t} = useTranslation(["beneficiary", "voter_list"]);
    const {showVoterlist} = voterListStore;

    const close_voterList = () => {
        voterListStore.setShowVoterlist(false);
    }

    const onVoterSelection = (voter) => {
        beneficiaryStore.update_add_beneficiary_modal(true);
        beneficiaryStoreV2.update_family_members(safe_get(voter, "family", []))
        close_voterList();
    }

    return(
        <SideOverlay
            onClose={close_voterList}
            show={showVoterlist}
            title={t("search_voters")}
        >
            <SearchVoterList/>
            <VoterListWithRadioButton onVoterSelection={onVoterSelection}/>
        </SideOverlay>
    )
})