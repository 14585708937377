import React from 'react';
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";

type FormButtonsProps = {
    onSave: (args) => void,
    onCancel: (args) => void,
    type?: "button" | "submit" | "reset"
}

export const FormButtons = (props: FormButtonsProps) => {

    const {onSave, onCancel, type="submit"} = props;
    const { t } = useTranslation("project_work");

    return(
        <div className="flex-shrink-0 border-t border-gray-200 p-4 sm:px-6">
            <div className="flex justify-end space-x-3 cursor-pointer">
                <Button
                    variant={"contained"}
                    onClick={onCancel}
                    color={"error"}
                    size={"small"}
                    startIcon={<i className="fas fa-window-close"/>}
                >
                    {t("cancel")}
                </Button>
                <Button
                    variant={"contained"}
                    onClick={onSave}
                    size={"small"}
                    type={type}
                    startIcon={<i className="fas fa-save"/>}
                >
                    {t("save")}
                </Button>
            </div>
        </div>
    )
}