import React from "react";
import {DashBoard} from "./dashboard-template";

export const MHFieldSummary = () => {

    const frame = `<iframe title="MH 2024 Field Status Summary" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiMTBhNTcwMWItMDcxNC00MzNiLWI0ZWQtZGE5OTQ1MjQxNzNlIiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`

    return (
        <>
            <DashBoard passCode={"field"} iframe={frame} />
        </>
    )
}