import React, {useState} from 'react'
import Select from "react-select";
import {toast} from 'react-toastify';
import {useStore} from '../../helpers';
import {safe_get} from '../../report-visuals';
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {Button} from "@mui/material";

export const UserTag = observer((props:any) => {

    const rootStore = useStore();
    const {userStore, projectStore} = rootStore;
    const [t] = useTranslation("user_management");
    const GetUserTags = projectStore.getProjectProperty("default_tags", {});
    const hierarchy = safe_get(
        JSON.parse(localStorage.getItem("userpartsandperms")),
        "parts",
        []
    );
    const permissions = safe_get(
        JSON.parse(localStorage.getItem("userpartsandperms")),
        "permissions",
        {}
    );
    const tags = safe_get(
        JSON.parse(localStorage.getItem("userpartsandperms")),
        "tags",
        []
    );

    const data = safe_get(
        JSON.parse(localStorage.getItem("userpartsandperms")),
        "voterprofile",
        []
    );

    const Usertags = tags.map((x) => {
        return {value: x, label: x}
    })
    const [selectedUserTags, setSelectedUserTags] = useState(Usertags);

    const handleUpdateUserTag = () => {
        userStore.setUserDetailsMode(false);
        userStore.updateUserPermissions({
            "projectid": projectStore.activeProject['id'],
            "userid": userStore.activeUser['id'],
            "permissions": permissions,
            "hierarchy": hierarchy,
            "tags": selectedUserTags
        })
        toast.success("UserTag Updated Successfully...");
    }

    return (
        <div>
            <label
                htmlFor="newProjectDescription"
                className="block text-md font-medium text-gray-700 pt-4"
            >
                {/* User Tags */}
            </label>

            <div className=" w-full mt-7">
                <Select
                    defaultValue={selectedUserTags}
                    isMulti
                    name="user_tags"
                    options={GetUserTags["default_tags"].map((x) => {
                        return {value: x, label: x};
                    })}
                    onChange={(val, action) => {
                        const value = val.map((x) => {
                            return x.value
                        })
                        setSelectedUserTags(value);
                    }}
                    placeholder="Filter by User_Tags..."
                    className="w-full h-10 rounded focus:shadow outline-none basic-multi-select"
                    classNamePrefix="select"
                />
            </div>

            <div className="py-5 flex flex-row-reverse justify-start">
                <Button
                    color={"success"}
                    onClick={handleUpdateUserTag}
                    variant={"contained"}
                    size={"large"}
                >
                    {t("update_user_tag")}
                </Button>
            </div>
        </div>
    )
})