import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { classNames, htmlValidation, SubHeading, useStore, error_toast } from "../../helpers";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { Carousel, DeleteButton, DeleteModal, EditButton, LoadingState, ProjectHeader, ViewAttachment } from "../../components";
import { displayVillageNames } from "../project-works";
import { GrievanceCrumbs, ViewGrievanceCrumbs } from "./grievance-crumbs";
import { AddEditGrievanceV2 } from "./add-edit-grievance-v2";
import { useQuery } from "@tanstack/react-query";
import { safe_get } from "../../report-visuals";
import { useWindowSize } from "react-use";
import { TimelineLog } from "./grievance-logs";
import RNAccordion from "../../components/Accordion";

const GRDetails = ({ title, value, direction = "flex-row" }) => {
	return (
		<div className={classNames(direction === "flex-col" ? "flex-col" : " flex-row items-center", "flex")}>
			<p className="text-md text-gray-800 font-bold pb-2">{title} :</p>
			<p className="text-gray-700 px-4 pb-2">{value}</p>
		</div>
	);
};

export const ViewGrievanceV2 = observer(() => {
	const params = useParams();
	const { grievanceStore, flexiStore, grievanceStoreV2 } = useStore();
	const { t } = useTranslation(["grievance_management", "project_work"]);

	const navigate = useNavigate();
	const { projectid, fsdid } = useParams();

	const { data, isError, isLoading } = useQuery({
		queryKey: ["view-grievance", projectid, fsdid],
		queryFn: async () => {
			const res = await flexiStore.get_fsdata(params.fsdid);
			if (res.status === 200) {
				return res.data;
			}
			throw new Error("Failed to fetch Grievance data");
		},
		onSuccess: (data) => {
			grievanceStoreV2.updateGrievanceMgmtV2Prop("activeGrievance", data.data);
			grievanceStoreV2.updateGrievanceMgmtV2Prop("attachments", data.data?.r1.attachments);
			grievanceStoreV2.updateGrievanceMgmtV2Prop("richMedia", data.data?.r1.richmedia);
		},
	});

	useEffect(() => {
		// getViewGrievanceData();
		return () => {
			grievanceStore.update_grievance_store_property("grievance_edit_mode", false);
			grievanceStore.update_grievance_store_property("isShowGrievanceForm", false);
		};
	}, []);

	const voters = safe_get(data, "r1.voters", []);
	const { width } = useWindowSize();
	const isMobile = width <= 766;

	const handleCloseModal = () => {
		grievanceStore.update_grievance_store_property("isShowGrievanceForm", false);
		grievanceStore.update_grievance_store_property("isViewGrievance", false);
	};

	const assigned_office_person = safe_get(data?.summary, "assigned_office_person", {});

	const onDeleteGrievanceHandler = async () => {
		const res = await flexiStore.delete_fsd(flexiStore.current_fsd.id);
		if (res.status === 200) {
			toast.success(t("grievance_deleted_successfully"));
			grievanceStore.update_grievance_store_property("isDeleteMode", false);
			navigate(-1);
		} else error_toast();
	};

	const closeDeleteModalHandler = () => {
		grievanceStore?.update_grievance_store_property("isDeleteMode", false);
	};

	const grie_title = safe_get(data, "summary.title", "Grievance Details");
	if (isLoading) return <LoadingState />;
	return (
		<>
			{!isMobile ? (
				<>
					<ViewGrievanceCrumbs />
					{/*<ProjectHeader />*/}
				</>
			) : null}

			<div className="px-4 viewgrievance w-full sm:max-w-7xl mx-auto ">
				{/* <Header /> */}
				<div className="flex flex-row justify-between items-center my-2">
					<div className="py-2 flex flex-row justify-between items-center">
						<h2 className="text-3xl text-indigo-500 font-bold ">{grie_title}</h2>
					</div>
					<div className="flex flex-row mx-2 gap-x-2">
						<DeleteButton
							OnDeleteClickHandler={(e) => {
								htmlValidation(e);
								grievanceStore.update_grievance_store_property("isDeleteMode", true);
							}}
						/>

						<EditButton
							OnEditClickHandler={() => {
								grievanceStoreV2.updateGrievanceMgmtV2Prop("addNewGrievanceModal", true);
							}}
						/>
					</div>
				</div>
				<div className="flex flex-row w-full main">
					<div className="flex flex flex-col grievancedetails w-full">
						{/* Grievance Details */}
						<div className="grid grid-cols-1 gap-y-4">
							<div className="items-start p-4 sm:px-4 bg-white rounded-lg shadow-lg">
								<div className="flex flex-row ite`ms-center ">
									<i className="fas fa-ballot-check fp-text text-xl pr-2 sm:pr-4" />
									<SubHeading title={t("grievance_details")} />
								</div>
								<div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-2">
									<GRDetails title={t("title")} value={data && data?.summary && data?.summary?.title} />
									<GRDetails title={t("department")} value={data && data?.summary && data?.summary?.category_level_1} />
									<GRDetails title={t("complaint")} value={data && data?.summary && data?.summary?.category_level_2} />
									<GRDetails
										title={t("location")}
										value={data && data?.summary && displayVillageNames(data?.summary?.piv_level_2)}
									/>
									<GRDetails title={t("priority")} value={data && data?.summary && data?.summary?.priority} />
									<GRDetails title={t("status")} value={data && data?.summary && data?.summary?.current_status} />
									<GRDetails
										title={t("comments")}
										value={data && data?.r1 && data?.r1?.timeline_log && data?.r1?.timeline_log[0]?.comments}
									/>
								</div>
								<div className="px-4">
									<GRDetails
										title={t("description")}
										direction="flex-col "
										value={data && data?.summary && data?.summary?.description}
									/>
								</div>
							</div>
							{/*Department*/}
							<div className="items-start p-4 sm:px-4 bg-white rounded-lg shadow-lg">
								<section>
									<div className="flex flex-row items-center ">
										<i className={"fas fa-building text-xl pr-2 fp-text sm:pr-4 "} />
										<SubHeading title={t("concerned_departement")} />
									</div>

									<div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-2">
										<GRDetails title={t("concerned_departement")} value={safe_get(data, "summary.dept", "N/A")} />
										{data &&
											data?.summary &&
											Object.keys(data?.summary?.assigned)?.map((x) => (
												<GRDetails title={t(`${x}`)} value={data?.summary?.assigned[x]} />
											))}
									</div>
								</section>

								{!isEmpty(assigned_office_person) && (
									<section>
										<div className="flex flex-row items-center">
											<i className={"fas fa-building text-xl pr-2 fp-text sm:pr-4 "} />
											<SubHeading title={t("assigned_op")} />
										</div>

										<div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-2">
											<GRDetails title={t("assigned_op")} value={safe_get(assigned_office_person, "name", "N/A")} />
											{Object.keys(assigned_office_person)?.map((x) => (
												<GRDetails title={t(`${x}`)} value={assigned_office_person[x]} />
											))}
										</div>
									</section>
								)}

								<div>
									{/* Attachments */}
									{data && data?.r1 && data?.r1?.attachments?.length > 0 && (
										<div className="flex flex-col my-2 items-start">
											<div className="flex flex-row text-left w-full">
												<SubHeading title="Uploaded Attachements" />
											</div>
											<div className="flex flex-row justify-between flex-wrap">
												{/* <ViewAttachments data={data.r1.attachments} /> */}
												{data?.r1?.attachments?.map((x, i) => {
													return (
														<div className="mx-2 px-2 underline">
															<ViewAttachment label={false} key={i} attachment={x} backgroundColor={""} />
														</div>
													);
												})}
											</div>
										</div>
									)}
								</div>
							</div>
							{/* User Details */}
							<div className="flex flex-col items-start p-4 bg-white rounded-lg shadow-lg">
								<div className="flex flex-row items-center ">
									<i className="fas fa-user-tie text-xl pr-2 sm:pr-4 fp-text" />
									<SubHeading title={t("user_details")} />
								</div>
								<div className="grid grid-cols-1 sm:grid-cols-2 gap-2 p-4">
									<GRDetails title={t("name")} value={data && data?.summary && data?.summary?.reported_by?.name} />
									<GRDetails title={t("phone")} value={data && data?.summary && data?.summary?.reported_by?.phone} />
									<GRDetails title={t("whatsapp_number")} value={data && data?.summary && data?.summary?.reported_by?.whatsapp} />
									<GRDetails title={t("voter_iD")} value={data && data?.summary && data?.summary?.reported_by?.voter_id} />
									<GRDetails title={t("aadhar_id")} value={data && data?.summary && data?.summary?.reported_by?.aadhar} />
									<GRDetails title={t("date_of_birth")} value={data && data?.summary && data?.summary?.reported_by?.dob} />
									<GRDetails title={t("age")} value={data && data?.summary && data?.summary?.reported_by?.age} />
									<GRDetails title={t("address")} value={data && data?.summary && data?.summary?.reported_by?.address} />
									<GRDetails title={t("caste")} value={data && data?.summary && data?.summary?.reported_by?.caste} />
									<GRDetails title={t("gender")} value={data && data?.summary && data?.summary?.reported_by?.gender} />
								</div>
								{/*<GRDetails title={t("description")} direction="flex-col" value={data && data.summary && data.summary?.reported_by?.description} />*/}
								{voters?.length > 0 && (
									<div className="flex flex-col items-start rounded-lg ">
										<SubHeading title={"Other Persons"} />
										<div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
											{voters?.map((voter) => {
												return (
													<div className=" bg-gray-200 rounded-lg my-2 p-2">
														<p className="font-bold text-md">{voter["profile"]["name_eng"]}</p>
														<p>{voter["phone"]}</p>
														<p>{voter["profile"]["address"]}</p>
													</div>
												);
											})}
										</div>
									</div>
								)}
							</div>
							{/* Media */}
							{data && data?.r1 && data?.r1?.richmedia?.length > 0 && (
								<div className="p-4 sm:px-6 cursor-pointer bg-white hover:bg-gray-50 rounded-lg shadow-lg items-start">
									<div className="flex flex-row text-left w-full">
										<SubHeading title={t("uploaded_media")} />
									</div>
									<div className="flex flex-col">
										{
											<Carousel
												items={data?.r1?.richmedia?.map((x, i) => {
													return (
														<div style={{ width: "500px" }}>
															<ViewAttachment label={false} key={i} attachment={x} backgroundColor={""} />
														</div>
													);
												})}
											/>
										}
									</div>
								</div>
							)}
						</div>
					</div>
					{/*TimeLine - Start*/}
					{/*<div className="flex flex flex-col lg:w-1/3 h-full timelinelog">*/}
					{/*	{data && data?.r1 && data?.r1?.timeline_log && (*/}
					{/*		<>*/}
					{/*			<div className="flex flex-row items-center px-8 pb-4">*/}
					{/*				<i className="fas fa-clock text-xl pr-4 fp-text" />*/}
					{/*				<SubHeading title={t("timeline_log", { ns: "project_work" })} />*/}
					{/*			</div>*/}
					{/*			<TimelineLog timeline_log={data?.r1?.timeline_log} mode={"VERTICAL"} />*/}
					{/*		</>*/}
					{/*	)}*/}
					{/*</div>*/}
					{/*TimeLine - End*/}
				</div>
				<div className="rounded-lg bg-white">
					<div className="flex flex-row items-center px-2">
						<i className="far fa-stream text-xl pr-4 text-purple-500"/>
						<h3 className="text-xl text-purple-500 font-bold py-2">{t("logs")}</h3>
					</div>
					<RNAccordion title={""} subtitle={"You can see the logs here"}>
						<TimelineLog timeline_log={data?.r1?.timeline_log} mode={"HORIZONTAL"} />
					</RNAccordion>
				</div>
				{/*<div className="flex flex flex-col h-full timelinelog">*/}
				{/*	{data && data?.r1 && data?.r1?.timeline_log && (*/}
				{/*		<>*/}
				{/*			<div className="flex flex-row items-center px-8 pb-4">*/}
				{/*				<i className="fas fa-clock text-xl pr-4 fp-text" />*/}
				{/*				<SubHeading title={t("timeline_log", { ns: "project_work" })} />*/}
				{/*			</div>*/}
				{/*			<TimelineLog timeline_log={data?.r1?.timeline_log} mode={"HORIZONTAL"} />*/}
				{/*		</>*/}
				{/*	)}*/}
				{/*</div>*/}
			</div>

			<AddEditGrievanceV2 />
			<DeleteModal
				onDeleteClick={onDeleteGrievanceHandler}
				onCancelClick={closeDeleteModalHandler}
				isOpen={grievanceStore?.isDeleteMode}
				desc={t("you_want_to_delete")}
			/>
		</>
	);
});
