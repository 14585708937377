import React from 'react';
import {MapContainer, TileLayer, GeoJSON, Marker, useMap, useMapEvents} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import L from 'leaflet';
import {useWindowSize} from 'react-use';
import {MarkLocations} from "../features/task-manager";
import {useStore} from "../helpers";

// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const SetBounds = ({bounds}) => {
    const map = useMap();
    map.fitBounds(bounds);
    return null
}

export const LeafletMap = (props:any) => {

    const rootStore = useStore();
    const {taskManagerStore} = rootStore;
    const {enable_marking_locations, marked_locations} = taskManagerStore;

    const {geojson, map_height, mode, task, bounds} = props;
    const {width} = useWindowSize();
    const isMobile = width <= 766;
    const _bounds =  L.geoJSON(geojson).getBounds();   /* Get map bounds from geoJson*/;

    //const custom_icon = new L.DivIcon({className: 'booth-icon', html: `<i class="fa-solid fa-location-check text-red-600 text-3xl"></i>`});
    const custom_icon = new L.DivIcon({className: 'booth-icon', html: `<i class="fa-solid fa-map-pin text-3xl" style="color: #B03A2E"/>`});

    return (
        <MapContainer style={{height: isMobile ? 400 : map_height, zIndex: 9999}}
                      center={[0,0]}
                      bounds={_bounds}
                      zoom={8}
                      scrollWheelZoom={true}
        >
            {
                mode === "leaflet" ?
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    /> :
                    <ReactLeafletGoogleLayer apiKey='AIzaSyCuZfb3JFcDYrKECKU91FKqjK764nvsFGI' type="roadmap"/>
            }

                {task && task["status"]["surveys"] && task["status"]["surveys"].map((item, index) => {
                    const {lat, long} = item;
                    if (!lat && !long) return;
                    console.log(lat, long)
                    return (
                        <Marker icon={custom_icon} key={index} position={[lat, long]}/>
                    )
                })}


            <GeoJSON data={geojson} style={{'fillColor': '#00EDB3', 'color': '#2E4053'}}/>

            <SetBounds bounds={_bounds} />

            {enable_marking_locations && <MarkLocations/>}

        </MapContainer>
    )
}

/*
npm uninstall --save lexical @lexical/clipboard @lexical/code @lexical/file @lexical/hashtag @lexical/headless @lexical/link @lexical/list @lexical/mark @lexical/overflow @lexical/plain-text @lexical/react @lexical/rich-text @lexical/selection @lexical/table @lexical/utils
npm install --save --force lexical @lexical/clipboard @lexical/code @lexical/file @lexical/hashtag @lexical/headless @lexical/link @lexical/list @lexical/mark @lexical/overflow @lexical/plain-text @lexical/react @lexical/rich-text @lexical/selection @lexical/table @lexical/utils
*/