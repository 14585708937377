import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useStore} from "../../../helpers";
import {useParams} from "react-router";

export const useCreateGrievance = () => {

    const rootStore = useStore();
    const {beneficiaryStore, flexiStore: {fsd_search_query}} = rootStore;
    const queryClient = useQueryClient();
    const {projectid} = useParams();

    const onGrievanceAdd = async (payload) => {
        const response = await beneficiaryStore.createBeneficiary(payload);
        if (response.status !== 200) {
            throw new Error("Something went wrong");
        }
        return response;
    };

    return useMutation({
        mutationFn: onGrievanceAdd,
        onSuccess: () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries([
                    "grievance",
                    fsd_search_query,
                    projectid,
                ]);
            }, 1000);
            toast.success("grievance added sucessfully");
        },
        onError: () => {
            toast.error("Couldn't add ,something went wrong");
        },
    });
};
