import React from "react";
import {DashBoard} from "./dashboard-template";

export const SomaliLandDashBoard = () => {

    const frame = `<iframe title="Somaliland Voters Analysis1.1" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiM2I3MzYyODctYjQzZS00NDgwLTgzMGQtZjg3Y2VjM2FlZjEzIiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`

    return (
        <>
            <DashBoard passCode={"x@x0@xHoA"} iframe={frame} />
        </>
    )
}