import React, { useState } from "react";
import { CSVLink } from "react-csv"
import moment from "moment";
import { useTranslation } from "react-i18next";
import {IconButton} from "@mui/material";

type ExcelProps = {
    data: {}[],
    cols?: {}[],
    name: string,
    classes?: string
}

export const DownloadAsExcelSheet = (props: ExcelProps) => {

    const { data, cols=[], name, classes } = props;
    const name_with_timestamp = `${name} - ${moment().format('LLL')}`;
    const [showDownload, setShowDownload] = useState(false);
    const { t } = useTranslation("downloadasexcelsheet");

    return (
        <div className={`flex flex-row items-center gap-x-4 justify-between ${classes}`}>
            <IconButton
                onClick={e => setShowDownload(!showDownload)}
                color={"warning"}
                size={"medium"}
            >
                <i className="fa-solid fa-download cursor-pointer" title={"Download"}/>
            </IconButton>

            {showDownload &&
                <CSVLink
                    data={data}
                    headers={cols}
                    filename={name_with_timestamp}
                >
                    <i className="text-blue-600 mx-4">Download</i>
                </CSVLink>
            }
        </div>
    )
}