import React from "react";
import {useParams} from "react-router-dom";
import {BreadCrumbs} from "../../../components";


export const MH2024Crumbs = ({fst}) => {
    const params = useParams();

    const crumbs = [
        {name: "DataBank", href: "/databank", current: false},
        {
            name: fst,
            href: "/databank/repo/" + `${params.fstid}/` + `${params.key}`,
            current: false,
        },
        {name: `AC Bank`, href: "#", current: true},
    ];

    return <BreadCrumbs crumbs={crumbs}/>;
};