import React from "react";
import {DashBoard} from "./dashboard-template";

export const MHOPV2DashBoard = () => {

    const frame = `<iframe title="MH OP Dashborad 1.1" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiMTE4MzcwMTktM2UyNC00NTViLThlNWEtM2I2ZGUxOTY1MjNlIiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`

    return (
        <>
            <DashBoard passCode={"maharashtra"} iframe={frame} />
        </>
    )
}