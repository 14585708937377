import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../helpers";
import {ACBankDAO} from "../acbank_dao";
import {FsFilterPane} from "../../../fsFilterPane";
import {InfluentialLeadersTable} from "./influential-leaders-table";
import {PlusIcon as PlusIconOutline} from "@heroicons/react/24/outline";
import AddInfluentialLeader from "./add-influential-leader";
import { useParams } from "react-router-dom";
import {toast} from "react-toastify";
import {safe_get} from "../../../../report-visuals";
import {Typography} from "@mui/material";
import {LoadingState} from "../../../../components";
import isEmpty from "lodash/isEmpty";

export const ACBankInfluentialLeaders = observer(props => {

    const rootStore = useStore();
    const {flexiStore} = rootStore;
    const params = useParams();
    const state = params.key;
    const dao = new ACBankDAO(rootStore)
    const acno = dao.get_summary_field('acno', 0);
    const fst_shared = safe_get(dao, "fsd.fst", []);

    useEffect(() => {
        dao.load_election_results();
        flexiStore.set_ac_number(acno);
        flexiStore.set_fsd_search_key(state);
    }, []);

    useEffect(() => {
        get_influential_leaders();
    }, [flexiStore.influential_leader_search_query]);

    const get_influential_leaders = async () => {
       await flexiStore.get_influential_leaders();
    }

    const handleSearch = (query) => {
        flexiStore.set_influential_leader_search_query(query);
    }

    const add_leader = async (payload) => {
       if(!flexiStore.influential_leader_edit_mode){
           const response = await flexiStore.add_influential_leader(payload);
           if (response.status === 200) {
               toast.success("Leader Added successfully...");
               flexiStore.set_add_leader_modal(false);
           }
       } else {
           const response = await flexiStore.update_influential_leader(payload);
           if (response.status === 200) {
               toast.success("Leader Updated successfully...");
               flexiStore.set_add_leader_modal(false);
           }
       }

       await flexiStore.get_influential_leaders();
    }

    const onCancel = () => {
        flexiStore.set_add_leader_modal(false);
    }

    if(flexiStore.loading) return <LoadingState />

    return (
        <div className="p-4">
            <div className="flex items-center justify-between flex-row">
                <Typography variant={"h4"} className={"flex flex-1"}>Influential People</Typography>
                <FsFilterPane onSearch={handleSearch}/>
                <button
                    type="button"
                    className="p-2 m-4 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                    onClick={(event) => {
                        flexiStore.set_add_leader_modal(true);
                        flexiStore.set_ileader_edit_mode(false);
                    }}
                >
                    <PlusIconOutline className="h-5 w-5" aria-hidden="true"/>
                </button>
                {!isEmpty(fst_shared) ? <AddInfluentialLeader onSave={add_leader} onCancel={onCancel} data={fst_shared}/> : null}
            </div>
            {flexiStore.influential_leaders && <InfluentialLeadersTable />}
        </div>
    )
})