import React from "react"
import {DeleteModal, LoadingState} from "../../components";
import {toast} from "react-toastify";
import {useParams} from "react-router";
import {useStore} from "../../helpers";
import {observer} from "mobx-react-lite";
import {useMutation, useQueryClient} from "@tanstack/react-query";

export const RemoveUser = observer(() => {

    const {projectid} = useParams();
    const {userStore} = useStore();
    const {remove_user_modal, activeUser} = userStore;
    const queryClient = useQueryClient();

    const remove_user_from_project = async (id) => {
        const payload = {
            projectid: parseInt(projectid),
            userid: parseInt(id),
        };
        return await userStore.remove_user_from_project(payload);
    };

    const {mutate, isLoading} = useMutation({
        mutationFn: remove_user_from_project,
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["users", projectid])
            }, 1000);
            toast.success("User deleted from the project")
            closeDeleteModal()
        },
        onError: (error) => {
            toast.error("Something went wrong");
        }
    })


    const closeDeleteModal = () => {
        userStore.update_user_store_prop("remove_user_modal", false);
    }

    if(isLoading) return <LoadingState />

    return (
        <>
            <DeleteModal
                isOpen={remove_user_modal}
                onDeleteClick={() => {
                   mutate(activeUser.id)
                }}
                onCancelClick={() => {
                    closeDeleteModal()
                }}
                desc={`Do you want to delete ${activeUser.name} from this project ?`}
                id={activeUser.id}
            />
        </>
    )
})