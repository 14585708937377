import React from "react";
import {Slide, Bounce} from "react-awesome-reveal";
import {Button, CardMedia, Typography} from "@mui/material";
import {useWindowSize} from "react-use"
import AudioPlayer from "./audio-player";

const Audio = "https://demo.rajneethi.com/somaliland/Audio%20-%20full%20speech%20of%20president_1.mp3"

export const SLAR = props => {

    const {width} = useWindowSize();

    const bstyle = {
        fontWeight: "bold",
        width: width <= 450 ? width * 0.9 : "auto",
        letterSpacing: "2px",
        color: "black",
        padding: "8px 10px",
        textAlign: "center"
    }

    return (
        <div className={"h-auto w-auto flex flex-col gap-y-6 sm:gap-y-2 items-center justify-evenly bg-gradient-to-t from-yellow-500 to-green-500 h-screen py-4"}>
           <div className={"flex flex-col items-center gap-y-2"}>
               <img
                   src={"sl-flag.jpg"}
                   alt="SomaliLand"
                   className={"max-w-[200px]"}
               />
               <img src={"kulmiye.png"} className={"max-w-[280px]"}/>
           </div>
            <Slide direction={"left"}>
                <Button
                    variant={"contained"}
                    size={"large"}
                    target={"_blank"}
                    href={"https://ar.forthepeople.ai/p/MBC-2024"}
                    sx={{
                        ...bstyle,
                        backgroundColor: "yellow"
                    }}
                    title={"Common Vote Appeal"}
                >
                    U CODEE muse Biixi
                </Button>
            </Slide>
            <div className={"flex flex-col items-center gap-y-4"}>
                <Slide direction={"right"}>
                    <Button
                        variant={"contained"}
                        size={"large"}
                        sx={{
                            ...bstyle,
                            backgroundColor: "#05db4c"
                        }}
                        href={"https://ar.forthepeople.ai/p/MadkuxigeenSaajin"}
                        target={"_blank"}
                        title={"VP Vote Appeal"}
                    >
                        U codee Musharax  Madaxwayne Ku xigeen Maxmuud xasan seejin
                    </Button>
                </Slide>
                <Slide direction={"right"}>
                    <Button
                        variant={"contained"}
                        size={"large"}
                        href={"https://ar.forthepeople.ai/p/dumarka"}
                        target={"_blank"}
                        sx={{
                            ...bstyle,
                            backgroundColor: "yellow"
                        }}
                        title={"Women"}
                    >
                        Ilaalinta xuquuqda Dumarka
                    </Button>
                </Slide>
                <Slide direction={"left"}>
                    <Button
                        variant={"contained"}
                        size={"large"}
                        sx={{
                            ...bstyle,
                            backgroundColor: "#05db4c"
                        }}
                        href={"https://ar.forthepeople.ai/p/HawlWadeenada"}
                        target={"_blank"}
                        title={"Party Workers"}
                    >
                        Hawl wadeenada Kulmiye
                    </Button>
                </Slide>
            </div>
            <div className={"flex flex-col gap-y-3 items-center"}>
                <Slide direction={"left"}>
                    <Button
                        variant={"contained"}
                        size={"large"}
                        sx={{
                            ...bstyle,
                            backgroundColor: "yellow",
                        }}
                        href={"https://ar.forthepeople.ai/p/SawirLaMuuseBiixi"}
                        target={"_blank"}
                        startIcon={<img src={"selfie.png"} className={"max-w-[50px]"} />}
                        title={"Photo"}
                    >
                        Sawir la gal Madaxweyne Muuse Biixi
                    </Button>
                </Slide>
                <Slide direction={"right"}>
                    <Button
                        startIcon={<img src={"youtube.png"} className={"max-w-[50px]"} />}
                        variant={"contained"}
                        href={"https://www.youtube.com/watch?v=VUZbdzpoloM"}
                        target={"_self"}
                        sx={{
                            ...bstyle,
                            backgroundColor: "#05db4c",
                        }}
                    >
                        Dhagayso Khudbada Madaxweyne Muuse Biixi
                    </Button>
                </Slide>
            </div>
        </div>
    )
}