import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {FlexiStoreData} from "../../../stores/fs/fst";
import {safe_get} from "../../../report-visuals/report-utils";
import JSONModal from "../../jsonModal";
import {classNames, useStore} from "../../../helpers";
import {instanceToPlain} from "class-transformer";
import {useParams} from "react-router-dom";
import {BoothDataModal} from "./BoothDataModal";
import {cooked_grader, grader} from "./booth-reports-utils";
import {useCurrentFSD} from "../../../custom-hooks/useCurrentFSD";
import { useTranslation } from 'react-i18next';
import {BreadCrumbs, LoadingState} from "../../../components";
import {grading, RenderCookedBoothReport, RenderLiveBoothReport} from "./booth-report-view-components";


export const BoothReportView = observer((props) => {

    const params = useParams();

    const crumbs = [
        {name: 'DataBank', href: '/databank', current: false},
        {name: 'Repositories', href: '/databank/repo/' + `${params.key}`, current: false},
        {name: `Booth Report`, href: '#', current: true}
    ];

    const config = useCurrentFSD();
    const {flexiStore, surveyResponseStore} = useStore();
    const [group_by_booth, set_group_by_booth] = useState({});
    const [loading, setLoading] = useState(false);
    const [configModalOpen, setConfigModalOpen] = useState(false);
    const [cookedBoothDataModalOpen, setCookedBoothDataModalOpen] = useState(false);
    const [t] = useTranslation(["booth_bank"])
    const {booth_details,report_booth_key,booth_key_prefix, skip_transform,filter_booths,filter_booths_whitelist, analytical_dashboard} = config

    useEffect(() => {
        if (!flexiStore.current_fsd_ready ) return;
            if (skip_transform) {
                load_grArr_from_booth_details()
            } else {
                load_survey_data_from_es()
            }
    }, [flexiStore.current_fsd.r1["config"]])

    useEffect(() => {
        load_grArr_from_booth_details()
    }, [group_by_booth])

    const load_survey_data_from_es = async () => {
        setLoading(true)
        // @ts-ignore
        const data: FlexiStoreData = flexiStore.current_fsd;
        const payload = {
            "index_name": data.summary["index_name"]
        }
        await surveyResponseStore.get_esdata(payload)
        const _tmp = await transformSurveys()
        console.log({_tmp})
        set_group_by_booth(_tmp)
        flexiStore.update_group_by_booth(_tmp)
        setLoading(false)
    }

    const load_grArr_from_booth_details = () => {

        const _tmp = [];

        Object.keys(booth_details).sort().map(booth_key => {
            let display = {};
            if(!analytical_dashboard){
                display = skip_transform ? cooked_grader(booth_key,flexiStore.current_fsd.r3,config) : grader(booth_key,config,group_by_booth);
            } else {
                display = grading(flexiStore.current_fsd.r6[`${booth_key}`])
            }

            if (!display) return null;
            if (display['display'] === 'IN PROGRESS') return null;

            if (filter_booths) {
                const check_filter_key:string = booth_key.replace(booth_key_prefix, '');
                if (!filter_booths_whitelist.includes(check_filter_key)) return null;
            }

            _tmp.push({
                booth: `${booth_key_prefix}${booth_key}`,
                grade: display
            })
        })
         flexiStore.update_swot_by_booth(_tmp)
    }

    //Arrange the surveys by Booth
    const transformSurveys = async() => {
        const _tmp = {}

       const res = await surveyResponseStore.allSurveyResponses.map(survey => {

            let key = safe_get(survey, report_booth_key, '')
            if (key === '') return;

            if (filter_booths) {
                const check_filter_key = key.replace('Booth #', '');
                if (!filter_booths_whitelist.includes(check_filter_key)) return;
            }

            //filter invalid surveys
            if (survey['qa_status'] === 'invalid') return;

            if (!Object.keys(_tmp).includes(key)) {
                _tmp[key] = []
            } else {
                _tmp[key].push(survey)
            }
        })
        console.log({res})
        return _tmp;
    }

    const onConfigUpdate = async(updated_json) => {
        setConfigModalOpen(false)
        await flexiStore.update_current_fsd("r1", updated_json)
        await flexiStore.api_update_current_fsd();
    }

    const onConfigCancel = () => {
        setConfigModalOpen(false)
    }

    const onCookedBoothDataUpdate = async (updated_json, booth_prefix, booth_no) => {
        setCookedBoothDataModalOpen(false)
        setLoading(true)
        await flexiStore.override_booth_report(updated_json, booth_prefix, booth_no)
        load_grArr_from_booth_details();
        setLoading(false)
    }

    const onCookedBoothDataEditorCancel = () => {
        setCookedBoothDataModalOpen(false)
    }

    const jsonEditorToggle = () => {
        return (
            <div className="fixed top-10 right-10 flex flex-row items-center">
                <div>{t("edit_configuration")}</div>
                <div
                    className={classNames(!configModalOpen ? 'cursor-pointer' : 'pointer-events-none opacity-50', 'text-purple-500 pr-4 ml-2')}
                    onClick={() => {
                        setConfigModalOpen(true)
                    }}><i className="fa-solid fa-code text-2xl"/></div>
            </div>
        )
    }

    const BoothDataEditorToggle = () => {
        return (
            <div className="fixed top-10 right-10 flex flex-row items-center" style={{top: 80}}>
                <div>{t("edit_booth_report")}</div>
                <div
                    className={classNames(!cookedBoothDataModalOpen ? 'cursor-pointer' : 'pointer-events-none opacity-50', 'text-purple-500 pr-4 ml-2')}
                    onClick={() => {
                        setCookedBoothDataModalOpen(true)
                    }}><i className="fa-solid fa-code text-2xl"/></div>
            </div>
        )
    }

    const onQltyReportUpdate =async (updated_json, booth_prefix, booth_no)=> {
        setCookedBoothDataModalOpen(false)
        setLoading(true)
        await flexiStore.override_booth_report(updated_json, booth_prefix, booth_no)
        load_grArr_from_booth_details();
        setLoading(false)
    }

    return (
        <>
            <BreadCrumbs crumbs={crumbs}/>
            {loading ? <LoadingState/> : null}
            <div className='noprint'>
                {jsonEditorToggle()}
                <JSONModal
                    open={configModalOpen}
                    data={JSON.stringify(instanceToPlain(flexiStore.current_fsd.r1), null, 4)}
                    handleUpdate={onConfigUpdate}
                    handleCancel={onConfigCancel}
                />
                {
                    skip_transform ? (
                        <>
                            {BoothDataEditorToggle()}
                            <BoothDataModal
                                open={cookedBoothDataModalOpen}
                                data={flexiStore.current_fsd.r3}
                                onCookedBoothDataUpdate={onCookedBoothDataUpdate}
                                handleCancel={onCookedBoothDataEditorCancel}
                                onReportEdit={onQltyReportUpdate}
                            />
                        </>
                    ) : null
                }
            </div>
            {skip_transform ? <RenderCookedBoothReport /> : <RenderLiveBoothReport />}
        </>
    )
})

