import {useStore} from "../../../helpers";
import {useParams} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";


export const useBeneficiaryMutationSuccess = () => {

    const rootStore = useStore();
    const { projectid } = useParams();
    const {flexiStore, beneficiaryStore} = rootStore;
    const queryClient = useQueryClient();
    const {filters} = beneficiaryStore;
    const {fsd_search_page_size, fsd_search_current_page, fsd_search_query} = flexiStore;

    const onMutationSuccess= async() => {
        setTimeout(async () => {
            await queryClient.invalidateQueries(["beneficiary", projectid, fsd_search_current_page, fsd_search_page_size, filters, fsd_search_query])
        }, 1000);
    }

    return {
        onMutationSuccess
    }
}