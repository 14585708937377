import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useWindowSize} from 'react-use';
import { useParams } from "react-router-dom";
import {safe_get} from "../../report-visuals";
import {useStore} from "../../helpers";
import _ from "lodash";
import {TaskList} from "./task-list";
import {EditTask} from "./task-edit";
import {CreateTask} from "./task-create";
import {TaskView} from "./task-view";
import {TaskFilters} from "./task-filters";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {BreadCrumbs, LoadingState, ProjectHeader, SideOverlay} from "../../components";
import {useGetFullProject} from "../../custom-hooks";

export const TaskManager = observer(props => {

    const {projectid} = useParams();
    const rootStore = useStore();
    const {taskManagerStore, projectStore} = rootStore;
    const {active_task, loading, task_filter_modal} = taskManagerStore;
    const {width} = useWindowSize();
    const isMobile = (width <= 766);
    const [t]=useTranslation("task_manager");
    const {isLoading} = useGetFullProject();

    const crumbs = [
        {name: "DashBoard", href: `/project/${projectid}`, current: true},
        {name: 'Task Manager', href: '#', current: true},
    ];

    useEffect(() => {
        const active_project = JSON.parse(localStorage.getItem("__active_project"));
        taskManagerStore.set_surveys(safe_get(active_project, "surveys", []));
        fetch_users();
    }, [projectid]);

    useEffect(() => {
        taskManagerStore.set_task_edit_modal(false);
        taskManagerStore.set_task_view_modal(false);
        taskManagerStore.set_task_list_query("");
    }, [projectid]);


    const fetch_users = async() => {
        const payload = {
            "projectid": parseInt(projectid),
            "tags": [],
            "query": "",
            "limit": 500,
            "offset": 0
        }
        const res = await projectStore.getAllUsers(payload);
        if(res.status === 200){
            taskManagerStore.set_users(safe_get(res.data, "users", []))
        } else {
            toast.error("Users are not loaded, please refresh the page.")
        }
    }

    if(isLoading) return <LoadingState />

    return (
        <>
            {!isMobile ? <BreadCrumbs crumbs={crumbs}/> : null}
            <ProjectHeader/>
            <div className='px-4'>
                {loading && <LoadingState/>}
                <CreateTask/>
                <TaskList/>
                {!(_.isEmpty(active_task)) && <EditTask/>}
                {!(_.isEmpty(active_task)) && <TaskView/>}

                <SideOverlay
                    onClose={() => {
                        taskManagerStore.update_task_filter_modal(false)
                    }}
                    show={task_filter_modal}
                    children={<TaskFilters />}
                    title={t("task_filters")}
                />

            </div>
        </>
    )

})