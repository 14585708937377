import {SideOverlay} from "../../../components";
import {SearchVoterList, VoterListWithCheckBox} from "../../voter-list";
import React, {useRef} from "react";
import {observer} from "mobx-react-lite";
import _ from "lodash";
import {useStore} from "../../../helpers";
import {useTranslation} from "react-i18next";


export const AddFmFromVoterList = observer((props:any) => {

    const {beneficiaryStore, beneficiaryStoreV2} = useStore();
    const {addFamilyFromVoterListModal} = beneficiaryStore;
    const family_members = useRef([]);
    const {t} = useTranslation("beneficiary");

    const onSelection = (voter) => {
        let family = Object.assign([], family_members.current);

        if (_.findIndex(family, {id: voter.id}) === -1) {
            family.push(voter);
        } else {
            family.splice(_.findIndex(family, {id: voter.id}), 1);
        }
        family_members.current = family;
    };

    const onCancel = () => {
        beneficiaryStore.update_addFamilyFromVoterListModal(false);
    };

    const onSave = async () => {
        await beneficiaryStoreV2.update_family_members([...beneficiaryStoreV2.familyMembers, ...family_members.current]);
        onCancel();
    };

    return (
        <SideOverlay
            onClose={onCancel}
            show={addFamilyFromVoterListModal}
            title={t("search_voters")}
        >
            <SearchVoterList/>
            <VoterListWithCheckBox
                onSelection={onSelection}
                onSave={onSave}
                onCancel={onCancel}
                checked={family_members.current}
            />
        </SideOverlay>
    )
})