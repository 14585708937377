import React, { useCallback, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { LeaderList } from "./LeaderList";
import Button from "@mui/material/Button";
import CreateANDEditOptionsDialog from "../CreateANDEditOptionsDialog";
import {useStore} from "../../../helpers";
import {formatName} from "../../utitlity/formatName";



interface LeaderHeaderProps {
    handleSave: () => void;
}
const LeaderHeader: React.FC<LeaderHeaderProps> = observer(({ handleSave }) => {
    const rootStore = useStore();
    const {influenceLeaderProjectProperty} = rootStore;
    const current_data = influenceLeaderProjectProperty.activeTab;
    let title = formatName(current_data.title);


    const [showModal, setShowModal] = useState(false);

    const addOption =  (Options) =>{
        // You want to pass only the values to addOption

        influenceLeaderProjectProperty.addOption(current_data.title, Options);


    } // when u add any value then this will display on front end using this function


    const updateOption = (option,id) =>{
      current_data.data[id] = option;
      console.log(current_data.data[id]);
        influenceLeaderProjectProperty.updateOption(current_data.title,current_data.data);
    } // edit your list with ID 

    const deleteOption = (option) => {
      const isConfirmed = window.confirm(
        "Are you sure you want to remove [" + option + "] option ?"
      );
      if(isConfirmed){
          influenceLeaderProjectProperty.deleteOption(current_data.title,option);
      }
    }

    return (
        <>
            <CreateANDEditOptionsDialog
                show={showModal}
                type={"Add"}
                title={title}
                currentValue={""}
                id=""
                onSubmit={addOption}
                setShow={setShowModal}
            />
          <Card className="h-screen">
            <CardContent>
              <div className="flex flex-wrap justify-between">
                <div>
                  <Typography gutterBottom variant="h5" component="div">
                    List of Options
                  </Typography>
                </div>

                <div>
              {current_data.title && (
                  <div className="flex justify-between gap-2">
                      <Button
                          variant="contained"
                          startIcon={<i className="fa-regular fa-plus-circle"/>}
                          sx={{color: "white"}}
                          color="warning"
                          onClick={() => setShowModal(!showModal)}
                      >
                          {title}
                      </Button>
                      <Button
                          variant="contained"
                          startIcon={<i className="fa-regular fa-floppy-disk"/>}
                          color="success"
                          sx={{color: "white"}}
                          onClick={handleSave}
                      >
                          Save
                      </Button>
                  </div>
              )}
                </div>

              </div>
                <div>

                </div>

                <ul className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-2">
                    {current_data.data.map((partydata, id) => {
                        return (
                            <LeaderList
                                data={partydata}
                                id={id}
                                title={current_data.title}
                                onUpdate={updateOption}
                                onDelete={deleteOption}

                            />
                        );
                    })}
                </ul>

            </CardContent>
          </Card>
        </>
    );
});

export default LeaderHeader;



