import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormHelperText, Typography } from "@mui/material";

export const FileInputField = ({ name, label = "", accept, multiple=false }) => {

    const {control, formState: { errors }} = useFormContext();

  return (
    <div>
      {/*<Typography variant={"caption"} color={"primary"} >{label}</Typography>*/}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <input
              type="file"
              multiple={multiple}
              accept={accept}
              onChange={(e) => {
                onChange(e.target.files);
              }}
            />
            {error && <FormHelperText error>{error?.message}</FormHelperText>}
          </>
        )}
      />
    </div>
  );
};
