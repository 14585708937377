import {BoothsByGrade} from "./BoothsByGrade";
import BoothReportMatrixTable, {
    BoothReportSimpleTable,
    cooked_grader,
    PageSection,
    RenderACName
} from "../booth-reports-utils";
import {safe_get} from "../../../../report-visuals/report-utils";
import {LeafletMap} from "../../../../components";
import _ from "lodash";
import {Form20BoothWise} from "./Form20BoothWise";
import React from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../helpers";
import {useCurrentFSD} from "../../../../custom-hooks/useCurrentFSD";
import {AnalyticalDashBoard} from "./render-analytical-dashboard";

export const RenderCookedBoothReport = observer((props: any) => {

    const {flexiStore} = useStore();
    const {swot_by_booth} = flexiStore;
    const config = useCurrentFSD();
    const {current_fsd} = flexiStore;
    const {
        booth_details,
        ac_name,
        title,
        sub_title,
        report_questions,
        booth_key_prefix,
        filter_booths,
        filter_booths_whitelist,
        booth_report_maps,
        f20_enabled,
        qlty_sections,
        show_swot,
        header,
        hide_maps,
        analytical_dashboard
    } = config;

    const acname = ac_name ? ac_name : title ;

    return (
        <>
            <div className='print-parent section-to-print'>


                <RenderACName title={acname} sub_title={sub_title}/>
                <BoothsByGrade key={`booth_count_${swot_by_booth.length}`}/>
                <PageSection text="Detailed Booth Reports"/>


                {/*TODO:Chittoor - Show overall summary*/}
                <div className='page-container bg-white mx-auto p-6 flex flex-row items-center justify-center'>
                    <div className='m-2 p-2' style={{width: "450px"}}>
                        <div className='font-bold text-3xl ml-6 text-left py-4'>Overall Summary</div>
                        <BoothReportMatrixTable
                            widget={{}}
                            ds={safe_get(current_fsd.r6, "overall_summary", [])}
                            booth_key={""}
                        />
                    </div>

                </div>

                {!analytical_dashboard ?
                    current_fsd.r3 && Object.keys(booth_details).sort().map(booth_key => {
                        const {left, name, booth, right} = booth_details[booth_key]
                        const gp = safe_get(booth_details[booth_key], "gp", "");
                        //const ds = group_by_booth[`${booth_key_prefix}${booth_key}`]
                        let ds = current_fsd.r3[`${booth_key_prefix}${booth_key}`]

                        if (!ds) return null;

                        if (filter_booths) {
                            const check_filter_key = booth_key.replace(booth_key_prefix, '');
                            if (!filter_booths_whitelist.includes(check_filter_key)) return null;
                        }

                        // For grading
                        const {display, fgcol, bgcol} = cooked_grader(booth_key, current_fsd.r3, config)

                        if (display === "IN PROGRESS") return null;

                        const maps = safe_get(booth_report_maps, booth_key, {})
                        const hasmaps = Object.keys(maps).length > 0
                        let geojson = safe_get(maps, 'geojson', {});

                        return (
                            <>
                                <div className='page-container bg-white mx-auto mb-4'>
                                    <div
                                        className={`grid grid-cols-3 gap-4 bg-blue-100 text-center font-bold text-xl`}>
                                        <div className='flex flex-col items-center'>
                                            <div className='ml-2 py-4'>{acname}<br/>
                                                <span>{left}</span> <br/>
                                                {gp && <span>GP: {gp}</span>}
                                            </div>
                                        </div>

                                        <div
                                            className='ml-2 py-4 flex items-center justify-center'>{right}</div>
                                        {show_swot && <div
                                            className='text-center text-white py-2 ml-16 flex justify-center items-center'
                                            style={{backgroundColor: bgcol, color: fgcol}}>{display}</div>}
                                    </div>
                                    <div className='text-center bg-gray-100 py-1'>{name}</div>
                                    {!hide_maps && <div className='text-center  pb-1' style={{
                                        fontFamily: "sans-serif",
                                        textAlign: "center",
                                        height: "8cm"
                                    }}>
                                        {hasmaps ? (
                                            <LeafletMap geojson={geojson} map_height={"8cm"} mode="leaflet"/>
                                        ) : null}
                                    </div>}
                                    {header && <div className="font-bold text-lg uppercase px-4 pt-2">{header} :-</div>}
                                    <div className={"ml-12"}>
                                        {
                                            report_questions.map((widget, index) => {
                                                if(widget.type !== "matrix") return null
                                                // @ts-ignore
                                                const {colkey, title, width} = widget

                                                return (
                                                    <div key={index} className='m-2 p-2' style={{width: width}}>
                                                        <div className='font-bold text-indigo-700'>{title}</div>
                                                        <BoothReportMatrixTable
                                                            widget={widget}
                                                            ds={ds[colkey]}
                                                            booth_key={booth_key}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 1200: 2}} className="ml-12">
                                        <Masonry gutter={"10px"}>
                                            {
                                                report_questions.map((widget, i) => {
                                                    if(widget.type === "matrix") return null
                                                    // @ts-ignore
                                                    const {colkey, headers, is_array, title, width} = widget
                                                    const transformed = _.orderBy(ds[colkey], ['surveys'], ['desc'])

                                                    return (
                                                        <div key={i} className='m-2 p-2' style={{width: `350px`}}>
                                                            <div className='font-bold text-indigo-700'>{title}</div>
                                                            <BoothReportSimpleTable
                                                                widget={widget}
                                                                ds={ds}
                                                                transformed={transformed}
                                                                booth_key={booth_key}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Masonry>
                                    </ResponsiveMasonry>


                                    <div className="ml-12">
                                        {
                                            qlty_sections.length > 0 && qlty_sections.map(section => {

                                                const sectype = safe_get(section, 'type', 'richtext')

                                                if (sectype !== 'richtext') return null;
                                                const hasValue = safe_get(ds[section["key"]], "value", [])
                                                if(!hasValue.length) return null;
                                                return (
                                                    <div key={section["key"]} className="mx-4 my-4">
                                                        <p className="font-bold text-indigo-700">{section["display"]}</p>
                                                        {/*<SlateEditor*/}
                                                        {/*    data={safe_get(ds[section["key"]], "value", initialValue)}*/}
                                                        {/*    read_only={true}*/}
                                                        {/*    more={{key: "sample"}}*/}
                                                        {/*    onEditorChange={() => {}}*/}
                                                        {/*/>*/}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    <div className="ml-12 flex flex-row flex-wrap">
                                        {
                                            qlty_sections.length > 0 && qlty_sections.map(section => {

                                                const sectype = safe_get(section, 'type', 'richtext')
                                                const table_width = safe_get(section, 'width', '330px')

                                                if (sectype !== 'table') return null;

                                                return (
                                                    <div key={section["key"]} className="mx-4 my-4"
                                                         style={{width: table_width}}>
                                                        <p className="font-bold text-indigo-700">{section["display"]}</p>
                                                        {/*<HTableViewOnly*/}
                                                        {/*    columns={section['headers']}*/}
                                                        {/*    more={{*/}
                                                        {/*        key: section.key,*/}
                                                        {/*        eleid: makeid_alpa_numeric(4)*/}
                                                        {/*    }}*/}
                                                        {/*    readonly={true}*/}
                                                        {/*    defaultvalue={safe_get(ds, section.key, [])}*/}
                                                        {/*/>*/}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {f20_enabled ? <Form20BoothWise booth_key={booth_key}/> : null}
                                </div>
                            </>
                        )
                    }) : <AnalyticalDashBoard />
                }
            </div>
        </>
    )
})