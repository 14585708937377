import {observer} from "mobx-react-lite";
import {SubHeading, useStore} from "../../../helpers";
import {RNSelect} from "../../../components/form-components";
import {Button, Divider} from "@mui/material";
import React, {useMemo} from "react";
import {getConcernedOfficePersonsOptions, useGrievanceOptions} from "../grievance-v2-hooks";
import {safe_get} from "../../../report-visuals";
import {useFormContext} from "react-hook-form";


export const ConcernedPerson = observer(() => {

    const {grievanceStore} = useStore()
    const grievanceOptions = useGrievanceOptions();
    const {watch} = useFormContext();
    const department = watch("category_level_1");

    const authorityPerson = useMemo(() => {
        const val = safe_get(grievanceOptions.grievanceProperties, `departments.${department}.owners`, []);
        return getConcernedOfficePersonsOptions(val)
    }, [department])

    return (
        <>
            <SubHeading title={"Office Person"}/>
            <div className={"flex flex-col gap-y-3"}>
                <RNSelect
                    label={"Concerned Person"}
                    name={"assigned"}
                    options={grievanceOptions.concerned_office_persons_options}
                />
                {department && (
                    <>
                        <RNSelect
                            label={"Authority Person"}
                            name={"assigned_office_person"}
                            options={authorityPerson}
                        />
                        <Button
                            variant={"text"}
                            onClick={() => {
                                grievanceStore.update_grievance_store_property(
                                    "isAddNewEnginner",
                                    true
                                );
                            }}
                        >
                            {"Add Engineer / Authority person"} +
                        </Button>
                    </>
                )}
                <Divider/>
            </div>
        </>
    )
})