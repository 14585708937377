import {Route, Routes} from "react-router-dom";
import {C3Beneficiaries, C3Demographics, C3Grievance, C3Historical, C3Home, C3PartyWorkers} from "../../features";
import React from "react";
import {C3projectWorks} from "../../features/constituency-command-center/c3-project-works";


export const C3Routes = () => {
    return (
        <Routes>
            <Route path="/" element={<C3Home/>}>
                <Route index element={<C3Demographics/>}/>
                <Route path="demographics" element={<C3Demographics/>}/>
                <Route path="historical" element={<C3Historical/>}/>
                <Route path="beneficiaries" element={<C3Beneficiaries/>}/>
                <Route path="partyworkers" element={<C3PartyWorkers/>}/>
                <Route path="grievance" element={<C3Grievance/>}/>
                <Route path="project_works" element={<C3projectWorks/>}/>
            </Route>
            <Route path="/:suffix" element={<C3Home/>}/>
        </Routes>
    )
}