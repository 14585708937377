import * as Yup from "yup";

export const AddFamilyMembersValidations = Yup.object().shape({
  name_eng: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone number is required"),
  voterid: Yup.string().required("Voter ID is required"),
  aadharid: Yup.string().required("Aadhar is required"),
  dob: Yup.string(),
  age: Yup.number().required("Age is required"),
  gender: Yup.string().required("Gender is required"),
  relation_name_eng: Yup.string(),
  relationship_eng: Yup.string(),
  additional: Yup.string(),
  profile_photo: Yup.mixed().test("file", "Please select a photo", (value) => {
    return value["length"] > 0;
  })
});
