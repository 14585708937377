import React, {useRef, useState} from "react";
import {htmlValidation} from "../helpers";
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField} from "@mui/material";
import {Visibility, VisibilityOff, Search} from "@material-ui/icons";

export const FsFilterPane = (props) => {
    const {onSearch} = props
    const [query, setQuery] = useState("")

    return (
        <div className='py-4 flex flex-row flex-wrap space-y-2 lg:space-y-0 flex-1'>
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
                <OutlinedInput
                    label="Search"
                    sx={{backgroundColor: "white"}}
                    id="outlined-size-small"
                    size="medium"
                    type={'text'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setQuery(event.target.value);
                    }}
                    onKeyUp={event => {
                        if (event.key === "Enter" && event.keyCode === 13) {
                            htmlValidation(event)
                            onSearch(query)
                        }
                    }
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => onSearch(query)}
                                onMouseDown={() => onSearch(query)}
                                edge="end"
                            >
                                <Search/>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </div>
    )
};