import React, {useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {Typography} from "@mui/material";

export const SWOTTable = ({data}) => {

    const rowStyle = {display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'center'};

    const fieldValue = Object.keys(data[0]).includes("bname_2014") ? "bname_2014" : "bname_2019"
    const rowData = useMemo(() => data, [data])
    const [colDefs] = useState([
        {
            field: fieldValue,
            headerValueGetter: (param) => param?.colDef?.field?.split("_")[1],
            headerClass: "text-lg font-bold",
            cellClass: "ag-grid-center-text"
        },
        {
            headerName: 'Booth No. 2024',
            field: 'bdetails_2024.bno',
            headerClass: "text-lg font-bold",
            cellClass: "ag-grid-center-text"
        },
        {
            headerName: 'Booth Name 2024',
            field: 'bdetails_2024.bname',
            headerClass: "text-lg font-bold",
            cellClass: "ag-grid-center-text"
        },
        {
            headerName: 'Vote Details',
            headerClass: "text-lg font-bold",
            field: "vote_details",
            flex: 1,
            cellRenderer: data => {
                // console.log(data)
                const values = data.value.split("\n")
                return (
                    <>
                        {values.map((value, i) => <Typography key={i}>{value}</Typography>)}
                    </>
                )
            }
        },
        {
            headerName: 'SWOT',
            field: 'grade.display',
            headerClass: "text-lg font-bold",
            cellClass: "ag-grid-center-text ag-text-align-center",
            cellStyle: (data) => {
                return {color: data.data.grade.fgcol,
                    backgroundColor: data.data.grade.bgcol,
                    borderRadius: "10px",
                    textAlign: "center"
                }
            }
        },
    ])

    return (
        <>
            <div
                className="ag-theme-quartz" // applying the Data Grid theme
                style={{height: 900, padding: "15px"}} // the Data Grid will fill the size of the parent container
            >
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    rowStyle={rowStyle}
                    gridOptions={{
                        getRowHeight: (params) => {
                            // Calculate row height based on content
                            const values = params.data.vote_details.split("\n")
                            return values.length * 25;
                        },
                    }}
                />
            </div>
        </>
    )
}