import React, {useState} from "react"
import {observer} from "mobx-react-lite";
import {isEmpty} from "lodash"
import {DataNotAvailable} from "./mh-2024-fieldview-main-page";
import {Typography} from "@mui/material";
import {DonutChart3d} from "../../../components/Charts";
import {SWOTTable} from "./swot-table";


export const MHSwotAnalysis = observer(({data} : any) => {

    const [activeSection, setActiveSectio] = useState(Object.keys(data)[0])

    if(isEmpty(data)) return <DataNotAvailable />;

    return(
        <>
            <div className={"flex flex-row flex-wrap w-full py-4"}>
                {
                    Object.keys(data).map(key => {
                        const bg = (activeSection === key) ? 'bg-indigo-500 text-white font-bold' : 'bg-gray-200 border border-black'
                        return (
                            <div
                                className={`mt-2 py-2 px-4 text-sm ${bg} w-fit cursor-pointer mr-4 rounded-2xl capitalize`}
                                onClick={() => setActiveSectio(key)}
                            >
                                {key}
                            </div>
                        )
                    })
                }
            </div>
            <div className={"sm:mb-8 mt-2"}>
                <DonutChart3d data={data[activeSection]["donut"]} _label="label" value="value" title={activeSection}/>
                <SWOTTable key={activeSection} data={data[activeSection]["details"]}/>
            </div>
        </>
    )
})

