import React, {useEffect, useState} from "react"
import {Button, FormControlLabel, Stack, Typography} from "@mui/material";
import {TailwindModal} from "./TailwindModal";
import {useStore} from "../helpers";
import {useQuery} from "@tanstack/react-query";
import {LoadingState} from "./LoadingState";
import {toast} from "react-toastify";
import Checkbox from '@mui/material/Checkbox';
import {useParams} from "react-router";

export const UserAgreement = () => {

    const rootStore = useStore();
    const [isSigned, setIsSigned] = useState(false)
    const [isAccepted, setIsAccepted] = useState(false)
    const [html, setHtml] = useState(null)
    const {projectid} = useParams();

    const {isLoading} = useQuery({
        queryKey: ["user-agreement"],
        queryFn: async () => await rootStore.api.post("utils/eulaterms"),
        onSuccess: data => setHtml(data.data),
        enabled: !isSigned
    })

    useEffect(() => {
        try {
            const value = localStorage.getItem("isSigned");
            setIsSigned(value !== "yes")
        } catch (e) {
            toast.error("User agreement have some issues, please try again later")
            console.log(e)
        }
    }, [projectid])

    // if (isLoading) return <LoadingState/>

    return (
        <TailwindModal isOpen={isSigned}>
            <div
                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                style={{maxHeight: "80vh", maxWidth: "80vw", overflow: "scroll"}}
            >
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    className={"gap-x-4 p-4 sticky top-0 bg-gray-200"}
                >
                    <Typography
                        variant={"h4"}
                        color={"primary"}>
                        User Terms and Conditions
                    </Typography>
                </Stack>
                <div className={"p-4"} dangerouslySetInnerHTML={{__html: html}}/>

                <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}
                       className={"gap-x-4 p-4 sticky bottom-0 bg-gray-200"}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={isAccepted}
                            onChange={() => {
                                setIsAccepted(!isAccepted)
                            }}
                        />}
                        label="I acknowledge that I have read, understood, and accept the Terms and Conditions in their entirety"
                    />
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        className={"gap-x-4"}
                    >
                        <Button
                            color={"error"}
                            variant={"outlined"}
                            sx={{fontWeight: "bold"}}
                            onClick={async () => await rootStore.userStore.logout()}
                        >
                            Decline & Logout
                        </Button>
                        <Button
                            disabled={!isAccepted}
                            color={"success"}
                            variant={"contained"}
                            sx={{color: "white", fontWeight: "bold"}}
                            onClick={() => {
                                localStorage.setItem("isSigned", "yes")
                                setIsSigned(false)
                            }}
                        >
                            Accept & Continue
                        </Button>
                    </Stack>
                </Stack>
            </div>
        </TailwindModal>
    )
}