import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {useStore} from "../../../helpers";

export const useGetBeneficiaryList = () => {

    const {projectid} = useParams();
    const rootStore = useStore();
    const {beneficiaryStore, flexiStore} = rootStore;
    const {filters} = beneficiaryStore;
    const {fsd_search_query} = flexiStore
    const {fsd_search_page_size, fsd_search_current_page} = flexiStore;

    const fetchBeneficiries = async () => {
        return await flexiStore.fetch_fsdlist("beneficiary", parseInt(projectid), filters);
    };

    const beneficiaryList = useQuery({
            queryKey: ["beneficiary", projectid, fsd_search_current_page, fsd_search_page_size, filters, fsd_search_query],
            queryFn: fetchBeneficiries,
            onSuccess: data => {
                beneficiaryStore.set_beneficiries(data["data"]["results"])
            }
        }
    )

    return beneficiaryList

}