import React, {memo} from "react"
import RNAccordion from "../../components/Accordion";
import {TimelineLog} from "../grievance-management";
import {useTranslation} from "react-i18next";

export const BeneficiaryLogs = memo(({logs}:any) => {
    const { t } = useTranslation("beneficiary");
    return (
        <>
            <div className="rounded-lg bg-white">
                <div className="flex flex-row items-center px-2">
                    <i className="far fa-stream text-xl pr-4 text-purple-500"/>
                    <h3 className="text-xl text-purple-500 font-bold py-2">{t("logs")}</h3>
                </div>
                <RNAccordion title={""} subtitle={"You can see the logs here"}>
                    <TimelineLog timeline_log={logs} mode={"VERTICAL"}/>
                </RNAccordion>
            </div>
        </>
    )
})