import React, { useRef} from "react";
import { Dialog } from "@headlessui/react"
import { PlusIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import {TailwindModal} from "./TailwindModal";

export const CreateModel = (props: any) => {

  const { onCreateClick, onCancelClick, isOpen, id, desc } = props;
  const cancelButtonRef = useRef(null);
  const [t] = useTranslation(["createmodel", "beneficiary"]);

  return (
    <TailwindModal isOpen={isOpen}>
      <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
            <PlusIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
            >
              {t("create")}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{desc}</p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={(event) => {
                onCreateClick(event, id);
              }}
          >
            {t("proceed")}
          </button>
          <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={(event) => onCancelClick(event)}
              ref={cancelButtonRef}
          >
            {t("cancel",{ns:"beneficiary"})}
          </button>
        </div>
      </div>
    </TailwindModal>
  );
};
