export type grievanceSummaryType = {
    title: string,
    category_level_1: string,
    category_level_2: string,
    specific_complaint: string,
    piv_level_1: string,
    piv_level_2: string,
    assigned: string,
    assigned_office_person: string,
    caste: string,
    location: string,
    priority: string,
    tracing_id: string,
    description: string,
    reported_by: {
        age: number,
        dob: string,
        name: string,
        caste: string,
        phone: string,
        aadhar: string,
        gender: string,
        address: string,
        voter_id: string,
        whatsapp: string
    },
    current_status: string,
    sla: string,
    attachments: Array<any>,
    media: Array<any>,

}

export const grievanceSummaryDefaultValues: grievanceSummaryType = {
    title: "",
    category_level_1: "",
    category_level_2: "",
    specific_complaint: "",
    piv_level_1: "",
    piv_level_2: "",
    assigned: "",
    assigned_office_person: "",
    caste: "",
    location: "",
    priority: "",
    tracing_id: "",
    description: "",
    reported_by: {
        age: 0,
        dob: "",
        name: "",
        caste: "",
        phone: "",
        aadhar: "",
        gender: "",
        address: "",
        voter_id: "",
        whatsapp: ""
    },
    current_status: "",
    sla: "",
    attachments: [],
    media: []
}

export type attachment = {
    name: string,
    path: string,
    type: string
}