import { action, makeAutoObservable } from "mobx";
import { GrievancePropertyKeysType } from "./grievance-property-keys";
import { safe_get } from "../../report-visuals";

export class GrievanceMgmtStoreV2 {
  rootStore: any;

  addNewGrievanceModal: boolean = false;
  editNewGrievanceModal: boolean = false;
  showFiltersModal: boolean = false;
  activeGrievance: any;
  searchQuery: string = "";
  richMedia: Array<any> = [];
  attachments: Array<any> = [];

  constructor(rootStore: any) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  updateGrievanceMgmtV2Prop = (key: GrievancePropertyKeysType, value: any) => {
    switch (key) {
      case "addNewGrievanceModal":
        this.addNewGrievanceModal = value;
        break;
      case "editNewGrievanceModal":
        this.editNewGrievanceModal = value;
        break;
      case "activeGrievance":
        this.activeGrievance = value;
        break;
      case "searchQuery":
        this.searchQuery = value;
        break;
      case "showFiltersModal":
        this.showFiltersModal = value;
        break;
      case "richMedia":
        this.richMedia = value;
        break;
      case "attachments":
        this.attachments = value;
        break;
    }
  };
}
