import React, {useState} from 'react';
import {TailwindModal} from "../components";

function JSONModal(props) {

    const {open, handleUpdate, handleCancel, data} = props;
    const [jsonTxtData, setJsonTxtData] = useState(data)

    return (
        <>
            <TailwindModal isOpen={open}>
                <div
                    className="inline-block align-bottom bg-white w-full rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                    <div>
                            <textarea
                                className='text-code w-full bg-gray-50 px-4 py-2'
                                value={jsonTxtData}
                                onChange={(evt) => {
                                    setJsonTxtData(evt.target.value);
                                }}
                                style={{height: '550px'}}
                            />
                    </div>
                    <div className="mt-5 sm:mt-6 flex flex-row justify-between items-center">
                        <button
                            type="button"
                            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-500 sm:text-sm mx-2"
                            onClick={(evt) => {
                                handleCancel();

                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 sm:text-sm mx-2"
                            onClick={(evt) => {
                                let updated_json = JSON.parse(data);
                                try {
                                    updated_json = JSON.parse(jsonTxtData);
                                } catch (e) {
                                }

                                handleUpdate(updated_json);

                            }}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </TailwindModal>
        </>
    )
}

export default React.memo(JSONModal)