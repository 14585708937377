import React from "react";
import {SideOverlay, ViewAttachment} from "../../components";
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers";
import {Avatar, Divider, Stack, Typography} from "@mui/material";
import {ProfileInfo} from "../beneficiary-management";
import {C3StarRating} from "../constituency-command-center";
import {safe_get} from "../../report-visuals";
import {defaultILValues} from "./il-types";


const ILInfo = ({title, value}) => {
    return (
        <div className={"flex flex-row items-center"}>
            <Typography variant={"body1"} sx={{fontWeight: "bold"}}>{title} :</Typography>
            <p className={"px-2"}>{value}</p>
        </div>
    )
}

export const InfluentialLeaderCard = observer((props:any) => {

    const rootStore = useStore();
    const {influentialLeadersStore} = rootStore;
    const {viewILModal, activeInfluentialLeader} = influentialLeadersStore;

    const closeViewModal = () => {
        influentialLeadersStore.updateIlStoreProperty("viewILModal", false)
        influentialLeadersStore.updateIlStoreProperty("activeInfluentialLeader", defaultILValues)
    }

    return (
        <>
            <SideOverlay
                onClose={closeViewModal}
                show={viewILModal}
                title={"Influential Leader"}
            >
                <div className={"flex flex-col gap-y-2"}>
                    <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} className={"gap-y-2"}>
                        <Avatar
                            sx={{ width: 150, height: 150 }}
                            title={activeInfluentialLeader?.profile?.name}
                        >
                            { <ViewAttachment label={false} attachment={activeInfluentialLeader?.profile?.photo_path}/> }
                        </Avatar>
                        <ProfileInfo title={activeInfluentialLeader.profile.name} icon="fa-solid fa-user font-bold" />
                        <ProfileInfo title={activeInfluentialLeader.profile.phonenumber} icon="fa-solid fa-phone font-semibold"/>
                        <ProfileInfo title={activeInfluentialLeader.node?.display} icon="fas fa-map-marker font-semibold"/>
                        <C3StarRating
                            value={parseFloat(String(activeInfluentialLeader.profile.rating))}
                            isEditable={false}
                            onStarClickHandler={() => {}}
                        />
                    </Stack>

                    <Divider />
                    <Stack direction={"column"} justifyContent={"center"} className={"gap-y-2"}>
                        <Typography variant={"h5"} color={"primary"}>Affiliation</Typography>
                        <ILInfo title={"Party Support"} value={activeInfluentialLeader.affiliation.party}/>
                        <ILInfo title={"Candidate Support"} value={activeInfluentialLeader.affiliation.candidate}/>
                        <ILInfo title={"Strength of Support"} value={activeInfluentialLeader.affiliation.strength_of_support}/>
                    </Stack>
                    <Divider />
                    <Stack direction={"column"}  justifyContent={"center"} className={"gap-y-2"}>
                        <Typography variant={"h5"} color={"primary"}>Influence</Typography>
                        <ILInfo title={"Caste"} value={activeInfluentialLeader.influence.caste}/>
                        <ILInfo title={"No.Of Voters he/she can influence"} value={activeInfluentialLeader.influence.voters}/>
                    </Stack>
                    <Divider />
                    <Stack direction={"column"}  justifyContent={"center"} className={"gap-y-2"}>
                        <Typography variant={"h5"} color={"primary"}>Other Information</Typography>
                        <ILInfo title={"Age"} value={safe_get(activeInfluentialLeader.profile, "age", "N/A")}/>
                        <ILInfo title={"Whatsapp Number"} value={safe_get(activeInfluentialLeader.profile, "whatsapp_number", "N/A")}/>
                        <ILInfo title={"Occupation"} value={activeInfluentialLeader.more.primary_occupation}/>
                        <ILInfo title={"Is Contested in Local Elections"} value={activeInfluentialLeader.more.local_elections_contested}/>
                        <ILInfo title={"Is He/She Party Volunteer ?"} value={activeInfluentialLeader.is_volunteer ? "Yes" : "No"}/>
                        <ILInfo title={"Additional Info"} value={activeInfluentialLeader.more.additional_information}/>
                        {activeInfluentialLeader.is_volunteer ? <>
                            <ILInfo title={"Position"} value={activeInfluentialLeader.volunteer.position}/>
                            <ILInfo title={"Roles"} value={""}/>
                            {activeInfluentialLeader.volunteer.roles.map((role, i) => <p key={i}>{role["label"]}</p>)}
                            </> : null}
                    </Stack>
                </div>
            </SideOverlay>
        </>
    )
})