import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {C3BoothFilter, C3ConcernedPerson, DateFilter} from "./filters";
import {Button} from "@mui/material";
import {FormButtons, SideOverlay} from "../../components";
import moment from "moment/moment";
import {useLocation} from "react-router-dom";
import {useStore} from "../../helpers";

type c3FilterType = {
    booths: Array<string>,
    start_date: string,
    end_date: string,
    office_persons: Array<string>
}

const defaultC3Filters: c3FilterType = {
    booths: [],
    start_date: '',
    end_date: '',
    office_persons: []
};

export const C3Filters = observer((props:any) => {

    const { c3Store } = useStore()
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false)

    const [c3Filters, setC3Filters] = useState<c3FilterType>(defaultC3Filters);

    const showDateSlider = pathname.includes("beneficiaries") || pathname.includes("grievance");

    const onBoothSelection = (booths: []) => {
        setC3Filters({ ...c3Filters, booths })
    }

    const onPersonSelection = (booths: []) => {
        setC3Filters({ ...c3Filters, office_persons:  booths })
    }

    const onDateSelection = (start_date, end_date = moment().format("YYYY-MM-DD")) => {
        setC3Filters({ ...c3Filters, start_date: start_date, end_date: end_date })
    }

    const filter_save_handler = () => {
        setLoading(true)
        c3Store.update_selected_booths(c3Filters.booths);
        c3Store.update_date_filters(c3Filters.start_date, c3Filters.end_date);
        c3Store.update_selected_office_persons(c3Filters.office_persons);
        filter_cancel_handler();
        setLoading(false)
    }

    const filter_cancel_handler = () => {
        c3Store.update_c3FilterModal(false)
    }

    return (
        <>
            <SideOverlay
                title="Select Filters"
                onClose={filter_cancel_handler}
                show={c3Store.c3FiltersModal}
            >
                <>
                    <div className='bg-gray-100 rounded-lg flex flex-col flex-1'>
                        <div className='custom-dropdown flex flex-col flex-1'>
                            <p className="text-blue-600 font-bold text-xl">Booth Filters</p>
                            <C3BoothFilter
                                onBoothSelection={onBoothSelection}
                            />
                        </div>
                        <div className='custom-dropdown flex flex-col flex-1'>
                            <p className="text-blue-600 font-bold text-xl px-2 py-4">Concerned Office Person</p>
                            <C3ConcernedPerson
                                onPersonSelection={onPersonSelection}
                            />
                        </div>

                        {showDateSlider && <div className='flex flex-col flex-1'>
                            <p className="text-blue-600 font-bold text-xl px-2 py-4">Date</p>
                            <div className={"flex flex-col px-2"}>
                                <DateFilter
                                    onDateChange={onDateSelection}
                                    startDate={c3Filters.start_date}
                                    endDate={c3Filters.end_date}
                                />
                                <Button
                                    variant={"contained"}
                                    startIcon={<i className={"fas fa-exchange-alt"}/>}
                                    size={"small"}
                                    color={"warning"}
                                    className="btn btn-primary btn-sm"
                                    onClick={() => c3Store.update_date_filters("", "")}
                                >Reset Date
                                </Button>
                            </div>
                        </div>}
                    </div>
                    <FormButtons
                        onSave={filter_save_handler}
                        onCancel={filter_cancel_handler}
                    />
                </>
            </SideOverlay>
        </>
    )
})