import {BriefcaseIcon, ChartPieIcon, ClockIcon, MapIcon, ChartBarIcon} from "@heroicons/react/24/solid";

export const tabOptions = [
    {
        name: `Field Summary`,
        icon: BriefcaseIcon,
    },
    {
        name: "Sample Distribution",
        icon: MapIcon,
    },
    {
        name: "Historical",
        icon: ClockIcon,
    },
    {
        name: "Demographics",
        icon: ChartPieIcon,
    },
    {
        name: "SWOT Analysis",
        icon: ChartBarIcon,
    }
];

export const mh_map_config = {
    height: 850,
    show_booth_numbers: false,
};