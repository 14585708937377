import React from "react";
import {Skeleton} from "@mui/lab";
import {Stack} from "@mui/material";

export const PBITableSkeleton = () => {

    return(
        <div className={"w-full sm:max-w-7xl mx-auto"}>
            <Stack spacing={1}>
                <Skeleton variant="rectangular" height={60} />
                <Stack direction={"row"} justifyContent={"spcae-between"}>
                    <Skeleton variant="text"  />
                    <Skeleton variant="rounded" />
                </Stack>
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="rounded" height={50} />
            </Stack>
        </div>
    )
}

