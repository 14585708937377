import {useState} from "react";
import {useStore} from "../../../helpers";
import {safe_get} from "../../../report-visuals";

export const getOption = val => {
  return {
    label: val,
    value: val
  }
}

export const getOptions = (value) => {
  return value.map((ele) => {
    return getOption(ele);
  });
};

export const getConcernedOfficePersonsOptions = (value) => {
  return value.map((ele) => {
    return {
      label: `${ele.name} - ${ele.designation} - ${ele.phone}`,
      value: ele.name,
    };
  });
};

export const getStatusOptions = (value) => {
  return value.map((ele) => {
    return { label: ele.status, value: ele.status };
  });
};

export const useGrievanceOptions = () => {

  const rootStore = useStore();
  const { projectStore } = rootStore;
  const grievanceProperties = projectStore.getProjectProperty("grievances", {});
  const profile_questions = projectStore.getProjectProperty("profile_questions", {});
  const hierarchy = projectStore.getProjectProperty("hierarchy", []);
  const [authorityPerson, setAuthorityPerson] = useState([]);

  const onEngSave = (eng) => {
    setAuthorityPerson([
      ...authorityPerson,
      { label: eng.name, value: eng.name },
    ]);
  };

  const departmentOptions = getOptions(Object.keys(safe_get(grievanceProperties, "categories", {})));

  const statusOptions = getStatusOptions(safe_get(grievanceProperties, "status_config", []));

  const priorityOptions = getOptions(safe_get(grievanceProperties, "priority", []));

  const beneficiaryConfiguration = projectStore.getProjectProperty("beneficiary", {});

  const concerned_office_persons_options = getConcernedOfficePersonsOptions(safe_get(beneficiaryConfiguration, "owners", []));

  const casteOptions = safe_get(profile_questions, "caste", []);

  return {
    grievanceProperties,
    hierarchy,
    authorityPerson,
    departmentOptions,
    statusOptions,
    priorityOptions,
    casteOptions,
    concerned_office_persons_options,
    onEngSave,
  };
};
