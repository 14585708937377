import {Route, Routes} from "react-router-dom";
import {ProjectWorkDetails, ProjectWorkEdit, ProjectWorks} from "../../features";
import React from "react";

export const ProjectWorksRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<ProjectWorks/>}/>
            <Route element={<ProjectWorkDetails/>} path="/:fsdid"/>
            <Route element={<ProjectWorkEdit/>} path="/:fsdid/edit"/>
        </Routes>
    )
}