import React from "react";
import {DashBoard} from "./dashboard-template";

export const KopargaonDashBoard = () => {

    const frame = `<iframe title="AC Kopargaon Anlytics1" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiYTk1ZDgyNDEtNGE2My00ZWQ0LThiZGMtMTc1MDMxYzIyOWM2IiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`

    return (
        <>
            <DashBoard passCode={"Kopargaon"} iframe={frame} />
        </>
    )
}