import {observer} from "mobx-react-lite";
import React from "react"
import {useStore} from "../../helpers";
import {useNavigate, useParams} from "react-router-dom";
import {ViewAttachment} from "../../components";
import {safe_get} from "../../report-visuals/report-utils";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import CalendarMonthIcon from '@material-ui/icons/CalendarToday';
import {RenderRequestStatus} from "../beneficiary-management";
import {DataNotAvailable} from "../../flexistore/renderkeys/mh-2024-field";
import {Fade} from "react-awesome-reveal";

export const displayVillageNames = (villageList) => {
    if (Array.isArray(villageList)) {
        return villageList.map(x => <span>{safe_get(x, "label", "")}, </span>)
    } else return "N/A"
}

export const ProjectWorksCardList = observer((props: any) => {

    const {projectid} = useParams();
    const navigate = useNavigate();
    const {projectWorksStore} = useStore()
    const {projectWorksList} = projectWorksStore;


    const projDetailsClickHandler = async (fsdid) => {
        navigate('/project/' + projectid + '/project_works' + '/' + fsdid);
    }

    return (
        <>{projectWorksList.length > 0 ?
            <div className="py-4 max-w-lg mx-auto grid gap-5 xl:grid-cols-4 lg:grid-cols-3 lg:max-w-none">
                {projectWorksList.length > 0 && projectWorksList.map((project, index) => {
                    const events = safe_get(project, "others.events", [])
                    return (
                        <Fade cascade={false} direction="up" delay={index * 10} triggerOnce>
                            <div key={project.id}
                                 className="flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer max-h-[600px]"
                                 onClick={(evt) => {
                                     projDetailsClickHandler(project.id);
                                 }}
                                 title={project.summary['decription']}
                            >
                                <div className="flex-shrink-0">
                                    <div className="h-48 w-full object-cover">
                                        {project?.summary['coverPhotoPath'] !== '' ?
                                            <ViewAttachment
                                                attachment={{
                                                    path: project.summary['coverPhotoPath'],
                                                    type: "image"
                                                }}
                                                label={false}

                                            /> :
                                            <img
                                                className="h-48 w-full object-cover"
                                                src={"/images/default_image.jpeg"}
                                                alt=""
                                            />
                                        }
                                    </div>

                                </div>
                                <div className="flex-1 bg-white p-3 flex flex-col justify-between">
                                    <div className="flex-1 flex flex-col gap-y-2">
                                        <p className="text-xl font-semibold text-gray-900">{project.summary['name']}</p>
                                        <div className={"flex flex-row gap-x-2"}>
                                            <LocationOnIcon color={"primary"}/>
                                            <p>{project.summary['location']}</p>
                                        </div>

                                        <div className={"flex flex-row gap-x-2"}>
                                            <HowToVoteIcon color={"primary"}/>
                                            <p>{displayVillageNames(project.summary['village'])}</p>
                                        </div>
                                        <div className={"flex flex-row gap-x-2 items-center"}>
                                            <CalendarMonthIcon color={"primary"}/>
                                            <p>{project['created']}</p>
                                            <RenderRequestStatus feature={"project_works"}
                                                                 status={safe_get(project, "summary.status", "")}/>
                                        </div>
                                        {events.length > 0 ? <div className={"flex flex-row gap-x-2"}>
                                            <p className="font-bold">Event Date :</p>
                                            {events.map(event => <p
                                                className="font-bold">{event.date}</p>)}
                                        </div> : null}

                                        <p className="py-4 overflow-hidden text-base text-gray-500 ">{project.summary['decription']}</p>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    )
                })}
            </div> : <DataNotAvailable/>
        }
        </>
    )
})