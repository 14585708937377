
export type familyMemberProfileType = {
  name_eng: string;
  phone: string;
  gender: string;
  voterid: string;
  aadharid: string;
  dob: string;
  age: number;
  relation_name_eng: string,
  relationship_eng: string,
  additional: string
  profile_photo: any;
};

export const familyMemberProfileDefaultValues: familyMemberProfileType = {
  name_eng: "",
  phone: "",
  voterid: "",
  aadharid: "",
  dob: "",
  age: 0,
  gender: "",
  profile_photo: null,
  relation_name_eng: "",
  relationship_eng: "",
  additional: ""
};

export const getValues = data => {
  return {
    id: data.voterid,
    ac: "",
    part: "",
    phone: data.phone,
    famcode: "",
    profile: {
      ...data.profile,
      age: data.age,
      gender: data.gender,
      voterid: data.voterid,
      aadharid: data.aadharid,
      dob: data.dob,
      name_eng: data.name_eng,
      relationship_eng: data.relationship_eng,
      relation_name_eng: data.relation_name_eng,
      additional: data.additional,
      profile_photo: {},
    },
  }
}


export const genders = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const relationShips = [
  {
    label: "Father",
    value: "Father",
  },
  {
    label: "Husband",
    value: "Husband",
  },
  {
    label: "Mother",
    value: "Mother",
  },
  {
    label: "Son",
    value: "Son",
  },
  {
    label: "Daughter",
    value: "Daughter",
  },
  {
    label: "Wife",
    value: "Wife",
  },
  {
    label: "Sister",
    value: "Sister",
  },
  {
    label: "Brother",
    value: "Brother",
  },
  {
    label: "Other",
    value: "Other",
  },
];
