import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers";
import { safe_get } from "../../report-visuals";
import { image_path } from "./beneficiaries-table";
import { useTranslation } from "react-i18next";
import {DeleteButton, EditButton} from "../../components/cm-components";

export const ProfileInfo = ({ title = "", value = "", icon = "" }) => {
    return (
        <div className="flex flex-row py-1">
            {icon && <i className={`text-gray-500 px-2 ${icon}`} />}
            {title && <div className="font-bold text-gray-700">{title}</div>}
            {value && <div className="text-gray-500 px-2">{value}</div>}
        </div>
    )
}

export const RenderBeneficiaryProfile = observer((props: any) => {

    const { beneficiaryStore } = useStore();
    const { activeBeneficiary } = beneficiaryStore;
    const { t } = useTranslation("beneficiary");
    const is_outsider = safe_get(activeBeneficiary, "summary.profile.is_outsider", false)

    const beneficiary_image = safe_get(activeBeneficiary, "imageUrl", `${image_path}`);

    return (
        <div className="rounded-lg bg-white p-2">
            <div className='flex flex-row justify-between items-center '>
                <div className="py-2 flex flex-row justify-between items-center">
                    <img className="w-16 h-16 flex-shrink-0 rounded-full" src={beneficiary_image} alt="" />
                    <div className='flex flex-col mx-2'>
                        <ProfileInfo title={activeBeneficiary["summary"].profile.name} icon="fa-solid fa-user font-bold" />
                        <ProfileInfo title={activeBeneficiary["summary"].profile.phone} icon="fa-solid fa-phone font-semibold"/>
                    </div>
                </div>
                <div className='flex flex-row items-center gap-x-2'>
                    <DeleteButton OnDeleteClickHandler={() => {
                        beneficiaryStore.update_beneficiary_delete(true)
                    }}/>
                    <EditButton
                        OnEditClickHandler={() => {
                            beneficiaryStore.update_edit_beneficiary_mode(true);
                            beneficiaryStore.update_add_beneficiary_modal(true);
                        }}
                    />
                </div>
            </div>
            <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                    <ProfileInfo title="Voter ID:" value={activeBeneficiary["summary"]["profile"].voter_id} icon="fa-solid fa-ballot" />
                    <ProfileInfo title="Aadhar ID:" value={activeBeneficiary["summary"].profile.aadhar_id} icon="fa-solid fa-ballot" />
                </div>
                {/*<ProfileInfo title="No.of Family Members:" value={activeBeneficiary["summary"].profile.family_members} icon="fa-solid fa-family"/>*/}
                <ProfileInfo title="Address:" value={activeBeneficiary["summary"].profile.address} icon="fa-solid fa-location-pin" />
                
                {activeBeneficiary["summary"].profile.whatsapp ? (
                <ProfileInfo title="Whatsapp Number:" value={activeBeneficiary["summary"].profile.whatsapp} icon="fa-solid fa-phone font-semibold"/>): ""}

            </div>
            {
                is_outsider &&
                <div>
                    <p className="font-bold text-purple-600">{t("outside_voter_details")}</p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        <ProfileInfo title={activeBeneficiary["summary"].profile["oacv_name"]} icon="fa-solid fa-user font-bold" />
                        <ProfileInfo value={activeBeneficiary["summary"].profile["oacv_phone"]} icon="fa-solid fa-phone font-semibold" />
                        <ProfileInfo title={activeBeneficiary["summary"].profile["ac_name"]} icon="fa-solid fa-map-marker font-bold" />

                    </div>
                </div>
            }
        </div>
    )
})