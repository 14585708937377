import {observer} from "mobx-react-lite";
import {Outlet, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {useStore} from "../../helpers";
import C3Items from "./c3-items";
import {useTranslation} from "react-i18next";
import 'react-dropdown-tree-select/dist/styles.css';
import {useIsMobile} from "../../custom-hooks";
import {BreadCrumbs, FilterButton, ProjectHeader} from "../../components";
import {C3Filters} from "./c3-filters";
import {safe_get} from "../../report-visuals";


export const C3Home = observer((props: any) => {

        const {c3Store} = useStore()
        const isMobile = useIsMobile();
        const [t] = useTranslation("constituency_command_center");
        const {projectid} = useParams();

        const crumbs = [
            {name: "DashBoard", href: `/project/${projectid}`, current: true},
            {name: 'Constituency Command Center', href: '/project/' + projectid + '/c3', current: true},
        ];

        useEffect(() => {
            return () => {
                c3Store.update_selected_booths([]);
            }
        }, []);

        return (
            <div className="relative">
                {!isMobile ?
                    <div className={"flex flex-col"}>
                        <BreadCrumbs crumbs={crumbs}/>
                        <div className={"flex flex-row items-center justify-between"}>
                            <ProjectHeader/>
                            <FilterButton
                                sx={{margin: 2}}
                                filterOnClickHandler={e => c3Store.update_c3FilterModal(true)}
                            />
                        </div>
                    </div> : null}

                <div className='px-4 flex flex-row'>
                    {c3Store.selected_booths.length > 0 && (
                        <div className={"flex flex-col"}>
                            <p className="font-bold fp-text text-md mr-r p-2">Selected Booths:</p>
                            <div className="flex flex-row flex-wrap">
                                {c3Store.selected_booths.map((booth) => (
                                    <p className="p-2 font-bold">Booth# {safe_get(booth, "id", booth)},</p>
                                ))}
                            </div>
                        </div>
                    )}
                </div>


                <div className="bg-gray-100">
                    <div className="flex flex-col sm:flex-row mx-2 sm:mx-auto ">
                        <div className="w-full sm:w-1/5">
                            <C3Items/>
                        </div>
                        <div className="w-full sm:w-4/5 bg-white p-4 overflow-x-hidden">
                            <Outlet/>
                        </div>
                    </div>
                </div>

                <C3Filters/>
            </div>

        )
    }
)
