import React, {useEffect, useState} from "react";
import {BreadCrumbs, FilterButton, LoadingState, ProjectHeader, SideOverlay} from "../../components";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {FolderIcon} from "@heroicons/react/24/solid";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {safe_get} from "../../report-visuals/report-utils";
import {AddFileOrFolder} from "./add-file-folder";
import {FileIcon, defaultStyles} from "react-file-icon";
import {htmlValidation, useStore} from "../../helpers";
import {toast} from "react-toastify";
import _ from "lodash"
import {FolderBreadCrumbs} from "./folder-bread-crumbs";
import {DAFilters} from "./da-filters";
import {useGetFullProject} from "../../custom-hooks";
import {Button, IconButton} from "@mui/material";
import {RNSearchBar} from "../influential-leaders";

export const DigitalRepository = observer((props: any) => {

    const {projectid} = useParams();
    const rootStore = useStore();
    const queryClient = useQueryClient();
    const {digitalArchiveStore} = rootStore;
    const {folderBreadCrumbs, activeParentId, daFilters, searchQuery} = digitalArchiveStore;
    const {date} = daFilters;
    const [s3Path, setS3Path] = useState({path: "", type: ""})

    const {isLoading:fullProjectLoading} = useGetFullProject();

    useEffect(() => {
        if(folderBreadCrumbs.length > 0){
            digitalArchiveStore.update_DAStoreProp("activeParentId", folderBreadCrumbs[folderBreadCrumbs.length-1]["id"])
        }
    }, [folderBreadCrumbs])

    useEffect(() => {
        return () => {
            digitalArchiveStore.resetFolderBreadCrumbs();
        }
    }, [projectid])

    const crumbs = [
        {name: "DashBoard", href: `/project/${projectid}`, current: true}
    ];

    const getFiles = async () => {
        const payload = {
            parent_id: activeParentId,
            query: searchQuery,
            project_id: parseInt(projectid),
            filters: {
                date: daFilters.date
            }
        }
        return await digitalArchiveStore.get_files_and_folders(payload)
    }

    const {data, isLoading, isError, refetch} = useQuery(['files', activeParentId, projectid, searchQuery], getFiles)

    const {mutate} = useMutation({
        mutationFn: async (payload: any) => {
            const id = payload["id"]
            await digitalArchiveStore.delete_file_or_folder({id})
        },
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["files", activeParentId, projectid])
            }, 1000);
            toast.success("Deleted Successfully")
        }
    })

    const deleteFile = async (e, file) => {
        htmlValidation(e);
        mutate(file)
    }

    const editFile = async(e, file) => {
        htmlValidation(e);
        digitalArchiveStore.update_DAStoreProp("addNewModal", true);
        digitalArchiveStore.update_DAStoreProp("activeFileorFolder", file);
    }

    const folderClickHandler = async (e, file) => {
        htmlValidation(e)
        const isFolder = safe_get(file, "is_folder", false)

        if (!isFolder) {
            window.open(safe_get(file,"s3_path", ""), "_blank")
            setS3Path({
                path: file.s3key,
                type: file.mimetype
            })
            return
        }

        await digitalArchiveStore.update_DAStoreProp("activeParentId", file.id);

        const cc = _.clone(digitalArchiveStore.folderBreadCrumbs);
        const index = cc.findIndex(x => x.id === file.id);
        if (index === -1) {
            cc.push(file)
        } else {
            cc.splice(index + 1)
        }

        await digitalArchiveStore.update_folder_breadcrumbs(cc);
    }

    if (isLoading || fullProjectLoading) return <LoadingState/>

    const sortedFilesAndFolders = data?.data.sort(function (x, y) {
        return y.is_folder - x.is_folder
    })

    //TODO:

    return (
        <>
            <BreadCrumbs crumbs={crumbs}/>
            <ProjectHeader/>
            <div className={"flexRow"}>
                <div className='flex flex-row justify-between items-center mb-4 p-4'>
                    <h2 className='font-bold text-3xl text-indigo-500'>Digital Archives</h2>
                    <div className={"flex justify-end gap-x-2"}>
                        <RNSearchBar onSearch={(val) => digitalArchiveStore.update_DAStoreProp("searchQuery", val)}/>
                        {/*<SearchBar*/}
                        {/*    query={query}*/}
                        {/*    handleChange={handleSearchBarChange}*/}
                        {/*    handleKeyDown={handleKeyDown}*/}
                        {/*    getFilteredData={() => {*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {searchQuery === "" &&
                        <Button
                            startIcon={<i className={"fas fa-circle-plus"} />}
                            onClick={() => {
                                digitalArchiveStore.update_DAStoreProp("addNewModal", true)
                                digitalArchiveStore.update_DAStoreProp("activeFileorFolder", {})
                            }}
                            variant={"contained"}
                        >
                            Add New
                        </Button>
                        }
                        <FilterButton
                            filterOnClickHandler={() => digitalArchiveStore.update_DAStoreProp("da_filter_modal", true)}
                        />
                    </div>
                </div>

                <FolderBreadCrumbs
                    onFolderClick={(e, page) => {
                        htmlValidation(e);
                        if (activeParentId === page.parent) return;
                        folderClickHandler(e, page);
                    }}
                />

                <div className={"px-4 my-4 grid grid-cols-1 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-4"}>
                    {
                        sortedFilesAndFolders.map(file => {
                            const isFolder = safe_get(file, "is_folder", false);
                            const key = safe_get(file, "s3key" , "").split("/");
                            const extension = key[key.length-1].split(".")[1]
                            const file_type = !isFolder ? extension : ""
                            return (
                                <div key={file.id} className={"flex flex-row"}
                                    onClick={e => {folderClickHandler(e, file)} }
                                >
                                    <div className={"relative rounded-lg p-4 bg-white shadow-xl w-full flex items-center justify-center flex-col cursor-pointer"}>
                                        <div className={"absolute top-[10px] right-3 flex flex-row items-center gap-x-2"}>
                                            <IconButton
                                                onClick={e => editFile(e, file)}
                                                color={"primary"}
                                                size={"small"}
                                            >
                                                <i className={"fas fa-edit"} />
                                            </IconButton>
                                            <IconButton
                                                onClick={e => deleteFile(e, file)}
                                                color={"error"}
                                                size={"small"}
                                            >
                                                <i className="fas fa-trash"/>
                                            </IconButton>
                                        </div>
                                        {isFolder ? <FolderIcon color={"#F8C471"}/> :<FileIcon radius={1} fold={true} type={"3d"} extension={file_type} {...defaultStyles[file_type]} />}
                                        <p>{file["title"]}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <SideOverlay
                onClose={() => {
                    digitalArchiveStore.update_DAStoreProp("addNewModal", false)
                }}
                show={digitalArchiveStore.addNewModal}
                title={"Add New File/Folder"}
                children={<AddFileOrFolder/>}
            />

            <SideOverlay
                onClose={() => {
                    digitalArchiveStore.update_DAStoreProp("da_filter_modal", false)
                }}
                show={digitalArchiveStore.da_filter_modal}
                title={"Digital Archive Filters"}
                children={<DAFilters onDateSave={async () => await refetch()}/>}
            />
        </>
    )
})