import React, {useEffect} from "react";
import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {useStore} from "../../helpers";
import _ from "lodash";
import {useWindowSize} from 'react-use';
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import {safe_get} from "../../report-visuals";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {LeafletMap, LoadingState, Toggle} from "../../components";

export const MapModal = observer((props: any) => {

    const {show, geojson, data, onClose} = props;
    const [t]=useTranslation("task-manager");
    const rootStore = useStore();
    const {taskManagerStore} = rootStore;
    const {marked_locations, enable_marking_locations} = taskManagerStore;
    const {width} = useWindowSize();
    const isMobile = width <= 766;
    const [loading, setLoading] = useState(false);

    const [delay, setDelay] = useState(false)

    useEffect(() => {
        const intr = setTimeout(() => {
            setDelay(true)
        }, 100);

        return (
            () => clearInterval(intr)
        )
    }, [])

    console.log(props)

    const delete_marked_location = id => {
        const _clone = _.clone(toJS(marked_locations))
        const index = _.findIndex(_clone, e => e["id"] === id);
        _clone.splice(index, 1);
        taskManagerStore.set_marked_locations(_clone);
    }

    const [active_loc, setActive_loc] = useState({});

    const [enableMapOnClickEvent, setEnableMapOnClickEvent] = useState(enable_marking_locations);

    useEffect(() => {
        taskManagerStore.set_enable_marking_locations(enableMapOnClickEvent)
    }, [enableMapOnClickEvent]);

    return (
        <>
            <Transition.Root
                show={show}
                as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => {
                }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="self-center w-full bg-white rounded-lg p-1 text-left overflow-hidden shadow-xl sm:mx-16 transform transition-all sm:my-4 sm:p-6">
                                    <i className="fa-solid fa-circle-xmark text-purple-500 cursor-pointer" style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        fontSize: "25px",
                                        zIndex: 99999
                                    }}
                                       onClick={e => onClose()}
                                    />
                                    <div className="flex flex-row flex-wrap sm:flex-nowrap">
                                        {loading && <LoadingState />}
                                        <div style={{width: isMobile ? "100%" : width * 0.75}}>
                                            {delay && <LeafletMap geojson={geojson} map_height={isMobile ? 350 : 850}
                                                         task={data}/>}
                                        </div>
                                        <div
                                            className="flex h-full flex-col py-6 shadow-xl bg-gray-200 rounded-lg bg-gray-300 sm:ml-2 p-2 sm:p-4"
                                            style={{width: isMobile ? "100%" : width * 0.25}}>
                                            <div className="flex items-center justify-between mb-2">
                                                <p className="font-bold text-xl text-blue-600">{t("enable_mark_locations")} </p>
                                                <Toggle val={enableMapOnClickEvent} onToggle={() => {
                                                    setEnableMapOnClickEvent(!enableMapOnClickEvent)
                                                }}/>
                                            </div>
                                            <div className="font-bold text-xl text-blue-600 mt-2">{t("marked_locations")}</div>
                                            {marked_locations && marked_locations.map(location => {

                                                return (
                                                    <>
                                                        <div className="grid grid-cols-3 gap-2 my-1">
                                                            <p>{location.id}</p>
                                                            <input
                                                                onFocus={e => setActive_loc(location)}
                                                                className="px-2 rounded-md border bg-tranaperent"
                                                                type="number"
                                                                defaultValue={safe_get(location, 'radius', 75)}
                                                                onChange={e => {
                                                                    try {
                                                                        const new_radius = parseInt(e.target.value)
                                                                        if (new_radius != (safe_get(location, 'radius', 75))) {
                                                                            const loc = _.clone(location)
                                                                            loc['radius'] = new_radius
                                                                            taskManagerStore.update_marked_location(loc)
                                                                        }
                                                                    } catch (e) {
                                                                    }
                                                                }}
                                                            />

                                                            <div className="flex items-center justify-around">
                                                                <i className="fa-solid fa-trash text-red-600 text-xl cursor-pointer"
                                                                   onClick={e => {
                                                                       delete_marked_location(location.id)
                                                                   }}/>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                            <div
                                                className="pt-2 sm:pt-4 flex flex-row-reverse justify-start divide-y divide-gray-200">
                                                <button
                                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={async (e) => {
                                                        setLoading(true);
                                                        const res = await taskManagerStore.save_locations();
                                                        if (res.status === 200) {
                                                            toast.success("Locations Marked successfully.");
                                                            await taskManagerStore.update_show_booth_map(false)
                                                        } else {
                                                            toast.error("Something went wrong, please try again.")
                                                        }
                                                        setLoading(false)
                                                    }}>Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
})