import React, {Fragment, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../helpers';
import {safe_get} from '../../report-visuals/report-utils';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {RNInput, RNSelect} from '../../components/form-components';
import {FormButtons} from '../../components';
import {Typography} from '@mui/material';
import {Dialog, Transition} from '@headlessui/react';
import {defaultUser, User, userEditValidationSchema, userRoles} from "./helpers";

export const UpdateUser = observer((props: any) => {

    const rootStore = useStore();
    const {projectStore, userStore} = rootStore;

    const {activeUser, edit_user_modal} = userStore;
    const defaultTags = projectStore.getProjectProperty('default_tags', {});
    const userTags = safe_get(defaultTags, 'user_tags', []).map((tag) => ({label: tag, value: tag}));

    const methods = useForm({
        defaultValues: defaultUser,
        // @ts-ignore
        resolver: yupResolver(userEditValidationSchema),
        mode: 'onSubmit'
    });

    useEffect(() => {
        const basePhoneNumber = safe_get(activeUser, 'phonenum', '');
        const prepareData: User = {
            name: safe_get(activeUser, 'name', ''),
            phonenum: basePhoneNumber.startsWith('+91') ? basePhoneNumber.slice(3) : basePhoneNumber,
            role: safe_get(activeUser, 'role', ''),
            tags: safe_get(activeUser, 'tags', [])
        };
        methods.reset(prepareData);
    }, [activeUser]);

    const onSubmit = async (data) => {
        console.log(data);
        closeModal();
    };

    const closeModal = () => {
        userStore.update_user_store_prop('edit_user_modal', false);
        methods.reset();
    };

    return (
        <Transition.Root show={edit_user_modal} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-10 flex items-center justify-center" onClose={() => {
            }}>
                <div id={'tail'} className="fixed inset-0 bg-gray-800 bg-opacity-75"/>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        className={
                            'p-4 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'
                        }
                    >
                        <FormProvider {...methods}>
                            <form
                                onSubmit={methods.handleSubmit(onSubmit)}
                                className="bg-white py-2 rounded-sm flex flex-col gap-y-4"
                            >
                                <Typography variant={'h5'} color={'primary'}>
                                    Update User Details
                                </Typography>
                                <RNInput name={'name'} label={'Name'}/>
                                <RNInput name={'phonenum'} label={'Phone Number'}/>
                                <RNSelect
                                    name={'role'}
                                    label={'Role'}
                                    options={userRoles}
                                    menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                    menuPlacement={'auto'}
                                    menuShouldScrollIntoView={true}
                                />
                                <RNSelect
                                    name={'tags'}
                                    label={'User Tags'}
                                    options={userTags}
                                    isMulti={true}
                                    menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                    menuPlacement={'auto'}
                                    menuShouldScrollIntoView={true}
                                />
                                <FormButtons onSave={() => {
                                }} onCancel={closeModal}/>
                            </form>
                        </FormProvider>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    );
});
