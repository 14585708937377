import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Button} from "@mui/material";
import {RNSearchBar} from "../features";

type HeaderWithAddButtonProps = {
    header: string,
    addNewHandler: Function,
    button_title: string,
    handleChange: (args) => void,
    value: number,
    onChange: (args) => void,
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiFormLabel-root": {
            color: "primary"
        }
    }
}))

export const HeaderWithAddButton = (props: HeaderWithAddButtonProps) => {

    const perPageOptions = [10, 20, 30, 40, 50, 60, 100, 200];
    const { handleChange } = props;
    const classes = useStyles();
    return (
        <>
            <div className='flex flex-row justify-between items-center mb-4 flex-1'>
                <h2 className='font-bold text-3xl text-indigo-500 pr-14'>{props.header}</h2>
                <div className={"flex items-start justify-between gap-x-4"}>
                    <RNSearchBar onSearch={handleChange}/>
                    {/*<TextField*/}
                    {/*    id="items-per-page"*/}
                    {/*    select*/}
                    {/*    color={"primary"}*/}
                    {/*    label="Items Per Page"*/}
                    {/*    defaultValue="10"*/}
                    {/*    value={value}*/}
                    {/*    className={classes.root}*/}
                    {/*    onChange={onChange}*/}
                    {/*    sx={{width: "120px", alignSelf: "flex-start"}}*/}
                    {/*    size={"small"}*/}
                    {/*>*/}
                    {/*    {perPageOptions.map((option) => (*/}
                    {/*        <MenuItem key={option} value={option}>*/}
                    {/*            {option}*/}
                    {/*        </MenuItem>*/}
                    {/*    ))}*/}
                    {/*</TextField>*/}
                    <Button
                        variant={"contained"}
                        startIcon={<i className="fas fa-plus-circle"/>}
                        onClick={() => {
                            props.addNewHandler();
                        }}
                    >
                        {props.button_title}
                    </Button>
                    {/*<div className="flex flex-row items-center pr-10 pl-10">*/}
                    {/*    <label htmlFor="dropdown" className="text-sm mb-1 font-bold text-black px-3 ">Items per page:</label>*/}
                    {/*    <select*/}
                    {/*        value={value}*/}
                    {/*        onChange={onChange}*/}
                    {/*        className="p-2 rounded border border-gray-300 focus:outline-none focus:border-blue-500 bg-white z-999"*/}
                    {/*        style={{ boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', zIndex: 0, appearance: "auto" }}*/}
                    {/*    >*/}
                    {/*        {perPageOptions.map((option, i) => (*/}
                    {/*            <option key={i} value={option}>{option}</option>*/}
                    {/*        ))}*/}
                    {/*    </select>*/}
                    {/*</div>*/}


                </div>
            </div>
        </>
    )
}