import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import { useWindowSize } from "react-use";
import 'react-dropdown-tree-select/dist/styles.css'
import {classNames} from "../../../helpers/utils";
import {ChatBubbleBottomCenterTextIcon, ChartBarSquareIcon} from "@heroicons/react/24/solid";
import {useTranslation} from "react-i18next";
import {FSDMeta} from "./fsd-meta";
import {FSDSlots} from "./fsd-slots";
import {FSDPromt} from "./fsd-Promt";
import {FSDModel} from "./fsd-model";
import {FSDMore} from "./fsd-more";
import {BreadCrumbs, ProjectHeader} from "../../../components";


export const FSDCreation = observer((props: any) => {
        const [t] = useTranslation("field_payment")
        const tabs = [
            {name: t("Meta"), icon: ChatBubbleBottomCenterTextIcon},
            {name: t("Slots"), icon: ChatBubbleBottomCenterTextIcon},
            {name: t("Prompt"), icon: ChartBarSquareIcon},
            {name: t("Model"), icon: ChartBarSquareIcon},
            {name: t("More"), icon: ChartBarSquareIcon},
            {name: t("Permissions"), icon: ChartBarSquareIcon}
        ];
        const [activeTabName, setActiveTabName] = useState("Meta");
        const [activeTab, setActiveTab] = useState(tabs[0]);
        const {width} = useWindowSize();
        const isMobile = width <= 766;

        const crumbs = [
            {name: "Aigentools", href: `/aigentools`, current: true},
        ];


        const TabView = () => {
            return (
                <div className="pl-5">
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            {t("select_a_tab")}
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            className="p-4 block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-lg"
                            defaultValue={activeTab.name}
                            value={activeTabName}
                            onChange={(eve) => setActiveTabName(eve.target.value)}
                        >
                            {tabs.map((tab) => (
                                <option key={tab.name}>{tab.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div className="border-b-2 border-gray-400">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                {tabs.map((tab) => {
                                    const isThisActiveTab = activeTabName === tab.name;
                                    return (
                                        <a
                                            key={tab.name}
                                            className={classNames(
                                                isThisActiveTab
                                                    ? "border-indigo-500 text-indigo-600"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                "group inline-flex items-center py-2 px-1 border-b-2 cursor-pointer"
                                            )}
                                            aria-current={isThisActiveTab ? "page" : undefined}
                                            onClick={(e) => setActiveTabName(tab.name)}
                                        >
                                            <tab.icon
                                                className={classNames(
                                                    isThisActiveTab
                                                        ? "text-indigo-500"
                                                        : "text-gray-400 group-hover:text-gray-500",
                                                    "-ml-0.5 mr-2 h-8 w-8"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span className="text-lg font-medium">{tab.name}</span>
                                        </a>
                                    );
                                })}
                            </nav>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <>
                <div className=''>
                    {!isMobile ?
                        <>
                            <BreadCrumbs crumbs={crumbs}/>
                            <ProjectHeader/>
                        </> : null}
                    <TabView/>
                    <div className='py-2'/>

                    {activeTabName === "Meta" ? <FSDMeta/> : null}
                    {activeTabName === "Slots" ? <FSDSlots/> : null}
                    {activeTabName === "Prompt" ? <FSDPromt/> : null}
                    {activeTabName === "Model" ? <FSDModel/> : null}
                    {activeTabName === "More" ? <FSDMore/> : null}
                    {activeTabName === "FSDPermissions" ? <FSDMeta/> : null}
                </div>
            </>
        )
    }
)