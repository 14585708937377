import {Route, Routes} from "react-router-dom";
import React from "react";
import {
    ActiveProjectsFTRSummary,
    AdHocHome,
    RJAnalyticalSummary,
    RJTaskAssign,
    SocialMediaTracker
} from "../pages/ad-hoc-features";

export const ToolsRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<AdHocHome/>}/>
            <Route path="/bulktaskassign" element={<RJTaskAssign/>}/>
            <Route path="/fieldsummary" element={<ActiveProjectsFTRSummary/>}/>
            <Route path="/analyticalreport" element={<RJAnalyticalSummary/>}/>
            <Route path="/socialmediatracker" element={<SocialMediaTracker/>}/>
        </Routes>
    )
}