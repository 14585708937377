import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { useParams } from "react-router";
import { useFileUpload } from "../../../helpers/file-upload";
import { Typography } from "@mui/material";

import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginFileValidateSize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageTransform
);

const FilePondUpload = ({
  onUpdate,
  accept = "*/*",
  label = "Upload File",
  files = [],
  path,
}) => {
  const [fileState, setFileState] = useState(files);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const uploadedFileNames = new Set(uploadedFiles.map((file) => file.name));
  const { isUploading, uploadFileToS3 } = useFileUpload();

  const handleFileUpdate = async (fileItems) => {
    const newFiles = fileItems
      .map((item) => item.file)
      .filter((file) => !uploadedFileNames.has(file.name));

    for (const file of newFiles) {
      const result = await uploadFileToS3(path, file, "urn-assets");
      if (result?.status === 204) {
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            path: result.key,
            type: file.type,
            name: file.name,
          },
        ]);
        uploadedFileNames.add(file.name);
      }
    }
    setFileState(fileItems);
  };

  useEffect(() => {
    onUpdate(uploadedFiles);
  }, [uploadedFiles]);

  return (
    <div>
      <Typography variant={"subtitle1"} color={"primary"}>
        {label}
      </Typography>
      <div className="border-4 border-dotted border-gray-500 p-2 rounded-md">
        <FilePond
          files={fileState}
          allowMultiple={true}
          name="documents"
          acceptedFileTypes={[accept]}
          onupdatefiles={handleFileUpdate}
          labelIdle={`📂Drag & Drop your files or <span class="filepond--label-action">Browse</span>`}
          maxFileSize="50MB"
          minFileSize="1KB"
          imagePreviewHeight={200}
          allowImageExifOrientation={true}
        />
      </div>
    </div>
  );
};

export default FilePondUpload;
