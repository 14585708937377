import React, {Fragment} from "react";
import {observer} from "mobx-react-lite";
import {FormButtons, LoadingState} from "../../components";
import {refineText, useStore} from "../../helpers";
import {safe_get} from "../../report-visuals/report-utils";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useForm, FormProvider} from "react-hook-form";
import {toast} from "react-toastify";
import {userRoles} from "./helpers";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {RNInput, RNSelect} from "../../components/form-components";
import {Dialog, Transition} from '@headlessui/react';
import {useMutation, useQueryClient} from "@tanstack/react-query";

type userType = {
    role: string;
    tags: Array<string>;
    phonenumlist: string;
};

const defaultUserPayload:userType = {
    role: "",
    tags: [],
    phonenumlist: ""
}

export const AddNewUsers = observer((props: any) => {
    const rootStore = useStore();
    const {userStore, projectStore} = rootStore;
    const {t} = useTranslation(["user_management", "survey_management"]);
    const {projectid} = useParams();
    const defaultTags = projectStore.getProjectProperty("default_tags", {});
    const userTags = safe_get(defaultTags, "user_tags", [])?.map((x) => ({value: x, label: x}))
    const role = safe_get(userStore.user, "role", "");
    const queryClient = useQueryClient();

    const methods = useForm<userType>({
        defaultValues: defaultUserPayload,
        //@ts-ignore
        resolver: yupResolver(Yup.object().shape({
            role: Yup.string().required("Role is Required"),
            tags: Yup.mixed(),
            phonenumlist: Yup.string()
                .required('Phone Number is Required')
                .test('phone-number', 'Please Enter a valid phone number', (value) => {
                    return refineText(value).every((number) => /^[6-9]\d{9}$/.test(number));
                })
        }))
    });

    const {mutate, isLoading} = useMutation({
        mutationFn: async (payload) => {
            return await projectStore.addNewUser(payload);
        },
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["users", projectid])
            }, 1000);
            toast.success("Users added Successfully");
        },
        onError: async () => {
            toast.error("Something went wrong");
        }
    })

    const onSubmit = async (data: userType) => {
        const phonenumlist = refineText(data?.phonenumlist)
        const role = data.role;
        const tags = data.tags;
        const payload = {
            phonenumlist,
            projectid: parseInt(projectid),
            role,
            tags,
        };
        // @ts-ignore
        mutate(payload)
        closeModal();
    };

    const closeModal = () => {
        userStore.update_user_store_prop("add_new_users_modal", false);
        methods.reset();
    };

    const user_roles = userRoles.filter((r) => {
        if (role === "client" && r.value === "root") return;
        return r;
    })

    if(isLoading) <LoadingState />

    return (
        <>
            <Transition.Root show={userStore.add_new_users_modal} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-10 flex items-center justify-center" onClose={() => {
                }}>
                    <div id={'tail'} className="fixed inset-0 bg-gray-800 bg-opacity-75"/>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg w-full p-4">
                            <div className={"text-xl font-bold text-purple-500"}>Add New Users</div>
                            <FormProvider {...methods}>
                                <form
                                    className={"flex flex-col gap-y-3"}
                                    onSubmit={methods.handleSubmit(onSubmit)}
                                >
                                    <RNSelect
                                        name={"role"}
                                        label={"Role"}
                                        options={user_roles}
                                        menuPosition="fixed"
                                        menuPortalTarget={document.body}
                                        menuPlacement={'auto'}
                                        menuShouldScrollIntoView={true}
                                        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                    />

                                    <RNSelect
                                        name={"tags"}
                                        label={"Tags"}
                                        options={userTags}
                                        isMulti={true}
                                        menuPosition="fixed"
                                        menuPortalTarget={document.body}
                                        menuPlacement={'auto'}
                                        menuShouldScrollIntoView={true}
                                        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                    />

                                    <RNInput
                                        name={"phonenumlist"}
                                        label={"Phone number"}
                                        multiline
                                        rows={5}
                                    />

                                    <FormButtons
                                        onSave={() => {
                                        }}
                                        onCancel={closeModal}
                                        type={"submit"}
                                    />
                                </form>
                            </FormProvider>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition.Root>

        </>
    );
});
function useMutate(arg0: {}) {
    throw new Error("Function not implemented.");
}

