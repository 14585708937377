import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import {htmlValidation} from "../helpers";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {ListItemIcon, Typography} from "@mui/material";

const ITEM_HEIGHT = 48;

export const FloatingMenu = (props: any) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        htmlValidation(event)
    };
    const handleClose = (e) => {
        setAnchorEl(null);
        htmlValidation(e)
    };
    return (
        <div className={"overflow-x-hidden"}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                color={"error"}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                disableScrollLock
                onClose={handleClose}
                slotProps={{
                    //@ts-ignore
                    paper: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                        },
                    },
                }}
            >
                {props.items.map((option, index) => (
                    <MenuItem key={index} onClick={(e) => {
                        option.onClick(e, props.data);
                        handleClose(e);
                    }}>
                        <ListItemIcon>
                          <i className={`${option.icon}`}/>
                        </ListItemIcon>
                        <Typography variant="inherit">{option.title}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
