import {useMutation} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {useStore} from "../../../helpers";
import {toast} from "react-toastify";
import {useBeneficiaryMutationSuccess} from "./useBeneficiaryMutationSuccess";


export const useEditBeneficiary = () => {

    const rootStore = useStore();
    const { projectid } = useParams();
    const {flexiStore,beneficiaryStoreV2} = rootStore;
    const {onMutationSuccess} = useBeneficiaryMutationSuccess();

    const onEditBeneficiary = async (summary) => {
        flexiStore.update_current_fsd("summary", {
            profile: summary,
            tracing_id: `P${projectid}B`,
        });
        flexiStore.update_current_fsd("r1", {
            logs: [],
            family: beneficiaryStoreV2.familyMembers,
            requests: beneficiaryStoreV2.requests,
        });
        const res = await flexiStore.api_update_current_fsd();
        if (!res.ok) {
            throw new Error("Something went wrong...");
        } else {
            return res;
        }
    };

    const {mutate: editBeneficiary, isLoading} = useMutation({
        mutationFn: onEditBeneficiary,
        onSuccess: async () => {
            await onMutationSuccess();
            toast.success("Beneficiary updated successfully")
            beneficiaryStoreV2.update_add_beneficiary_modal(false);
        },
        onError: ({message}) => {
            toast.error(message);
        },
    });

    return {
        editBeneficiary, isLoading
    }
}