import React from "react";
import {Route, Routes} from "react-router-dom";
import {
    DavanagereDashBoard,
    HCPDashBoard,
    HoskoteDashBoard,
    IndiaContactsDashBoard,
    KaHistoryDashBoard,
    KaraikudiDashBoard,
    KopargaonDashBoard,
    MHFieldSummary,
    MHMap,
    MHOPDashBoard, MHOPV2DashBoard,
    PadmanabhaNagarDashBoard,
    RNSales,
    ShimogaDashboard,
    SomaliLandDashBoard
} from "../pages/ad-hoc-features";
import {HombaleDashBoard} from "../pages/ad-hoc-features/dash-boards/Hombale";
import {DVGMap} from "../pages/ad-hoc-features/davanagere-map";


export const DashBoardRoutes = () => {
    return (
        <Routes>
            <Route path={"/davanagere"} element={<DavanagereDashBoard/>}/>
            <Route path={"/hoskote"} element={<HoskoteDashBoard/>}/>
            <Route path={"/shimoga"} element={<ShimogaDashboard/>}/>
            <Route path={"/hombale"} element={<HombaleDashBoard/>}/>
            <Route path={"/rnsales"} element={<RNSales/>}/>
            <Route path={"/hcp"} element={<HCPDashBoard/>}/>
            <Route path={"/KAHistory"} element={<KaHistoryDashBoard/>}/>
            <Route path={"/karaikudi"} element={<KaraikudiDashBoard/>}/>
            <Route path={"/indiacontacts"} element={<IndiaContactsDashBoard/>}/>
            <Route path={"/maps/vk"} element={<DVGMap/>}/>
            <Route path={"/padmanabhanagar"} element={<PadmanabhaNagarDashBoard/>}/>
            <Route path={"/mh_map"} element={<MHMap/>}/>
            <Route path={"/mh_field_summary"} element={<MHFieldSummary/>}/>
            <Route path={"/kopargaon"} element={<KopargaonDashBoard/>}/>
            <Route path={"/somaliland"} element={<SomaliLandDashBoard/>}/>
            <Route path={"/mhop"} element={<MHOPDashBoard/>}/>
            <Route path={"/mhopv2"} element={<MHOPV2DashBoard/>}/>
        </Routes>
    )
}