import React, {useState} from 'react';
import {useIsMobile} from "../../../custom-hooks";
import {safe_get} from "../../../report-visuals/report-utils";
import HomeIcon from '@mui/icons-material/Home';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {red} from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {styled} from '@mui/material/styles';
import {FloatingMenu} from "../../../components";
import {htmlValidation} from "../../../helpers";
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import WorkIcon from '@mui/icons-material/Work';
import { Fade } from 'react-awesome-reveal';


export type contact = {
    id: string,
    name: string,
    phone: string,
    alternate_phone: string,
    email: string,
    address: string,
    comments: string,
    designation: any
}
type contactProps = {
    contact: contact,
    onEdit: Function,
    onDelete: Function
}


interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme}) => ({
    marginLeft : 'auto'

}));

export const ContactCard = (props: contactProps) => {
    const [clicked, setClicked] = useState(false);
    const {contact, onEdit, onDelete} = props;
    const isMobile = useIsMobile();
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(expanded => !expanded);
    };
    console.log(expanded);

    const floatingMenuOptions = [
        {
            title: "Delete",
            icon: "fas fa-trash text-orange-500",
            onClick: () =>  onDelete(contact.id)

        }, {
            title: "Edit",
            icon: "fas fa-edit text-green-300",
            onClick : () => onEdit(contact)

        }
    ]

    return (
        <>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar sx={{bgcolor: red[500]}} aria-label="recipe">
                            <i className={`${safe_get(contact, "designation.icon", "fa-solid fa-user")}`}/>

                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon/>
                            <div className={"absolute right-0"}>
                                <FloatingMenu
                                    items={floatingMenuOptions}
                                    data ={contact}

                                />
                            </div>
                        </IconButton>
                    }
                    title={contact.name}

                    titleTypographyProps={{
                        sx: {
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: '#333333',
                            letterSpacing: '0.8px'
                        },
                    }}

                />
                <CardContent>
                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                        <div className="flex mb-3 gap-x-6 pl-2 ">
                            <PhoneAndroidIcon />
                            <p className="text-lg ">{contact.phone}</p>
                        </div>
                        <div className="flex mb-3 gap-x-6 pl-2">
                            <HomeIcon/>
                            <p className="text-lg ">{contact.address}</p>
                        </div>
                        <div className="flex gap-x-6 pl-2">
                            <ModeCommentIcon/>
                            <p className="text-lg">{contact.comments}</p>
                        </div>
                    </Typography>
                </CardContent>
                <Collapse in={expanded} timeout="auto" unmountOnExit

                >
                    <Fade key={expanded ? "A" : "B"}>
                        <CardContent >
                            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                <div className="flex mb-3 gap-x-6 pl-2 ">
                                    <EmailIcon/>
                                    <p className="text-lg ">{contact.email}</p>
                                </div>
                                <div className="flex mb-3 gap-x-6 pl-2">
                                    <CallIcon/>
                                    <p className="text-lg ">{contact.alternate_phone}</p>
                                </div>
                                <div className="flex  mb-3 gap-x-6 pl-2">
                                    <WorkIcon/>
                                    <p className="text-lg">{contact.comments}</p>
                                </div>
                            </Typography>
                        </CardContent>
                    </Fade>
                </Collapse>
                <CardActions   sx={{
                   position : expanded ? 'relative' : 'static',
                    top:'5px'
                }} disableSpacing>

                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        sx = {{

                            transform : expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition : 'transform 0.5s ease-in-out'
                        }}


                    >
                        <ExpandMoreIcon/>
                    </ExpandMore>
                </CardActions>




            </Card>

            {/*<Fade key={clicked ? "A" : "B"}>*/}
            {/*    <div style={{ position: expanded ? "absolute" : "relative" }}>*/}
            {/*        Animated Content!*/}
            {/*    </div>*/}
            {/*</Fade>*/}
        </>
    )
}