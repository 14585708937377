import {useQuery} from "@tanstack/react-query";
import {useStore} from "../../helpers";


export const usePBIReportList = () => {

    const {reportStore} = useStore();

    const fetchReportList = async () => {
        const res = await reportStore.getPBIReportsList();

        if(!res.ok){
            throw new Error("Failed to fetch Reports, please try again later")
        } else return res
    }

    const reportsList = useQuery({
        queryKey: ["pbi-reports-list"],
        queryFn: fetchReportList
    })

    return reportsList
}