import { observer } from "mobx-react-lite";
import { SubHeading } from "../../../helpers";
import { RNInput, RNSelect } from "../../../components/form-components";
import { Divider } from "@mui/material";
import React, { useMemo } from "react";
import { getOptions, useGrievanceOptions } from "../grievance-v2-hooks";
import { useFormContext } from "react-hook-form";
import { safe_get } from "../../../report-visuals";

export const GrievanceSummary = observer(() => {
	const grievanceOptions = useGrievanceOptions();
	const { watch, getValues } = useFormContext();
	const [department, complaint_type, hobli] = watch(["category_level_1", "category_level_2", "piv_level_1"]);
	const values = getValues();
	console.log(values);
	const complaintOptions = useMemo(() => {
		return getOptions(safe_get(grievanceOptions.grievanceProperties, `categories.${department}`, []));
	}, [department]);

	const villageOptions = useMemo(() => {
		const selectedVillage = grievanceOptions.hierarchy.find(value => value.id === hobli);
		return selectedVillage?.children;
	}, [hobli]);

	return (
		<>
			<SubHeading title={"Grievance Information"} />
			<div className={"flex flex-col gap-y-2"}>
				<RNInput label={"Complaint Title"} name={"title"} />
				{/* Department - Custom Select Box */}
				<RNSelect
					label={"Department"}
					name={"category_level_1"}
					options={grievanceOptions.departmentOptions}
					defaultValue={{ label: values?.category_level_1, value: values?.category_level_1 }}
				/>
				{department && (
					<RNSelect
						label={"Complaint Type"}
						name={"category_level_2"}
						options={complaintOptions}
						defaultValue={{ label: values?.category_level_2, value: values?.category_level_2 }}
					/>
				)}

				{complaint_type === "Other" && <RNInput label={"Specific Complaint"} name={"specific_complaint"} />}
				{/* Hobli - Custom Select Box */}
				<RNSelect label={"Hobli"} name={"piv_level_1"} options={grievanceOptions.hierarchy} />
				{/* Village - Custom Select Box */}
				{hobli ? (
					<RNSelect
						label={"Village"}
						name={"piv_level_2"}
						options={villageOptions}
						isMulti={true}
						defaultValue={{ label: values?.piv_level_2, value: values?.piv_level_2 }}
					/>
				) : null}

				{/* More Details - Custom Text Area */}
				<RNInput label={"Description"} name={"description"} multiline rows={4} />
				{/* Status - Custom Select Box */}
				<RNSelect label={"Status"} name={"current_status"} options={grievanceOptions.statusOptions} />
				{/* Priority - Custom Select Box */}
				<RNSelect label={"Priority"} name={"priority"} options={grievanceOptions.priorityOptions} />
				<Divider />
			</div>
		</>
	);
});
