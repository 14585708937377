import {grade_filter, SectionTitle} from "../booth-reports-utils";
import * as am4core from "@amcharts/amcharts4/core";
import {DonutChart3d} from "../../../../components/Charts";
import {GradeGrouping} from "./GradeGrouping";
import React from "react";
import {BoothGradingMethodology} from "./BoothGradingMethodology";
import {BoothGradingSummary} from "./BoothGradingSummary";
import {useStore} from "../../../../helpers";
import {observer} from "mobx-react-lite";
import { useTranslation } from "react-i18next";

export const BoothsByGrade = observer((props:any) => {

    const {flexiStore} = useStore();
    const {swot_by_booth} = flexiStore;

    const strong = grade_filter("STRONG", swot_by_booth);
    const weak = grade_filter("WEAK", swot_by_booth);
    const avg = grade_filter("AVERAGE", swot_by_booth);
    const threat = grade_filter("THREAT", swot_by_booth);
    const opportunity = grade_filter("OPPORTUNITY", swot_by_booth);
    const {t} = useTranslation("booth_bank");

    const chart_payload = [
        {
            label: t("strong"),
            count: strong.length,
            color: am4core.color('#1E8449')
        },
        {
            label: t("weak"),
            count: weak.length,
            color: am4core.color('#B03A2E')
        },
        {
            label: t("average"),
            count: avg.length,
            color: am4core.color('#3498DB')
        },
        {
            label: t("threat"),
            count: threat.length,
            color: am4core.color('#5B2C6F')
        },
        {
            label: t("opportunity"),
            count: opportunity.length,
            color: am4core.color('#D4AC0D')
        }
    ]

    return (
        <>
            <div className='page-container bg-white mx-auto mb-4 p-6 bg-white'>
                <SectionTitle text={'Methodology'}/>
                <BoothGradingMethodology />
                <SectionTitle text={'Overview'}/>
                <DonutChart3d data={chart_payload} _label="label" value="count" title={"SWOT"}/>
            </div>

            <div className='page-container bg-white mx-auto mb-4 p-6 bg-white'>
                <BoothGradingSummary />
            </div>

            <div className='page-container bg-white mx-auto mb-4 p-6 bg-white'>
                <SectionTitle text={'Booths Grouped by Grading'}/>
                {strong.length > 0 && <GradeGrouping data={strong} title="STRONG"/>}
                {avg.length > 0 ? <GradeGrouping data={avg} title="AVERAGE"/> : null}
                {weak.length > 0 ? <GradeGrouping data={weak} title="WEAK"/> : null}
                {threat.length > 0 ? <GradeGrouping data={threat} title="THREAT"/> : null}
                {opportunity.length > 0 ? <GradeGrouping data={opportunity} title="OPPORTUNITY"/> : null}
            </div>
        </>
    )
})