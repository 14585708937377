import {useStore} from "../../../helpers";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";

export const useDeletePBIReport = () => {

    const {reportStore} = useStore();
    const queryClient = useQueryClient();

    const closeModal = () => {
        reportStore.update_deletePBIReportModal(false)
    }

    const onSuccessfulMutation = async() => {
        closeModal();
        await queryClient.invalidateQueries(["pbi-reports-list"]);
        toast.success("Report Deleted Successfully");
    }

    const {mutate: deletePBIReport, isLoading:isDeleting} = useMutation({
        mutationFn: async (data: any) => {
            const res = await reportStore.deletePBIReport(data);
            if(!res.ok){
                throw new Error("Something went wrong")
            } else return res;
        },
        onSuccess: async() => {
            await onSuccessfulMutation();
        },
        onError: async (e) => {
            toast.error(e["message"]);
        },
    });

    return {
        deletePBIReport,
        isDeleting
    };

}