export type beneficiaryRequestType = {
    id: string;
    department: string;
    type: string;
    status: string;
    additional: string;
    assigned_to: any;
    documents: any;
};

export const beneficiaryRequestDefaultValues: beneficiaryRequestType = {
    id:"",
    department: "",
    type: "",
    status: "",
    additional: "",
    assigned_to: {},
    documents: {},
};
