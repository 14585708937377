import React from "react";
import {Typography} from "@mui/material";

export const Tables = ({table}) => {
    return (
        <>
            {
                !Array.isArray(table) || !table ?
                    <Typography variant={"h6"} color={"error"} className={"p-4"}>Data not available</Typography> :
                    <table className="my-3 fsd-caste-details mt-4 border-2 border-black">
                        {table.map((row, i) => {
                            return (
                                <thead key={i}>
                                <tr>
                                    {row && row.length > 0 && row.map((col, j) => {
                                        return j === 0 || col.class === "header col" ?
                                            <th key={j} className={`font-bold bg-gray-200 ${j===0 ? "text-left": "text-right"} first`}>
                                                {col.value}
                                            </th>
                                            :
                                            <td key={j} className="text-right caste_value border-2 border-black">
                                                {col.type === "value" ? Number.isInteger(col.value) ? col.value : col.value.toFixed(2) : col.value}
                                            </td>
                                    })}
                                </tr>
                                </thead>
                            );
                        })}
                    </table>
            }
        </>
    );
};