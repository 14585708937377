import React, {useEffect} from "react"
import {observer} from "mobx-react-lite";
import {useStore} from "../helpers";
import {FormButtons, LoadingState, TailwindModal} from "../components";
import {FormProvider, useForm} from "react-hook-form";
import * as Yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {RNInput} from "../components/form-components";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {safe_get} from "../report-visuals/report-utils";

type project = {
    name: string,
    dates: string,
    description: string
}

const defaultProject: project = {
    name: "",
    dates: "",
    description: ""
}

export const AddNewProject = observer((props: any) => {

    const {projectStore} = useStore();
    const {activeProject} = projectStore;
    const pid = safe_get(activeProject, "id", null)
    const queryClient = useQueryClient();

    const methods = useForm({
        defaultValues: defaultProject,
        resolver: yupResolver(
            Yup.object().shape({
                name: Yup.string().required("Project Name is required"),
                dates: Yup.string(),
                description: Yup.string(),
            })
        )
    })

    useEffect(()=>{
        methods.reset(activeProject)
    }, [activeProject])

    const onAddOrEditSuccess = (msg) => {
        setTimeout(async () => {
            await queryClient.invalidateQueries(["projects-list"])
        }, 1000);
        toast.success(msg)
    }

    const {mutate, isLoading} = useMutation({
        mutationFn: async(data) => await projectStore.addNewProject(data),
        onSuccess: () => onAddOrEditSuccess("Project added successfully."),
        onError: () => toast.error("Something went wrong")
    })

    const {mutate: editProject, isLoading: isEditing} = useMutation({
        mutationFn: async(data) => await projectStore.editProject(data),
        onSuccess: () => onAddOrEditSuccess("Project Updated successfully."),
        onError: () => toast.error("Something went wrong")
    })

    const onSubmit = (data) => {
        if (pid) {
            editProject({
                ...data,
                id: pid
            })
        } else {
            mutate(data)
        }
        closeModal()
    }

    const closeModal = () => {
        projectStore.set_addNewProjectModal(false)
        methods.reset(defaultProject);
    }

    if(isLoading || isEditing ) return <LoadingState />

    return(
        <>
            <TailwindModal isOpen={projectStore.addNewProjectModal}>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <div className="p-4 flex flex-col gap-y-4">
                                <h4 className="text-xl font-semibold text-gray-700">Add New Project</h4>
                                <RNInput
                                    name={"name"}
                                    label = "Project Name"
                                />
                                <RNInput
                                    name={"dates"}
                                    label = "Dates"
                                />
                                <RNInput
                                    name={"description"}
                                    label = "Description"
                                    multiline
                                    rows={3}
                                    fullWidth
                                />
                            </div>
                            <FormButtons
                                onSave={() => {}}
                                onCancel={closeModal}
                            />
                        </form>
                    </FormProvider>
                </div>
            </TailwindModal>
        </>
    )
})