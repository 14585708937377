import React, {Fragment, useRef} from "react";
import {Dialog, Transition} from "@headlessui/react";

export const TailwindModal = (props: any) => {

    const {isOpen, children} = props;
    const cancelButtonRef = useRef(null);
    return (
        <>
            <Transition.Root show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={isOpen}
                    onClose={() => {}}
                    style={{zIndex: 999}}
                >
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Panel className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                        </Transition.Child>
                        {/*<span className="sm:inline-block sm:align-middle sm:h-screen"*/}
                        {/*      aria-hidden="true">&#8203;</span>*/}
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="flex flex-col items-center justify-center h-screen">
                                {children}
                            </Dialog.Panel>

                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}