import React from "react"
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../helpers";
import {safe_get} from "../../../../report-visuals";
import {DFSingleSelect} from "./df-single-selct";
import {Divider} from "@mui/material";

export const VotersDynamicFilters = observer((props: any) => {

    const {projectStore} = useStore();
    const gotvConfig = projectStore.getProjectProperty("gotv_config", {});
    const dynamicFiltersConfig =  safe_get(gotvConfig, "dynamic_filters", []);

    return(
        <div className={"flex flex-col gap-y-3"}>
            {
                dynamicFiltersConfig.map((filter,i) => {
                    return (
                        <div key={i}>
                            <DFSingleSelect data={filter} onChangeHandler={(options, key) => props.habdleDF(options, key)}/>
                            <Divider />
                        </div>
                    )
                })
            }
        </div>
    )
})