import React, {useState} from "react" ;
import {ElectionResults} from "../../shared/election_results";
import {useTranslation} from "react-i18next";
import {isEmpty} from "lodash"
import {DataNotAvailable} from "./mh-2024-fieldview-main-page";
import {Typography} from "@mui/material";

export const HistoricalData = ({data}) => {

    const elections = Object.keys(data)
    const [active_election, set_active_election] = useState(elections[0])
    const {t} = useTranslation("ac_bank");

    if(isEmpty(data)) return <DataNotAvailable />

    return(
        <>
            <div className='p-4 sm:px-0'>
                <Typography variant={"h5"} className={"pb-4 uppercase"} color={"primary"}>{t("past_election_results")}</Typography>
                <div className='flex flex-row flex-wrap'>
                    {elections.map(x => {
                        const bg = (active_election === x) ? 'bg-indigo-500 text-white font-bold' : 'bg-gray-200 border border-black'
                        return (
                            <>
                                <div className={`mt-2 py-2 px-4 text-sm ${bg} cursor-pointer mr-4 rounded-2xl`}
                                     onClick={() => {
                                         set_active_election(x)
                                     }}
                                >{x}</div>
                            </>
                        )
                    })}
                </div>
                {active_election !== '' ? (<ElectionResults name={active_election} ds={data[active_election]}/>) : null}
            </div>
        </>
    )
}