import {useQuery} from "@tanstack/react-query";
import {useStore} from "../helpers";
import {useParams} from "react-router-dom";

export const useGetFullProject = () => {

    const rootStore = useStore();
    const {projectid} = useParams();
    const {projectStore} = rootStore;

    const {data, isLoading, error, isError, refetch} = useQuery({
        queryKey:["full-project", projectid],
        queryFn: async() => {
            return await projectStore.getFullProject(projectid);
        },
        refetchOnWindowFocus: false,
    })

    return {data, isLoading, error, isError, refetch}

}