import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useStore} from "../../../helpers";
import {safe_get} from "../../../report-visuals";
import {RNCheckBoxTree} from "../../../components";
import {useParams} from "react-router-dom";


export const C3ConcernedPerson = observer((props:any) => {

    const { projectStore, c3Store } = useStore();
    const [beneficiary, setBeneficiary] = useState([]);
    const [checked, setChecked]= useState(safe_get(c3Store, "selected_office_persons" ,[]));
    const {projectid} = useParams();

    useEffect(() => {
        const beneficiary_project_property = projectStore.getProjectProperty("beneficiary", {})
        const persons_options_for_dropdown = safe_get(beneficiary_project_property, "owners", []);
        const transformed_values = persons_options_for_dropdown.map(x => {
            return {
                ...x,
                label:`${x.name} - ${x.designation}`,
                value:x.name,
                name:x.name,
                designation: x.designation,
            }
        })
        setBeneficiary(transformed_values);
    }, [projectid]);

    return(
        <>
            <RNCheckBoxTree
                data={beneficiary}
                on_node_selection={(nodes) => {
                    setChecked(nodes)
                    props.onPersonSelection(nodes)
                }}
                checked_nodes={checked}
            />
        </>
    )
})