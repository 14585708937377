import React from "react";
import { useStore } from "../../helpers";
import { useNavigate, useParams } from "react-router-dom";
import { ViewAttachment } from "../../components";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import HowToVoteIcon from "@material-ui/icons/HowToVote";
import CalendarMonthIcon from "@material-ui/icons/CalendarToday";
import { RenderRequestStatus } from "../beneficiary-management";
import { safe_get } from "../../report-visuals";
import { displayVillageNames } from "../project-works";
import { observer } from "mobx-react-lite";
import { Fade } from "react-awesome-reveal";

export const ProjectWorkCards = observer(() => {

  const { projectid } = useParams();
  const navigate = useNavigate();
  const { projectWorkStoreV2 } = useStore();
  const { projectWorksList } = projectWorkStoreV2;

  const projDetailsClickHandler = async (project: any) => {
    navigate(`/project/${projectid}/projectworks_v2/${project.id}`, {
      state: { info: JSON.stringify(project) },
    });
  };

  return (
    <div className="pb-4 max-w-lg mx-auto grid gap-5 xl:grid-cols-4 lg:grid-cols-3 lg:max-w-none">
      {projectWorksList.length > 0 &&
        projectWorksList.map((project, index) => {
          const attachment = safe_get(project.summary, "coverPhotoPath", {});
          const events = safe_get(project, "others.events", []);
          return (
            <Fade
              cascade={false}
              direction="up"
              triggerOnce={true}
              delay={index * 200}
            >
              <div
                key={project.id}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer max-h-[600px] zoom-on-hover"
                onClick={(evt) => {
                  projDetailsClickHandler(project);
                }}
                title={project.summary["decription"]}
              >
                <div className="flex-shrink-0">
                  <div className="w-full object-cover">
                    {project?.summary["coverPhotoPath"] !== "" ? (
                      <ViewAttachment
                        attachment={attachment}
                        label={false}
                        styles={{ height: "300px", width: "100%" }}
                      />
                    ) : (
                      <img
                        className="h-48 w-full object-cover"
                        src={"/images/default_image.jpeg"}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="flex-1 bg-white p-3 flex flex-col justify-between">
                  <div className="flex-1 flex flex-col gap-y-2">
                    <p className="text-xl font-semibold text-gray-900">{project.summary["name"]}</p>
                    <div className={"flex flex-row gap-x-2"}>
                      <LocationOnIcon color={"primary"} />
                      <p>{safe_get(project.summary, "location", "N/A")}</p>
                    </div>

                    <div className={"flex flex-row gap-x-2"}>
                      <HowToVoteIcon color={"primary"} />
                      <p>{displayVillageNames(project.summary["village"])}</p>
                    </div>

                    <div className={"flex flex-row gap-x-2 items-center"}>
                      <CalendarMonthIcon color={"primary"} />
                      <p>{project["created"]}</p>
                      <RenderRequestStatus
                        feature={"project_works"}
                        status={safe_get(project, "summary.status", "")}
                      />
                    </div>
                    {events.length > 0 ? (
                      <div className={"flex flex-row gap-x-2"}>
                        <p className="font-bold">Event Date :</p>
                        {events.map((event) => (
                          <p className="font-bold">{event.date}</p>
                        ))}
                      </div>
                    ) : null}

                    <p className="pb-4 overflow-hidden text-gray-500 max-h-32">
                      {project.summary["description"]}
                    </p>
                  </div>
                </div>
              </div>
            </Fade>
          );
        })}
    </div>
  );
});
