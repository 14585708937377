import React, {useEffect} from "react";
import { useStore } from "../../helpers";
import { observer } from "mobx-react-lite";
import { VoterCard } from "./VoterCard";
import { useTranslation } from "react-i18next";
import {EditVoter} from "./edit-voter";
import {FilterOverLay, SearchVoterList} from "../voter-list";
import {LoadingState, SideOverlay} from "../../components";
import {useGetFullProject} from "../../custom-hooks";
import {useParams} from "react-router";

export const ManageVoters = observer(() => {

    const rootStore = useStore();
    const { voterListStore } = rootStore;
    const { FilterStatus, Voterlist } = voterListStore;
    const {edit_voter_modal} = voterListStore;
    const { t } = useTranslation("manage_voters");
    const {isLoading} = useGetFullProject();
    const {projectid} = useParams();

    const close_Filter_modal = () => {
        voterListStore.setFilterStatus(false)
        voterListStore.setShowVoterlist(true)
        voterListStore.setFilterVal(null);
        voterListStore.setSelectedBooths([]);
    }

    useEffect(()=>{
        return () => voterListStore.reset_filters(parseInt(projectid))
    }, [])

    if(isLoading) return <LoadingState />

    return (
        <>
            <div className="px-4 pb-4 flex flex-col gap-y-4">
                <SearchVoterList isManage={true} user={true} />
                <div className="overflow-hidden my-2">
                    <ul role="list" className=" grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-4">
                        {Voterlist?.map((data) => {
                            return (
                                <li key={data.id}>
                                    <VoterCard voter={data} edit={true}/>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>

            <SideOverlay
                children={<FilterOverLay />}
                onClose={close_Filter_modal}
                show={FilterStatus}
                title={t("filter_votersearch")}
            />

            <SideOverlay
                onClose={() => voterListStore.update_edit_voter_modal(!edit_voter_modal)}
                show={edit_voter_modal}
                children={<EditVoter />}
                title={"Edit Voter"}
            />
        </>
    );
})

export default ManageVoters;
