import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useStore} from "../../../helpers";
import {safe_get} from "../../../report-visuals";
import {RNCheckBoxTree} from "../../../components";
import {get_booth_node_by_boothnumber} from "../../../custom-hooks/useBoothNumber";
import {Typography} from "@mui/material";


export const C3BoothFilter = observer((props: any) => {

    const {onBoothSelection} = props;

    const {projectStore, c3Store} = useStore();
    const [hierarchy, setHierarchy] = useState([]);
    const [checked, setChecked] = useState(safe_get(c3Store, "selected_booths", []));

    useEffect(() => {
        setHierarchy(projectStore.getProjectProperty("hierarchy", []));
    }, []);

    return (
        <div className={"flex flex-col gap-y-2"}>
            <Typography color={"primary"}>Select a specific village or booth to view relevant data.</Typography>
            <RNCheckBoxTree
                data={hierarchy}
                on_node_selection={(nodes) => {
                    const final = nodes.map(node => get_booth_node_by_boothnumber(node))
                    setChecked(final)
                    onBoothSelection(final)
                }}
                checked_nodes={Array.isArray(checked) ? checked.map(val => val.value) : checked}
            />
        </div>
    )
})