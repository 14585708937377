import * as Yup from "yup"

export type ReportType = {
    title: string,
    link: string,
    route: string
    passcode: string,
}

export const ReportDefaultValues:ReportType = {
    title: "",
    passcode: "",
    link: "",
    route: ""
}

export const ReportValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    passcode: Yup.string().required("Passcode is required"),
    link: Yup.string().required("link is required"),
    route: Yup.string().required("Route is required"),
})