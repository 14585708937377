import React, {Fragment, useEffect} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XCircleIcon} from "@heroicons/react/24/solid";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {safe_get} from "../../report-visuals";
import {useForm, Controller} from "react-hook-form";
import {FormButtons} from "../../components/cm-components";
import {TextField, FormControl, InputLabel, Select, MenuItem, Stack} from '@mui/material';
import {useStore} from "../../helpers"
import _ from "lodash"

type SideOverlayProps = {
    onClose: Function,
    show: boolean,
    title?: string,
    data: any
}

export const FiSideOverlay = observer((props: SideOverlayProps) => {

    const {onClose, show, title = "", data} = props;
    const rootStore = useStore();
    const {fieldIntelligenceStore} = rootStore;
    const {activeNode, activeRow} = fieldIntelligenceStore;

    const form_fields = safe_get(data, "fields", []);

    const result = form_fields?.reduce((acc, field) => {
        if (field.field_type === "dropdown") {
            acc[field.field_name] = [];
        } else if (field.field_type === "text") {
            acc[field.field_name] = "";
        } else if (field.field_type === "text-area"){
            acc[field.field_name] = "";
        }
        return acc;
    }, {});

    const {t} = useTranslation("components");



    useEffect(() => {
        Object.keys(activeRow).map(key => setValue(key, safe_get(activeRow, key , null)))
    }, [activeRow])

    const {control, register, handleSubmit, formState: {errors, isSubmitted}, reset, setValue} = useForm({
        defaultValues: _.isEmpty(activeRow) ? result : activeRow
    });

    const onSubmit = async (data: any) => {
        const copy_sc = _.clone(activeNode);
        copy_sc["data"].push(data);
        await fieldIntelligenceStore.update_FiStoreProp("activeNode", copy_sc)
        fieldIntelligenceStore.update_FiStoreProp("addNewModal", false)
        console.log(data);
    };

    if (isSubmitted) {
        reset(result);
    }

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => {
            }}>
                <div className="absolute inset-0 overflow-hidden font-medium">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Panel className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-lg sm:max-w-full pl-10">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="pointer-events-auto w-screen max-w-xl">
                                <div className="flex h-full flex-col overflow-y-scroll py-4 shadow-xl bg-gray-100 rounded-l-lg">
                                    <div className="px-2 pt-12 sm:px-4 sm:pt-0">
                                        <div className="flex items-center justify-between">
                                            <Dialog.Title
                                                className="text-2xl py-4 font-bold text-purple-600 uppercase">{title}</Dialog.Title>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    className="rounded-xl text-xl text-gray-400 hover:text-gray-500 focus:outline-none hover:bg-white"
                                                    onClick={() => {
                                                        onClose()
                                                        reset(result);
                                                    }}
                                                >
                                                    <span className="sr-only">{t("close_panel")}</span>
                                                    <XCircleIcon className="h-8 w-8" aria-hidden="true"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative flex-1 pl-4 sm:pl-6">
                                        {/*<div className="text-lg font-bold text-purple-600 uppercase">{title}</div>*/}
                                        <div
                                            className="flex flex-col flex-1 h-full align-bottom text-left overflow-hidden transform transition-all sm:my-2 sm:align-middle max-w-lg w-full">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                {form_fields.map((field, index) => {
                                                    const type = safe_get(field, "field_type", "text")
                                                    const name = safe_get(field, "field_name", "");
                                                    const dropdown_options = safe_get(field, "options", []);
                                                    const isRequired = safe_get(field, "mandatory", false);
                                                    return (
                                                        <>
                                                            {
                                                                type === "text" ?
                                                                    <Stack direction={"row"} gap={4} my={2}>
                                                                        <TextField
                                                                            key={field.id}
                                                                            label={name}
                                                                            variant="filled"
                                                                            fullWidth
                                                                            {...register(name, {required: isRequired})}
                                                                            error={!!errors.name}
                                                                            required={isRequired}
                                                                        />
                                                                    </Stack> : null
                                                            }
                                                            {
                                                                type === "dropdown" ?
                                                                    <Stack key={field.id} direction={"row"} gap={4} my={2}>
                                                                        <FormControl
                                                                            className={"my-2"}
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            error={!!errors.status}
                                                                        >
                                                                            <InputLabel>{name}</InputLabel>
                                                                            <Controller
                                                                                name={name}
                                                                                control={control}
                                                                                rules={{required: isRequired}}
                                                                                render={({field: {onChange, value}}) => (
                                                                                    <Select
                                                                                        label={name}
                                                                                        variant={"filled"}
                                                                                        value={value ?? ""}
                                                                                        onChange={onChange}
                                                                                    >
                                                                                        {dropdown_options.map((status) => (
                                                                                            <MenuItem key={status}
                                                                                                      value={status}>
                                                                                                {status}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                )}
                                                                            />
                                                                        </FormControl></Stack> : null
                                                            }
                                                            {
                                                                type === "text-area" ?
                                                                    <Stack key={field.id} direction={"column"}>
                                                                        <label>{name}</label>
                                                                        <textarea
                                                                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                                                                            rows={3}
                                                                            key={field.id}
                                                                            {...register(name, {required: isRequired})}
                                                                            // error={!!errors.name}
                                                                            required={isRequired}
                                                                        /></Stack> : null
                                                            }
                                                        </>
                                                    )
                                                })}
                                                <FormButtons
                                                    onCancel={() => {
                                                        fieldIntelligenceStore.update_FiStoreProp("addNewModal", false)
                                                        reset(result);
                                                    }}
                                                    onSave={() => {
                                                    }}
                                                    type={"submit"}
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
})