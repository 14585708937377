export type User = {
    name: string,
    phonenum: string,
    role: string,
    tags: Array<string>,
}

export const defaultUser: User = {
    name: "",
    phonenum: "",
    role: "",
    tags: [],
}
