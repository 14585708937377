import React from "react";
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
} from "@mui/material";
import {Tables} from "./mh-tables";
import {safe_get} from "../../../report-visuals";

export const FieldSamplesData = ({data, samples}) => {

    const {tables, titles} = data;

    const indices = safe_get(data, "index", [])

    return (
        <div className="py-4 px-4 sm:px-0">
            <Typography className={"pb-4"} variant={"h6"} color={"primary"}>Total number of samples collected: {samples}</Typography>
            {
                // TODO: Render the questions based on Index
                indices && indices.map((item, i) => {
                    return (
                        <div className={"mb-4"} key={i}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<i className="fa-solid fa-arrow-down"/>}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography>
                                        <h3>{safe_get(titles, item, "")}</h3>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Tables table={tables[item]}/>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    )
                })
            }
        </div>
    );
};
