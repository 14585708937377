import {useTranslation} from "react-i18next";
import {safe_get} from "../../report-visuals";
import ProgressBar from "../../components/ProgressBar";
import * as React from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers";

const RenderInterviewStatus = ({icon, value}) => {
    return (
        <>
            <div className='flex flex-col'>
                <div title='Valid' className='cursor-pointer text-2xl'
                     style={{color: '#138D75'}}>
                    <i className={icon}/>
                </div>
                <div className='text-center'>{value}</div>
            </div>
        </>
    )
}

export const Interviews = props =>{
    const {task} = props;
    const [t] = useTranslation("task_manager");
    return (
        <div className='flex flex-col my-2'>
            {/*<div className='text-center font-semibold text-gray-500'>{t("interviews")}</div>*/}
            <div className='flex flex-row justify-between'>
                <RenderInterviewStatus
                    icon={"fa-solid fa-circle-check text-green-500"}
                    value={safe_get(task, 'status.by_qastatus.valid', 0)}
                />
                <RenderInterviewStatus
                    icon={"fa-solid fa-circle-xmark text-red-500"}
                    value={safe_get(task, 'status.by_qastatus.invalid', 0)}
                />
                <RenderInterviewStatus
                    icon={"fa-solid fa-circle-exclamation text-yellow-500"}
                    value={safe_get(task, 'status.by_qastatus.not-checked', 0)}
                />
            </div>
        </div>
    )
}

export const TaskRowRender = observer((props:any) => {
    const {task} = props;
    const rootStore = useStore();
    const {taskManagerStore} = rootStore;
    const [t] = useTranslation("task_manager")
    return (
        <tr key={task['id']} onClick={e => {
            taskManagerStore.set_task_view_modal(true);
            taskManagerStore.set_active_task(task);
        }}>
            <td className="whitespace-nowrap p-3 text-sm font-semibold leading-5 text-black sm:pl-6">
                {task["more"]["name"]}
                <dl className="font-normal lg:hidden">
                    <dd className="truncate">
                        <div className="flex flex-row justify-between items-center">
                            <p>{task["node"]["piv_level1"]}/{task["node"]["piv_level2"]}</p>
                            <p>
                                {task['assigned'] ?
                                    <span
                                        className="bg-green-100 inline-flex rounded-lg p-2 font-semibold leading-5 text-black">{safe_get(task['user'], "name", "Not Assigned")}</span>
                                 :
                                    <span
                                        className="bg-red-100 inline-flex rounded-lg p-2 font-semibold leading-5 text-black">{t("unassigned")}</span>
                                }
                            </p>
                        </div>
                    </dd>
                    <dd className="mt-1 truncate sm:hidden rounded-lg">
                        <p className="font-semibold text-gray-600">{t("interviews")}</p>
                        <div className="flex flex-row justify-between items-center mt-1">
                            <p>{t("required")}: {task['count']["count"]}</p>
                            <p>{t("pending")}: {task['status']["pending"]}</p>
                        </div>
                    </dd>
                </dl>
            </td>
            <td className="hidden whitespace-nowrap p-3 text-sm font-medium text-gray-900 sm:pl-6 sm:table-cell lg:table-cell">
                {task["node"]["piv_level1"]}/{task["node"]["piv_level2"]}
            </td>
            <td className="hidden whitespace-nowrap p-3 text-sm text-gray-500 sm:table-cell lg:table-cell">
                {task['assigned'] ? (
                    <span
                        className="bg-green-100 inline-flex rounded-lg p-2 font-semibold leading-5 text-black">{safe_get(task['user'], "name", "Not Assigned")}</span>
                ) : (
                    <span
                        className="bg-red-100 inline-flex rounded-lg px-2 font-semibold leading-5 text-black">{t("unassigned")}</span>
                )}
            </td>
            <td className="hidden whitespace-nowrap p-3 text-sm text-gray-500 sm:table-cell lg:table-cell">{task['count']["count"]}</td>
            <td className="hidden lg:table-cell whitespace-nowrap p-3 text-sm sm:table-cell text-gray-500">
                <ProgressBar
                    title="Field Work"
                    progress={task["status"]["progress_field_work"]}
                    color={"#2ECC71"}
                    height={20}
                />
            </td>
            <td className="hidden lg:table-cell whitespace-nowrap p-3 text-sm sm:table-cell text-gray-500">
                <ProgressBar
                    title="QC Check"
                    progress={task["status"]["progress_qa"]}
                    color={"#F1C40F"}
                    height={20}
                />
            </td>
            <td className="hidden lg:table-cell whitespace-nowrap p-3 text-sm sm:table-cell text-gray-500">
                <Interviews task={task}/>
            </td>
        </tr>
    )
})