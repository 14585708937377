import React, { Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { Popover, Transition } from "@headlessui/react";
import {
  ChatBubbleBottomCenterIcon,
  ChevronDownIcon,
  ClipboardDocumentCheckIcon,
  AdjustmentsHorizontalIcon,
  QuestionMarkCircleIcon,
  UserIcon
} from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {IconButton, Modal, Typography} from "@mui/material";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import {
  BreadCrumbs, LoadingState,
  ProjectHeader,
} from "../components";
import DeleteIcon from "@material-ui/icons/Delete";
import Features from "../project-properties/Features";
import { safe_get } from "../report-visuals";
import QuestionsWindow from "../project-properties/QuestionsWindow";
import InfluentialLeaderWindow from "../project-properties/components/influenceLeaderProjectProperty/InfluenceLeaderWindow";
import {
  classNames,
  htmlValidation,
  makeid_alpa_numeric,
  renameKeys,
  useStore,
} from "../helpers";


// Main component to manage the project, wrapped in an observer to track MobX state changes.
export const ManageProject = observer((props) => {

  const [t] = useTranslation("ManageProject");

  const rootStore = useStore();
  const { projectStore,influenceLeaderProjectProperty } = rootStore;
  const { questionStore } = rootStore;
  const navigate = useNavigate();
  const { projectid } = useParams();
  const { userStore } = rootStore;
  const isRootOrClient: boolean = userStore.isRoot() || userStore.isClient();

  const [projectName, setProjectName] = useState("");
  const [disable, setDisable] = useState(false);
  const [surveyName, setSurveyName] = useState({});
  const [hide, setHide] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fullProject, setFullProject] = useState({});
  const [allSurveyList, setAllSurveyList] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [selectedSurveyForCloning, setSelectedSurveyForCloning] = useState({});
  const [isSurveyListModalOpen, setIsSurveyListModalOpen] = useState(false);
  const [properties, setProperties] = useState({});
  const [features, setFeatures] = useState({});
  const [fetched, setFetched] = useState(false);
  const [activeProperty, setActiveProperty] = useState("empty-property");
  const [json, setJson] = useState("{}");
  const [activeTabName, setActiveTabName] = useState<number>(1);
  const [isValidJson, setIsValidJson] = useState(false);
  const [popOver, setPopOver] = useState(false);
  const [leader,setLeader] = useState(false);


  // Define tabs for navigation within the project management interface.
  const tabs = [
    { id: 1, name: t("surveys"), icon: ClipboardDocumentCheckIcon },
    { id: 2, name: t("properties"), icon: ChatBubbleBottomCenterIcon },
    { id: 3, name: t("web_features"), icon: AdjustmentsHorizontalIcon },
    { id: 4, name: "Profile Questions", icon: QuestionMarkCircleIcon },
    ...(leader ? [{ id: 5, name: "Influential Leaders", icon: UserIcon }] : [])
  ];

  // Function to handle the change of project name selection.
  const handleChange = (event: SelectChangeEvent) => {
    setProjectName(event.target.value);
    setHide(false);
    setDisable(true);
  };

  // Function to handle the change of survey name selection.
  const handleChangeId = (event: SelectChangeEvent) => {
    setSurveyName(event.target.value);
    setSelectedSurveyForCloning(event.target.value);
  };

  // Breadcrumbs configuration for navigation.
  const crumbs = [
    { name: "DashBoard", href: `/project/${projectid}`, current: true },
    { name: "Manage Project", href: "#", current: true },
  ];

  // Function to fetch project data and set state accordingly.
  const fetchProjectData = async () => {
    setLoading(true);
    const response = await projectStore.getFullProject(projectid);

    const defaultData = {
      name: "test",
      description: "test Desc",
      dates: "2021",
    };
    setFullProject(response.data == null ? defaultData : response.data);
    setSurveys(response.data["surveys"]);
    setLoading(false);
    setProperties(response.data["properties"]);
    setFetched(true);
    setFeatures(properties["features"]);
    const hasInfluenLeader = Object.keys(response.data["properties"]).includes('influential_leaders');
    setLeader(hasInfluenLeader);

  };

  // Function to fetch the list of all surveys.
  const fetchAllSurveyListData = async () => {
    setLoading(true);
    const response = await projectStore.getAllSurveyList();
    setAllSurveyList(response);
    setLoading(false);

  };

  // useEffect hook to load project and survey data on component mount or when project ID changes.
  useEffect(() => {
    fetchProjectData();
    fetchAllSurveyListData();
    setActiveProperty("empty-property");
  }, [projectid]);

  // Function to clone a new survey based on the selected survey.
  const newSurveyClone = async () => {
    if (!Object.keys(selectedSurveyForCloning).includes("surveyid")) return;
    setLoading(true);
    await projectStore.cloneSurvey(
        fullProject["id"],
        selectedSurveyForCloning["surveyid"]
    );
    await fetchProjectData();
    setProjectName("");
    setSurveyName({});
    setLoading(false);
  };

  // Function to create a new blank survey.
  const newBlankSurvey = async () => {
    setLoading(true);
    await projectStore.blankSurvey(fullProject["id"]);
    await fetchProjectData();
    setLoading(false);
    toast.success("Blank Survey created successfully");
  };

  // Function to delete an existing survey.
  const deleteSurvey = async (surveyIdForDeletion) => {
    setLoading(true);
    await projectStore.deleteSurvey(fullProject["id"], surveyIdForDeletion);
    await fetchProjectData();
    setLoading(false);
  };

  // Component to display project metadata.
  const ProjectMeta = () => {
    return (
        <div>
          <div className="font-bold text-2xl text-base text-black-500">
            {fullProject["name"]}
          </div>
          <div className="text-base text-base">{fullProject["dates"]}</div>
          <div className="text-sm">{fullProject["description"]}</div>
        </div>
    );
  };

  // Component to render survey editor links.
  const SurveyEditorLinks = () => {
    const header = (
        <div className="flex flex-row items-center">
          <h3 className="title font-bold">{t("manage_surveys")}</h3>
          <IconButton
              aria-label="delete"
              onClick={() => {
                setIsSurveyListModalOpen(true);
              }}
              sx={{ width: "fit-content" }}
          >
            <i className="fa-solid fa-plus-circle" />
          </IconButton>
        </div>
    );

    if (!surveys) {
      return <div className="my-2">{header}</div>;
    }

    return (
        <div className="my-2">
          {header}
          <ul className={"my-2"}>
          {surveys.map((x) => {
              return (
                  <li
                key={x["id"]}
                      className="my-2 mx-4 max-w-none grid gap-5 grid-cols-3 xl:grid-cols-7 lg:grid-cols-4 md:grid-cols-3 "
                  >
                    <div className="">Survey ID: {x["id"]}</div>
                    <div
                        className="text-blue-700 font-bold cursor-pointer"
                        onClick={() => {
                          navigate(
                              `/project/${projectid}/manage/survey/${x["id"]}/edit`
                          );
                        }}
                    >
                      {x["name"]}
                    </div>
                    <IconButton
                        color={"error"}
                        onClick={async (e) => {
                          htmlValidation(e);
                          await deleteSurvey(x["id"]);
                        }}
                        sx={{ width: "fit-content" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </li>
              );
            })}
          </ul>
        </div>
    );
  };

  // Function to close the survey list modal and reset states.
  const closeSurveyListModalOpen = () => {
    setIsSurveyListModalOpen(false);
    setProjectName("");
    setSurveyName({});
    setDisable(false);
  };

  // Component to render the modal dialog for selecting and cloning surveys.
  const ModalDialog = () => {
    const allSurveyListByProject = _.groupBy(allSurveyList, (x) => {
      return x["projectname"];
    });

    const style = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      border: "1px solid #000",
      boxShadow: 24,
      p: 4,
    };

    return (
        <Modal
            open={isSurveyListModalOpen}
            onClose={closeSurveyListModalOpen}
            sx={{
              display: "flex",
              p: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
        >
          <Box sx={style}>
            <div className="flex flex-col overflow-y-hidden">
              <div className="flex flex-row flex-nowrap">
                <div className="font-bold text-2xl">{t("add_new_survey")}</div>
                <div
                    className="ml-auto text-2xl text-red-500 hover:text-red-800 cursor-pointer"
                    onClick={closeSurveyListModalOpen}
                >
                  <i className="fa-solid fa-times" />
                </div>
              </div>

              <div className="italic text-md">
                {t("existing_survey_to_clone_from")}
              </div>

              <div className="flex-1 overflow-y-auto">
                <div className="pt-3 sm:grid sm:grid-cols-1 sm:items-start d-flex flex-column text-left">
                  <FormControl sx={{ m: 1, minWidth: 300 }}>
                    <label
                        htmlFor="message"
                        className="block text-gray-700  rounded-lg text-left font-semibold"
                    >
                      {t("project_name")}
                    </label>

                    <Select
                        labelId="project_name"
                        id="ProjectName"
                        value={projectName}
                        onChange={handleChange}
                        className=""
                    >
                      {Object.keys(allSurveyListByProject).map((projectName) => {
                        return (
                        <MenuItem key={crypto.randomUUID()} value={projectName}> {projectName} </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <div className="pt-3 sm:grid sm:grid-cols-1 sm:items-start d-flex flex-column text-left">
                    <FormControl sx={{ m: 1, minWidth: 300 }}>
                      <label
                          htmlFor="message"
                          className="block text-gray-700  rounded-lg text-left font-semibold"
                      >
                        {t("survey_name")}
                      </label>

                      <Select
                          labelId="Survey_name"
                          id="SurveyName"
                          value={surveyName["surveyname"]}
                          onChange={handleChangeId}
                          disabled={hide}
                      >
                        {allSurveyListByProject[projectName] &&
                            allSurveyListByProject[projectName].map((x, index) => {
                              return (
                                  <MenuItem key={index} value={x}>
                                    {x["surveyname"]}
                                  </MenuItem>
                              );
                            })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="flex flex-row-reverse flex-nowrap mt-8">
                <button
                    onClick={async () => {
                      await newSurveyClone();
                      setDisable(false);
                      setIsSurveyListModalOpen(false);
                    }}
                    className="flex shadow text-center block bg-green-600 border-2 rounded-full px-4 py-2 text-white"
                >
                  {t("clone_selected_survey")}
                </button>
                <button
                    disabled={disable}
                    onClick={async () => {
                      await newBlankSurvey();
                      setIsSurveyListModalOpen(false);
                    }}
                    className={
                      disable
                          ? "flex mx-4 shadow text-center block bg-blue-300 border-2 rounded-full px-4 py-2 text-white"
                          : "flex mx-4 shadow text-center block bg-blue-600 border-2 rounded-full px-4 py-2 text-white"
                    }
                >
                  {t("create_blank_survey")}
                </button>
                <button
                    onClick={() => {
                      setIsSurveyListModalOpen(false);
                      setProjectName("");
                      setSurveyName({});
                      setHide(true);
                      setDisable(false);
                    }}
                    className="flex shadow text-center block border-red-600 border-2 rounded-full px-4 py-2 text-red-600"
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
          </Box>
        </Modal>
    );
  };

  // Component to render the tab view for navigating between surveys and properties.
  const TabView = () => {
    return (
        <div className="">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              {t("select_a_tab")}
            </label>
            <select
                id="tabs"
                name="tabs"
                className="p-4 block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-lg"
                value={activeTabName}
                onChange={(eve) => {
                  setActiveTabName(parseInt(eve.target.value));
                }}
            >
              {tabs.map((tab) => {
                return (
                    <option key={tab.id} value={tab.id}>
                      {tab.name}
                    </option>
                );
              })}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b-2 border-gray-400">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => {
                  const isThisActiveTab = activeTabName === tab.id;
                  return (
                      <a
                          key={tab.name}
                          className={classNames(
                              isThisActiveTab
                                  ? "border-indigo-500 text-indigo-600"
                                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                              "group inline-flex items-center py-2 px-1 border-b-2 cursor-pointer"
                          )}
                          aria-current={isThisActiveTab ? "page" : undefined}
                          onClick={(e) => setActiveTabName(tab.id)}
                      >
                        <tab.icon
                            className={classNames(
                                isThisActiveTab
                                    ? "text-indigo-500"
                                    : "text-gray-400 group-hover:text-gray-500",
                                "-ml-0.5 mr-2 h-8 w-8"
                            )}
                            aria-hidden="true"
                        />
                        <span className="text-lg font-medium">{tab.name}</span>
                      </a>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
    );
  };

  // Component to render the list of properties for the active project.
  const ActiveProperties = () => {
    const properties = fullProject["properties"];
    return (
        <div>
          {Object.keys(fullProject["properties"])
              .sort()
              .map((name, index) => {
                const isActive = name === activeProperty;
                // console.log(name);
                return (
                    <div
                        key={index}
                        className={classNames(
                            isActive
                                ? "text-blue-600 bg-white rounded-lg font-bold"
                                : "text-gray-800",
                            "cursor-pointer font-medium uppercase px-4 py-2 my-2"
                        )}
                        onClick={(evt) => {
                          setActiveProperty(name);
                          setJson(
                              JSON.stringify(
                                  fullProject["properties"][name]["value"],
                                  null,
                                  4
                              )
                          );
                        }}
                    >
                      {properties[name]["type"] === "json" && (
                          <i className="fa-solid fa-code px-2 " />
                      )}
                      {properties[name]["type"] === "value" && (
                          <i className="fa-solid fa-paperclip px-2" />
                      )}
                      {name}
                    </div>
                );
              })}
        </div>
    );
  };

  // Function to add a new JSON property to the project.
  const addJson = () => {
    const new_ds_id = makeid_alpa_numeric(5);
    const new_property = _.clone(fullProject["properties"]);

    const new_ds = {
      id: new_ds_id,
      type: "json",
      value: {
        name: t("test"),
      },
    };
    new_property[new_ds_id] = new_ds;
    setFullProject({ ...fullProject, properties: new_property });
    setPopOver(false);
  };

  // Function to add a new value property to the project.
  const addValue = () => {
    const new_ds_id = makeid_alpa_numeric(5);
    const new_valueproperty = _.clone(fullProject["properties"]);

    const new_ds = {
      id: new_ds_id,
      type: "value",
      value: "value",
    };

    new_valueproperty[new_ds_id] = new_ds;
    setFullProject({ ...fullProject, properties: new_valueproperty });
    setPopOver(false);
  };

  // Function to save the project properties.
  const saveProjectProperties = async (isValidJson) => {
    const payload = {
      id: fullProject["id"],
      properties: fullProject["properties"],
    };
    setLoading(true);

    if (!isValidJson) {
      setLoading(false);
      return;
    }

    await projectStore.editProjectProperties(payload);
    fetchProjectData();
    setLoading(false);
    setIsValidJson(false);
  };

  // Function to save the edited JSON property.
  const saveEditedJSON = () => {
    try {
      const data = JSON.parse(json);
      setIsValidJson(true);
      let cloned = _.clone(fullProject["properties"]);
      cloned[activeProperty]["value"] = data;
      setFullProject({ ...fullProject, properties: cloned });

      saveProjectProperties(!isValidJson);

      toast.success("Property Updated Successfully")
    } catch (e) {
      toast.error("invalid JSON");
      setIsValidJson(false);
    }
  };

  // Component to render the left section with the list of properties.
  const PropertiesLeftSection = () => {
    const datasetTypes = [
      { name: "json", description: "upload json data", key: "json" },
      { name: "value", description: "value", key: "value" },
    ];

    return (
        <>
          <div className="flex flex-row">
            <div className="font-semibold uppercase ">{t("proper")}</div>
            <div className="ml-auto">
              <Popover className="relative">
                {({ open }) => (
                    <>
                      <Popover.Button
                          onClick={() => setPopOver(true)}
                          className={classNames(
                              open ? "text-gray-900" : "text-gray-500",
                              "group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 bg-white px-4 py-2 mx-4"
                          )}
                      >
                        <span>{t("add_new_property")}</span>
                        <ChevronDownIcon
                            className={classNames(
                                open ? "text-gray-600" : "text-gray-400",
                                "ml-2 h-5 w-5 group-hover:text-gray-500"
                            )}
                            aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                          show={popOver}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                            static
                            className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"
                        >
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-3 py-2 sm:gap-8 sm:p-3">
                              <button
                                  onClick={() => {
                                    console.log("Closing Popover");
                                    setPopOver(false);
                                  }}
                                  className="absolute top-2 right-2 p-2 cursor-pointer"
                              >
                            <span className="text-gray-500 text-lg font-bold">
                              X
                            </span>
                              </button>
                              {datasetTypes.map((item) => (
                                  <div
                                      key={item.name}
                                      onMouseDown={(evt) => {
                                        console.log("Adding section ", item.key);

                                        if (item.key === "json") addJson();
                                        if (item.key === "value") addValue();
                                      }}
                                      className="-m-3 p-3 block rounded-sm cursor-pointer hover:bg-blue-50 transition ease-in-out duration-150"
                                  >
                                    <p className="text-base font-bold text-gray-900">
                                      {item.name}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                      {item.description}
                                    </p>
                                  </div>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                )}
              </Popover>
            </div>
          </div>
          {ActiveProperties()}
        </>
    );
  };

  // Function to delete the currently active property.
  const deleteActiveProperty = () => {
    const hasActiveProperty = activeProperty !== "empty-property";
    if (!hasActiveProperty) return;
    const fullProjectCloned = _.clone(fullProject);

    delete fullProjectCloned["properties"][activeProperty];
    setFullProject(fullProjectCloned);
    setActiveProperty("empty-property");
  };

  // Component to render the main section where project properties are displayed and edited.
  const RenderProperties = () => {
    const hasActiveProperty = activeProperty !== "empty-property";

    // Function to render the currently active property.
    const RenderActiveProperty = () => {
      if (!fullProject["properties"][activeProperty]) return null;

      const { type, value } = fullProject["properties"][activeProperty];

      return (
          <>
            <div className="flex flex-row no-wrap items-center">
              <div className="border-b-2 w-full border-gray-200 font-bold text-lg h-12 px-4 py-2">
                {activeProperty}
              </div>
              <div className="ml-auto">
                <div
                    className="text-red-500 font-2xl cursor-pointer mr-2"
                    title="Delete section"
                    onClick={() => {
                      deleteActiveProperty();
                    }}
                >
                  <i className="fa-solid fa-trash" />
                </div>
              </div>
            </div>

            <div className="h-full">
              <div className="px-4 py-2 ">
                <div className="mt-1">
                  <label className="block text-sm font-medium text-gray-700 font-bold">
                    {t("section_name")}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                        type="text"
                        value={activeProperty}
                        onChange={(event) => {
                          const oldPropertyName = activeProperty;
                          const newPropertyName = event.target.value;
                          let changed = _.clone(fullProject["properties"]);
                          changed = renameKeys(
                              changed,
                              oldPropertyName,
                              newPropertyName
                          );
                          setFullProject({ ...fullProject, properties: changed });
                          setActiveProperty(newPropertyName);
                        }}
                        className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                        placeholder="Section name..."
                    />
                  </div>
                </div>
                <div className="mt-1">
                  <label className="block text-sm font-medium text-gray-700 font-bold">
                    {t("property_type")}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                        type="text"
                        value={type}
                    onChange={(event) => {}}
                        className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                        placeholder="Section name..."
                    />
                  </div>
                </div>
              </div>
              {type === "json" ? RenderActiveReport_json() : null}
              {type === "value" ? RenderActiveReport_value() : null}
            </div>
          </>
      );
    };

    // Function to render JSON property.
    const RenderActiveReport_json = () => {
      if (!hasActiveProperty) return null;
      return (
          <div className="mt-5">
            <label className="ml-4 block text-sm font-medium text-gray-700 font-bold ">
              {t("property_settings")}
            </label>
            <textarea
                className="w-full bg-gray-50 px-4 py-2 mx-4 rounded-lg bg-white"
                value={json}
                onPaste={(evt) => {
                  const content = evt.clipboardData.getData("text/plain");
                  try {
                    const updated = JSON.parse(content);
                    let cloned = _.clone(fullProject["properties"]);
                    cloned[activeProperty]["value"] = updated;
                    setFullProject({ ...fullProject, properties: cloned });
                  } catch (e) {
                    // console.log(e)
                  }
                }}
                onChange={(evt) => {
                  try {
                    const val = evt.target.value;
                    setJson(val);
                  } catch (e) {
                    console.log(e);
                  }
                }}
                style={{ height: "90vh" }}
            />
          </div>
      );
    };

    // Function to render value property.
    const RenderActiveReport_value = () => {
      if (!hasActiveProperty) return null;
      const { type, value } = fullProject["properties"][activeProperty];

      return (
          <>
            <div className="px-4 py-2">
              <div className="mt-5">
                <label className="block text-sm font-medium text-gray-700 font-bold">
                  {t("property_settings")}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                      type="text"
                      value={value}
                      onChange={(event) => {
                        const updated = event.target.value;
                        const clonedProperties = _.clone(fullProject["properties"]);
                        clonedProperties[activeProperty]["value"] = updated;
                        setFullProject({
                          ...fullProject,
                          properties: clonedProperties,
                        });
                      }}
                      className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                      placeholder="Section name..."
                  />
                </div>
              </div>
            </div>
          </>
      );
    };

    // Function to display placeholder text when no property is selected.
    const propertyPlaceHolderText = () => {
      return (
          <div className="text-lg italic text-gray-500 px-8 py-8">
            {t("select_project_property")}
          </div>
      );
    };

    const activeType = fullProject["properties"][activeProperty];

    return (
        <div>
          <div className="flex flex-row border-b-2 border-t-2 border-gray-300">
            <div className="font-bold text-2xl text-base text-black-500 p-4 border-r-2 border-gray-300 w-full md:w-1/3 lg:w-1/4">
              {t("project_properties")}
            </div>
            <div className="ml-auto self-center">
              <button
                  className="px-4 py-2 rounded-md text-white bg-indigo-700"
                  onClick={() => {
                    activeType && activeType.type != "value"
                        ? saveEditedJSON()
                        : saveProjectProperties(true);
                  }}
              >
                {t("save")}
              </button>
            </div>
          </div>
          <div
              className="flex flex-col flex-wrap w-full md:flex-row overflow-hidden"
              key="content-left"
          >
            <div className="w-full md:w-1/3 lg:w-1/4 py-2 border-r-2 border-gray-300">
              {PropertiesLeftSection()}
            </div>
            <div className="w-full md:w-2/3 lg:w-3/4" key="content-right">
              {hasActiveProperty
                  ? RenderActiveProperty()
                  : propertyPlaceHolderText()}
            </div>
          </div>
        </div>
    );
  };

    // Retrieve the 'web_features' object from the 'fullProject' state using safe_get function
    let web_properties = safe_get(
    fullProject,
    "properties.web_features.value",
    {}
    );

    // Retrieve the 'app features' object from the 'fullProject' state using safe_get function
  let app_properties = safe_get(fullProject, "properties.features.value", {});

  const WebFeatures = () => {
    // Function to handle the saving of updated settings
    async function handleSave(settings: object, title: string) {
      // Clone the fullProject object to avoid mutating the original state directly
      const updatedSettings = _.cloneDeep(fullProject);

      // Check if the title corresponds to "Web Features"
      if (title === "Web Features") {
        // Merge the existing web_properties with the new settings
        web_properties = { ...web_properties, ...settings };

        // Update the 'web_features' in the cloned fullProject object
        _.set(updatedSettings, "properties.web_features.value", web_properties);

        // Check if the title corresponds to "App Features"
      } else if (title === "App Features") {
        // Merge the existing app_properties with the new settings
        app_properties = { ...app_properties, ...settings };

        // Update the 'features' in the cloned fullProject object
        _.set(updatedSettings, "properties.features.value", app_properties);
      }

      const payload = {
        id: updatedSettings["id"],
        properties: updatedSettings["properties"],
      };

      setLoading(true);
      await projectStore.editProjectProperties(payload);
      await fetchProjectData();
      setLoading(false);

      // Update the state of fullProject with the modified settings
      setFullProject({ ...updatedSettings });
    }

    return (
        <>
          <div className="mt-3 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-4">
            <Features
                json={web_properties}
                title={"Web Features"}
                onSave={handleSave}
            />

            {/* Features component for handling App Features */}
            <Features
                json={app_properties}
                title={"App Features"}
                onSave={handleSave}
            />
          </div>
        </>
    );
  };

  useEffect(() => {
    const ls_fullProject = JSON.parse(localStorage.getItem("__active_project"));
    console.log(ls_fullProject);
    const _IL = safe_get(
        ls_fullProject,
        "properties.influential_leaders.value",
        {}
    );

    const _PQ = safe_get(
        ls_fullProject,
        "properties.profile_questions.value",
        {}
    );
    if (!_.isEmpty(_PQ)) {
      questionStore.initializeStore(_PQ);
    }
    if (!_.isEmpty(_IL)) {

      influenceLeaderProjectProperty.initializeStore(_IL);
    }
  }, []);

  const ProfileQuestions = () => {
    async function onSave(questions) {
      const updatedValues = _.cloneDeep(fullProject);
      _.set(updatedValues, "properties.profile_questions.value", questions);
      try {
      const payload = {
        id: updatedValues["id"],
        properties: updatedValues["properties"],
      };
      setLoading(true);
      await projectStore.editProjectProperties(payload);
      await fetchProjectData();
      setLoading(false);

      // // Update the state of fullProject with the modified settings
      setFullProject({ ...updatedValues });
      } catch (error) {
        console.log(error);
        alert("Server down...");
      }
    }
    return (
        <>
          <QuestionsWindow onSave={onSave} />
        </>
    );
  };

  const InfluentialLeaders = () =>{
    async function onSave(leaders) {
      const updatedValues = _.cloneDeep(fullProject);
      _.set(updatedValues, "properties.influential_leaders.value",leaders);
      const payload = {
        id: updatedValues["id"],
        properties: updatedValues["properties"],
      };


      setLoading(true);
      await projectStore.editProjectProperties(payload);
      await fetchProjectData();
      setLoading(false);

      // // Update the state of fullProject with the modified settings
      setFullProject({ ...updatedValues });
      console.log(updatedValues);
    }
    return (
        <>
          <InfluentialLeaderWindow onSave={onSave} />
        </>
    )
  }

  if(loading) return <LoadingState />

  // Main return statement of the component, rendering the breadcrumbs, project header, and content.
  return (
      <>
      <BreadCrumbs crumbs={crumbs} />
      <ProjectHeader />
        <h3 className="px-4 title">{t("manage_project")}</h3>

      {/*TODO: Given permission for only Muralidhar (Asked by Srinivas sir)*/}
      {userStore.isRoot() || userStore.user.id === 16003 ?
            <div className="p-4">
          <TabView />
              {fetched && activeTabName === 1 ? SurveyEditorLinks() : null}
              {fetched && activeTabName === 2 ? RenderProperties() : null}
          {fetched && activeTabName === 3 ? <WebFeatures /> : null}
          {fetched && activeTabName === 4 ? <ProfileQuestions /> : null}
          {fetched && leader && activeTabName === 5 ? InfluentialLeaders() : null}
          <ModalDialog />
        </div> : <Typography variant={"h6"} color={"error"} className={"p-4"}>Permission Denied</Typography>
      }
      </>
  );
});
