import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import {RNCheckBoxTree} from "../checkbox-tree";
import {safe_get} from "../../report-visuals";
import {useStore} from "../../helpers";
import {getOption} from "../../features/grievance-management-v2/grievance-v2-hooks";


export const RepresentativeFilter = observer((props: any) => {

    const {projectStore, beneficiaryStore} = useStore();
    const beneficiery_property = projectStore.getProjectProperty("beneficiary", {});
    const representativeOptions = safe_get(beneficiery_property, "more.representatives", []);
    const [checked, setChecked] = useState(safe_get(beneficiaryStore, "filters.rep", []));

    return (
        <RNCheckBoxTree
            data={representativeOptions.map(x=>getOption(x))}
            on_node_selection={(nodes) => {
                setChecked(nodes)
                props.onPersonSelection(nodes)
            }}
            checked_nodes={checked}
        />
    )
})