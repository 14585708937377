import React from "react";
import {DashBoard} from "./dashboard-template";

export const MHOPDashBoard = () => {

    const frame = `<iframe title="MH OP Dashborad" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiOTRhYWQ4NGUtYzE0ZS00MjE4LWE0OTktZWYyMGNhYmIyNGI0IiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`

    return (
        <>
            <DashBoard passCode={"maharashtra"} iframe={frame} />
        </>
    )
}