import React from "react";
import { input_style, label_style } from "./add-beneficiary";

export const excel_col_def = [
    {
        "label": "Name",
        "key": "name"
    }, {
        "label": "Phone Number",
        "key": "phone"
    },
    {
        "label": "Voter ID",
        "key": "voter_id"
    }, {
        "label": "Aadhar ID",
        "key": "aadhar_id"
    }, {
        "label": "Caste",
        "key": "caste"
    }, {
        "label": "Address",
        "key": "address"
    },
    {
        "label": "Request Type",
        "key": "req_type"
    },
    {
        "label": "Status",
        "key": "status"
    },
    {
        "label": "Additional Information",
        "key": "additional_info"
    },
    {
        "label": "Other AC VoterName",
        "key": "oacv_name"
    },
    {
        "label": "Other AC PhoneNumber",
        "key": "oacv_phone"
    },
]

export const roles = [
    {
        label: 'Father',
        value: 'Father'
    },
    {
        label: 'Husband',
        value: 'Husband'
    },
    {
        label: 'Mother',
        value: 'Mother'
    }, {
        label: 'Son',
        value: 'Son'
    }, {
        label: 'Daughter',
        value: 'Daughter'
    }, {
        label: 'Wife',
        value: 'Wife'
    },
    {
        label: 'Sister',
        value: 'Sister'
    },
    {
        label: 'Brother',
        value: 'Brother'
    },
    {
        label: 'Other',
        value: 'Other'
    }
]

export type voterProfileType = {
    age: number,
    house: string,
    gender: string,
    address: string,
    voterid: string,
    aadharid: string,
    dob: string,
    name_eng: string,
    name_reg: string,
    serialno: number,
    voter_roll_page: number,
    relationship_eng: string,
    relationship_reg: string,
    relation_name_eng: string,
    relation_name_reg: string,
    phone: string,
    additional: string,
    profile_photo: string
}

export type voterType = {
    ac: string,
    id: string,
    part: string,
    phone: string,
    famcode: string,
    aadhar: string,
    dob: string,
    age: number,
    profile: voterProfileType
}

export const requestModel = {
    id: "",
    type: "",
    additional: "",
    status: "",
    date: "",
    department: "",
    documents: [],
    richmedia: [],
    assigned_to: {}
}

export const ProfileFormField = ({ register, label, field_name, value, onChangeHandler, required, error, error_msg, type="text" }) => {
    return (
        <>
            <div className="flex flex-row items-center justify-center mb-2">
                <label htmlFor="name" className={label_style}>{label}</label>
                <input
                    {...register(field_name, { required: required })}
                    type={type}
                    name={field_name}
                    id={field_name}
                    value={value}
                    className={input_style}
                    onChange={e => {
                        onChangeHandler(e.target.value, field_name)
                    }}
                />
            </div>
            <ErrorMeaasge val={error[field_name]} error={error_msg} />
        </>
    )
}

export const ErrorMeaasge = ({ val, error }) => {

    return (
        <>
            {val && <div className="flex flex-row items-center px-2 pb-2 justify-end">
                <i className="text-sm fa-solid fa-triangle-exclamation text-red-600 text-sm pr-2" />
                <p className="text-sm text-red-600">{error}</p>
            </div>}
        </>
    )
}
