import React from "react";
import {observer} from "mobx-react-lite";
import {Typography} from "@mui/material";
import {safe_get} from "../../report-visuals";
import {FiTable} from "./fi-table";
import {FiSideOverlay} from "./fi-sideoverlay";
import {useStore} from "../../helpers";

export const schema = [{
    "id": "htakcis24598d",
    "type": "table",
    "title": "Influential People",
    "cols": ["name", "caste", "type", "mobile", "party", "area", "remarks"],
    "data": [
        {
            "id": "egcvnhgfj",
            "name": "Drinking Water Problem",
            "caste": ["Bramhin"],
            "type": ["High"],
            "mobile": "8088535252",
            "party": ["TDP"],
            "area": "Anantapur",
            "remarks": "People of RR Nagar facing sevear water scarcity since mid february."
        },
        {
            "id": "yhnfghgh",
            "name": "Drinking Water Problem",
            "caste": ["Golla"],
            "type": ["High"],
            "mobile": "8088535252",
            "party": ["TDP"],
            "area": "Anantapur",
            "remarks": "People of RR Nagar  facing sevear water scarcity since mid february."
        },
        {
            "id": "ncghfgh",
            "name": "Drinking Water Problem",
            "caste": ["Kamma"],
            "type": ["High"],
            "mobile": "8088535252",
            "party": ["TDP"],
            "area": "Anantapur",
            "remarks": "People of RR Nagar  facing sevear water scarcity since mid february."
        }
    ],
    "fields": [
        {
            "field_type": "text",
            "field_name": "name",
            "mandatory": true
        },
        {
            "field_type": "dropdown",
            "field_name": "caste",
            "mandatory": true,
            "options": ["Bramhin", "Kshthrya", "Vaishya", "Golla", "Kamma"]
        },
        {
            "field_type": "dropdown",
            "field_name": "type",
            "mandatory": true,
            "options": ["High", "Kshthrya", "Vaishya", "Golla", "Kamma"]
        },
        {
            "field_type": "text",
            "field_name": "mobile",
            "mandatory": true
        },
        {
            "field_type": "dropdown",
            "field_name": "party",
            "mandatory": true,
            "options": ["BJP", "INC", "JDS","TDP", "Others"]
        },
        {
            "field_type": "text",
            "field_name": "area",
            "mandatory": true
        },
        {
            "field_type": "text-area",
            "field_name": "remarks",
            "mandatory": false
        }
    ]
},
    {
        "id": "hta58fdis2458d",
        "type": "table",
        "title": "Prominent Issues",
        "cols": ["issue", "area", "impact", "remarks"],
        "data": [{
            "id": "dfsdf",
            "issue": "Drinking Water Problem",
            "area": "RR Nagar",
            "impact": "High",
            "remarks": "People of RR Nagar  facing sevear water scarcity since mid february."
        }],
        "fields": [
            {
                "field_type": "dropdown",
                "field_name": "issue",
                "mandatory": true,
                "options": ["Drainage", "Drinking Water", "Sanitaisation", "Infrastructure", "Electicity"]
            },
            {
                "field_type": "text",
                "field_name": "area",
                "mandatory": true
            },
            {
                "field_type": "dropdown",
                "field_name": "impact",
                "mandatory": true,
                "options": ["High", "Medium", "Low"]
            },
            {
                "field_type": "text-area",
                "field_name": "remarks",
                "mandatory": false
            }
        ]
    }
]

export const FieldIntelligenceLanding = observer((props: any) => {

    const rootStore = useStore();
    const {fieldIntelligenceStore} = rootStore;
    const {addNewModal, activeNode} = fieldIntelligenceStore;

    return (
        <div className={"overflow-x-hidden"}>
            <Typography className={'p-4'} variant={"h4"} color={'primary'}>Welcome to Field Intelligence</Typography>
            <div className="flex flex-col gap-8 px-4">
                {
                    schema.map(visual => {
                        return (
                            <div key={visual.id}>
                                {visual["type"] === "table" ? <FiTable key={visual["id"]} onAdd={(x) => console.log(x)}
                                                                       table_data={visual}/> : null}
                            </div>
                        )
                    })
                }
            </div>
            {<FiSideOverlay
                onClose={() => fieldIntelligenceStore.update_FiStoreProp("addNewModal", false)}
                show={addNewModal}
                data={activeNode}
                title={`Add New ${safe_get(activeNode, "title", "")}`}
            />}
        </div>
    )
})