import { observer } from "mobx-react-lite";
import { SubHeading, useFileUpload, useStore } from "../../../helpers";
import { FileInputField } from "../../../components/form-components";
import { UploadButton } from "../../../components/cm-components";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import { VortexSpinner } from "../../digital-repository";
import { toJS } from "mobx";

export const AttachmentsAndMedia = observer(props => {
	const { flexiStore, grievanceStoreV2 } = useStore();
	const { richMedia: storeRichMedia, attachments: storeAttachments, editNewGrievanceModal } = grievanceStoreV2;
	const { projectid } = useParams();
	const { uploadFileToS3, isUploading } = useFileUpload();
	const { watch } = useFormContext();
	const [attachments, media] = watch(["attachments", "media"]);

	const ATTACHMENT_PATH = `project-${projectid}/grievance/${flexiStore?.current_fsd?.id}/docs`;
	const MEDIA_PATH = `project-${projectid}/grievance/${flexiStore?.current_fsd?.id}/media`;

	const deleteAttachment = file => {
		const filtered = storeAttachments.filter(media => media.path !== file.path);
		grievanceStoreV2.updateGrievanceMgmtV2Prop("attachments", filtered);
	};

	const deleteMediaFile = file => {
		const filtered = storeRichMedia.filter(media => media.path !== file.path);
		grievanceStoreV2.updateGrievanceMgmtV2Prop("richMedia", filtered);
	};

	const updateAttachments = (file, key) => {
		const copy = [];
		copy.push({
			path: key,
			type: file["type"],
			name: file["name"],
		});
		grievanceStoreV2.updateGrievanceMgmtV2Prop("attachments", copy);
	};

	const updateRichMedia = (file, key) => {
		const copy = [];
		copy.push({
			path: key,
			type: file["type"],
			name: file["name"],
		});
		grievanceStoreV2.updateGrievanceMgmtV2Prop("richMedia", copy);
	};

	const attachmentUploadHandler = async (attachments, type) => {
		if (!attachments || attachments.length === 0) {
			console.error("No attachments found");
			return;
		}
		Array.from(attachments).forEach((fileToUpload, index) => {
			setTimeout(async () => {
				const uploadedData = await uploadFileToS3(type === "attachment" ? ATTACHMENT_PATH : MEDIA_PATH, fileToUpload, "urn-assets");

				if (uploadedData?.status === 204) {
					console.log(uploadedData);
					console.log(type);
					type === "attachment" ? updateAttachments(fileToUpload, uploadedData.key) : updateRichMedia(fileToUpload, uploadedData.key);
				}
			}, 1000 * index);
		});
	};

	const onAttachmentUpload = () => {
		attachmentUploadHandler(attachments, "attachment")
			.then(res => console.log(res))
			.catch(e => console.log(e));
	};

	const onMediaUpload = () => {
		attachmentUploadHandler(media, "media")
			.then(res => console.log(res))
			.catch(e => console.log(e));
	};

	return (
		<div className={"flex flex-col gap-y-2 my-3"}>
			<div className={"flex flex-row justify-between items-center"}>
				<SubHeading title={"Attachements"} />
				<VortexSpinner visible={isUploading} />
			</div>
			<div className={"flex flex-col gap-y-2"}>
				<div className="flex flex-row flex-wrap justify-between align-middle">
					<FileInputField name={"attachments"} accept={".pdf, .doc, .docx, .txt, .csv, .xslx"} multiple={true} />
					<UploadButton uploadHandler={onAttachmentUpload} />
				</div>
				{storeAttachments?.map(item => {
					let file_name = item.path?.split("/")?.at(-1);
					return (
						<div className="m-2 p-2 bg-gray-200 rounded-lg max-w-max">
							<i className="fa-solid fa-file p-2" />
							{file_name}
							<i className="ml-4 fa-solid fa-trash p-2 text-red-500 cursor-pointer" onClick={() => deleteAttachment(item)} />
						</div>
					);
				})}
			</div>
			<div className={"flex flex-col gap-y-2"}>
				<SubHeading title={"Media"} />
				<div className="flex flex-row flex-wrap justify-between align-middle">
					<FileInputField name={"media"} accept={"image/* ,video/*"} multiple={true} />
					<UploadButton uploadHandler={onMediaUpload} />
				</div>
				{storeRichMedia?.map(item => {
					let file_name = item.path?.split("/")?.at(-1);
					return (
						<div className="m-2 p-2 bg-gray-200 rounded-lg max-w-max">
							<i className="fa-solid fa-file p-2" />
							{file_name}
							<i className="ml-4 fa-solid fa-trash p-2 text-red-500 cursor-pointer" onClick={() => deleteMediaFile(item)} />
						</div>
					);
				})}
			</div>
		</div>
	);
});
