import {observer} from "mobx-react-lite";
import {RNSearchBar} from "../influential-leaders";
import {Button} from "@mui/material";
import {FilterButton} from "../../components/cm-components";
import React from "react";
import {useStore} from "../../helpers";

export const GrievanceHomeHeader = observer((props:any) => {

    const {grievanceStoreV2} = useStore();

    const handleSearchBarChange = (value) => {
        grievanceStoreV2.updateGrievanceMgmtV2Prop("searchQuery", value)
    };

    return (
        <div className="flex flex-col justify-center items-center rounded-md mx-auto max-w-max my-4 ">
            <div className="flex flex-row flex-wrap justify-between gap-x-4">
                <h2 className="font-bold text-3xl text-indigo-500 pr-14 text-center">
                    Grievance Management
                </h2>
                <RNSearchBar onSearch={handleSearchBarChange}/>
                <Button
                    variant={"contained"}
                    startIcon={<i className="fas fa-plus-circle"/>}
                    onClick={() => grievanceStoreV2.updateGrievanceMgmtV2Prop("addNewGrievanceModal", true)}
                >
                    {"new_grievance"}
                </Button>
                <FilterButton
                    filterOnClickHandler={() => grievanceStoreV2.updateGrievanceMgmtV2Prop("showFiltersModal", true)}
                />
            </div>
        </div>
    )
})