import {RootStore} from "../../../stores/root";
import {FlexiStoreData, FlexiStore} from "../../../stores/fs";
import {safe_get} from "../../../report-visuals";

export const FSD_S3_BUCKET = 'urn-fsd'

export class ACBankDAO {
    rs: RootStore
    fs: FlexiStore

    fsd: FlexiStoreData

    constructor(rootstore) {
        this.rs = rootstore
        this.fs = rootstore.flexiStore
        this.fsd = this.fs.current_fsd
    }

    get_summary_field(field_name, default_value: any = '') {
        if(this.fsd){
            return safe_get(this.fsd.summary, field_name, default_value)
        } else return 0

    }

    async load_election_results() {
        const key = "election_results"
        const path = safe_get(this.fsd.r1, key, "")
        await this.fs.load_s3_json_resource(FSD_S3_BUCKET, path, key)
    }

    async get_primary_map_image() {
        const path = safe_get(this.fsd.r1, 'maps.acmap_highlight', "")
        return await this.rs.get_s3_url(FSD_S3_BUCKET, path)
    }

    async get_current_map(map_key) {
        console.log(map_key)
        const key = "maps";
        return await this.fs.load_s3_json_resource(FSD_S3_BUCKET, this.fsd.r1["maps"][map_key], key)
    }

    async load_caste_details() {
        const key = "caste"
        const path = safe_get(this.fsd.r1, "caste.src0", "")
        await this.fs.load_s3_json_resource(FSD_S3_BUCKET, path, key)
    }

    get_timeline_payload() {
        const fsdKey = safe_get(this.fsd, "key", "");
        const ac = this.get_summary_field('acno', 0)
        const count = 10000;
        return {
            state: fsdKey.slice(0, 2),
            acno: ac,
            offset: 0,
            count: count,
            query: this.rs.flexiStore.timeline_search_query
        }
    }
}