import {Route, Routes} from "react-router-dom";
import {BeneficiaryManagemet, BeneficiaryView} from "../../features";
import BeneficiarySettings from "../../features/beneficiary-management/beneficiary-settings";
import React from "react";


export const BeneficiaryRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<BeneficiaryManagemet/>}/>
            <Route path="/:beneficid/view" element={<BeneficiaryView/>}/>
            <Route path="/admin" element={<BeneficiarySettings/>}/>
        </Routes>
    )
}