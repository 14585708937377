import React from "react";
import {useStore} from "../../helpers";
import {observer} from "mobx-react-lite";
import {VoterCard} from "../manage-voters";

export const VoterListWithRadioButton = observer((props:any) => {

    const {voterListStore} = useStore()
    const {Voterlist, activeVoter} = voterListStore;

    return(
        <>
            {Voterlist && Voterlist.length > 0 && Voterlist.map((data, index) => {
                return (
                    <div key={data.id} className="relative mb-2">
                        <input
                            type="radio"
                            checked={activeVoter && activeVoter["id"] === data["id"]}
                            onChange={async() => {
                                await voterListStore.update_active_voter(data)
                                props.onVoterSelection(data)
                            }}
                            className={"absolute top-8 right-4 p-2 w-8 h-8"}
                        />
                        <VoterCard voter={data}/>
                    </div>
                );
            })}
        </>
    )
})