

export const formatName = (name) => {
    let value_field = name;

    // Replace underscores with spaces
    value_field = value_field.replace(/_/g, " ");

    // Capitalize the first letter of each word
    value_field = value_field.replace(/\b\w/g, (match) => match.toUpperCase());

    return value_field;
}