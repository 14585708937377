import React, { useMemo } from 'react';
import { observer } from "mobx-react-lite";
import { useStore, error_toast } from "../../helpers";
import { BeneficiaryView } from "./beneficiary-view";
import { useTranslation } from "react-i18next";
import RenderRequestStatus from './render-beneficiary-requests';
import { useParams } from "react-router";
import { CMTable, LoadingState } from "../../components";
import {safe_get} from "../../report-visuals";
import {DataNotAvailable} from "../../flexistore/renderkeys/mh-2024-field";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';


export const image_path = "/images/benefic.jpg"

export const BeneficiariesTable = observer((props: any) => {

    const { beneficiaryStore, flexiStore } = useStore();
    const { fsd_search_page_size, fsd_search_current_page } = flexiStore;
    const { loading } = flexiStore;
    const { beneficiaries } = beneficiaryStore;
    const { t } = useTranslation("beneficiary");
    const { projectid } = useParams();

    const columns = useMemo(() => [
        {
            Header: t("tracking_id"),
            accessor: 'id',
            Cell: props => {
                return <p>P{projectid}B{props.cell.value}</p>
            }
        },
        {
            Header: t("date"),
            accessor: 'created'
        },
        {
            Header: t("name"),
            accessor: 'summary.profile.name',

            Cell: ({ cell, row }) => {
                const value = cell.value;
                const isOutsider = row.original.summary.profile.is_outsider;
                return (
                    <div className="flex flex-row items-center">
                        <div>{value}</div>
                        {isOutsider && <i className="fas fa-star text-yellow-300 h-8 w-8 mx-2 py-1.5" />}
                    </div>
                );
            },

        },
        {
            Header: t("phone_number"),
            accessor: 'summary.profile.phone',
        },

        {
            Header: t("voter_iD"),
            accessor: 'summary.profile.voter_id',
        },

        {
            Header: t("caste"),
            accessor: 'summary.profile.caste',
        },
        {
            Header: t("address"),
            accessor: 'summary.profile.address',
        },
        {
            id: "requests",
            Header: t("request_type"),
            accessor: 'others.requests',
            Cell: props => {
                const values = props.cell.value;
                const request_types = values.map(req => `${req.type} - ${req.status} - ${req.additional}`);
                return (
                    <>
                        {request_types.map((r, i) => {
                            const split = r.split("-");
                            return (
                                <div key={i} className='flex flex-row justify-between items-center my-2'>
                                    <div className='flex flex-col flex-1'>
                                        <p className="font-bold">{split[0]}</p>
                                        <p>{split[2]}</p>
                                    </div>
                                    <div className='flex'>
                                        <RenderRequestStatus feature={"beneficiary"} status={split[1].trim()} />
                                    </div>
                                </div>
                            );
                        })}
                    </>
                );
            }

        },
        {
            id: "additional_information",
            Header: t("concerned_office_person"),
            accessor: 'others.requests',
            Cell: props => {
                const values = props.cell.value;
                const assigned_to = values.map(req => req.assigned_to);

                return (
                    <>
                        {assigned_to.map(r => {
                            const name = safe_get(r, "name", "")
                            return <>
                                <span>{name}</span>
                                <br />
                            </>
                        })}
                    </>
                )
            }
        }
    ], [beneficiaries, fsd_search_page_size, fsd_search_current_page])

    const row_click_handler = async (beneficiary) => {
        flexiStore.set_loading(true)
        try {
            const res = await flexiStore.get_fsdata(beneficiary.id);
            if (res.status === 200) {
                beneficiaryStore.update_show_benefic(true);
                beneficiaryStore.set_active_beneficiary(res.data);
            } else error_toast();
            flexiStore.set_loading(false);
        } catch (e) {
            flexiStore.set_loading(false);
            error_toast();
        }
    }

    return (
        <>
            {loading && <LoadingState />}
            {
                beneficiaries.length > 0 ?
                <CMTable
                    data={beneficiaries}
                    columns={columns}
                    pagesize={flexiStore.fsd_search_page_size}
                    onRowClick={row_click_handler}
                    hideFooter={true}
                    highlightRow={true}
                /> : <DataNotAvailable />
            }
        </>
    )
})

export const TableLoadingSkeleton = () =>{
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Skeleton width="100%" />
                        </TableCell>
                        <TableCell align="right">
                            <Skeleton width="100%" />
                        </TableCell>
                        <TableCell align="right">
                            <Skeleton width="100%" />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[1, 2, 3, 4, 5].map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                <Skeleton width="100%" />
                            </TableCell>
                            <TableCell align="right">
                                <Skeleton width="100%" />
                            </TableCell>
                            <TableCell align="right">
                                <Skeleton width="100%" />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
