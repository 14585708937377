import React, {useState} from "react";
import LeaderHeader from "./LeaderHeader";
import {toJS} from "mobx";
import {observer} from "mobx-react-lite";
import LeaderParty from "./LeaderParty";
import {useStore} from "../../../helpers";

interface InfluentialLeaderWindowProps {
    onSave: (leaders: object) => void;
}

const InfluentialLeaderWindow: React.FC<InfluentialLeaderWindowProps> = observer(({onSave}) => {
        const rootStore = useStore();
        const {influenceLeaderProjectProperty} = rootStore;

        // Retrieve activeTab from the store

        // Function to handle save action
        function handleSave() {
            onSave(toJS(influenceLeaderProjectProperty.getLeaders));
        }



        return (
            <div className="flex flex-wrap h-screen">

                    <div className="w-full sm:w-[30%]">
                        <LeaderParty/>
                    </div>
                    <div className="sm:w-[70%]">
                        <LeaderHeader handleSave={handleSave}/>
                    </div>

            </div>
        );
    }
);

export default InfluentialLeaderWindow;