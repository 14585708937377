import React, {useState, useEffect, useReducer} from "react";
import {useStore, error_toast} from "../../helpers";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {clone} from "lodash"
import {Button, Pagination, Stack,} from "@mui/material";
import {FilterButton, LoadingState} from "../../components";
import {RNSearchBar} from "../influential-leaders";
import {DataNotAvailable} from "../../flexistore/renderkeys/mh-2024-field";
import {useWindowSize} from "react-use";
import {safe_get} from "../../report-visuals";
import { CSVLink } from "react-csv"
import {useQuery} from "@tanstack/react-query";

const initialState = {
    loading: true,
    voterList: [],
    totalVoters: 0,
    error: ""
}

const reducer = (state, action) => {
    switch (action.type) {
        case "START" :
            return {
                ...state,
                loading: true,
                error: ''
            }
        case "SUCCESS":
            return {
                loading: false,
                voterList: action.payload.results,
                totalVoters: action.payload.total,
                error: ""
            }
        case "FAILURE":
            return {
                loading: false,
                voterList: [],
                totalVoters: 0,
                error: "Something went wrong"
            }
        default:
            return state
    }
}

//TODO: This compoenent is responsible for only to fetch voter-list based on search criteria and filters.
//TODO: It will not accept any props

export const SearchVoterList = observer((props: any) => {

    //TODO: useReducer for grouping related state variables in a single object, and to reduce the state transitions
    const [state, dispatch] = useReducer(reducer, initialState);
    const {projectid: pid} = useParams();
    const {voterListStore, userStore, projectStore} = useStore();
    const {voter_filters} = voterListStore;
    const {relation, projectid, page, house, genders, df, booths, age, name, serialno, voterid, offset, count} = voter_filters;
    const {t} = useTranslation(["voter_list", "manage_voters"]);
    const clone_voter_filters = clone(voterListStore.voter_filters);
    const {width} = useWindowSize();
    const isMobile = width <= 766;
    const end = offset + 1 * count;
    const isAllowed = userStore.isClient() || userStore.isRoot();
    const voterRecordsConfig = projectStore.getProjectProperty("voterrecords_v2", {});
    const downloadColumns = safe_get(voterRecordsConfig, "download_voter_data_fields", [])

    const [pageNumber, setPageNumber] = useState(1);
    const totalPages = Math.ceil(state.totalVoters / 100);

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
        clone_voter_filters["offset"] = (value - 1) * count
        await voterListStore.update_filters(clone_voter_filters);
    };

    /*TODO: Passing individual filter to the useEffect to cause re-render
    * TODO: It will not re-render if you just pass voter_filters
    * */
    useEffect(() => {
        getVoterList();
    }, [relation, projectid, page, house, genders, df, booths, age, name, serialno, voterid, offset, pid, count]);


    const {} = useQuery({
        queryKey: ["voters-data",relation, projectid, page, house, genders, df, booths, age, name, serialno, voterid, offset, pid, count ],
        queryFn: () => {}
    })

    const getVoterList = async () => {

        dispatch({type: 'START'});
        clone_voter_filters["projectid"] = parseInt(pid)
        await voterListStore.update_filters(clone_voter_filters);

        try {
            const res = await voterListStore.getVoterSearch();
            userStore.setUserDetailsMode(false);
            if (res.status !== 200) {
                error_toast();
                dispatch({type: "FAILURE"})
            } else {
                dispatch({type: "SUCCESS", payload: res.data})
            }
        } catch (e) {
            dispatch({type: "FAILURE"})
        }
    };

    const handleSearchBarChange = async (value) => {
        clone_voter_filters["name"] = value
        await voterListStore.update_filters(clone_voter_filters);
    };

    const getUsers = async(event, done) => {
        await voterListStore.update_filters({...voter_filters, count: state.totalVoters});
        done(!state.loading);
    }

    return (
        <>
            {state["loading"] && <LoadingState/>}
            <div className={"flex flex-col sm:flex-row items-center justify-between"}>
                <h2 className="font-bold flex flex-1 text-2xl text-indigo-500 w-full sm:flex-1">Voter Management</h2>
                <div
                    className="flex flex-row flex-1 items-center gap-x-4 justify-between sm:justify-end sm:flex-wrap w-full">
                    <RNSearchBar onSearch={handleSearchBarChange}/>
                    <div className="flex flex-row items-center gap-x-4">
                        <FilterButton
                            filterOnClickHandler={() => {
                                voterListStore.setShowVoterlist(false);
                                voterListStore.setFilterStatus(true);
                            }}
                        />
                        <Button
                            variant={"contained"}
                            onClick={async () => {
                                await voterListStore.reset_filters(parseInt(pid));
                            }}
                            endIcon={<i className="fas fa-retweet"/>}
                            color={"warning"}
                        >
                            {t("reset_search")}
                        </Button>
                        {isAllowed &&
                            <CSVLink
                                data={voterListStore.Voterlist}
                                headers={downloadColumns}
                                filename={"Voter List"}
                                asyncOnClick={true}
                                onClick={getUsers}
                            >
                                <i className="text-blue-600 mx-4">Download</i>
                            </CSVLink>
                        // <DownloadAsExcelSheet data={voterListStore.Voterlist} cols={downloadColumns} name={"Voter List"}/>
                        }
                    </div>
                </div>
            </div>

            {totalPages ? <Stack spacing={2} className='w-full' direction={"row"} alignItems={"center"}>
                <div className="font-bold text-gray-500">Page: {pageNumber}</div>
                <Pagination
                    boundaryCount={isMobile ? 1 : 5}
                    color={"primary"}
                    size={"medium"}
                    variant={"outlined"}
                    count={totalPages}
                    page={pageNumber}
                    onChange={handlePageChange}
                />
            </Stack> : null}
            {state["totalVoters"] > 0 ?
                <div className="font-bold text-gray-500">
                    Showing {offset + 1} to {end > state.totalVoters ? count : end} of {state.totalVoters.toLocaleString('en-IN')} voters
                </div> :
                <DataNotAvailable/>
            }
        </>
    );
})
