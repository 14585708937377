import React from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers";



export const VisualHeader = ({title}) => {
    return <h3 className="text-base text-lg font-bold leading-6 fp-text">{title}</h3>
}

export const C3Header = ({title, start_date, end_date}) => {
    return (
        <>
            <div className="py-2 text-2xl text-[#009EA6] font-bold text-center">{title}</div>
            <p className="text-sm py-2 italic code">** Click on the filter and choose a date from the calendar.</p>
            {start_date !== "" && <p className={"fp-text"}>Showing the Results from: <strong>{start_date}</strong> to <strong>{end_date}</strong> </p>}
        </>
    )
}


export const C3projectWorks = observer((props:any) => {
    const rootStore = useStore();
    const {c3Store} = rootStore;
    const {date_filetrs:{start_date, end_date}} = c3Store;

    return(
        <>
            <C3Header title={"Project Works Insights: A Comprehensive Summary Dashboard"} start_date={start_date} end_date={end_date} />

            <div>
                <VisualHeader title={"Summary"}/>
            </div>
        </>
    )
})