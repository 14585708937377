import React from "react";
import {Route, Routes} from "react-router-dom";
import {FSDListPage, FSDViewPage, FSTListPage} from "../flexistore/pages-root";
import {CasteSummary} from "../flexistore/renderkeys/ac-bank/caste-details";
import {
    ACBankConsituencyMap,
    ACBankElectionResults,
    ACBankInfluentialLeaders,
    ACBankTimeLine, AddTimeLine, TimeLineView
} from "../flexistore/renderkeys/ac-bank";
import {ACBankPermissions} from "../flexistore/renderkeys/ac-bank/permissions/ac-bank-permissions";


export const DataBankRoutes = () => {
    return (
        <Routes>
            <Route path="/" index element={<FSTListPage/>}/>
            <Route path="/repo/:fstid/:key" element={<FSDListPage/>}/>
            <Route path="/repo/:fstid/:key/:id" element={<FSDViewPage/>}>
                <Route path="caste_summary" element={<CasteSummary/>}/>
                <Route path="constituency_map" element={<ACBankConsituencyMap/>}/>
                <Route path="past_results" element={<ACBankElectionResults/>}/>
                <Route path="influential_people" element={<ACBankInfluentialLeaders/>}/>
                <Route path="timeline" element={<ACBankTimeLine/>}/>
                <Route path="permissions" element={<ACBankPermissions/>}/>
            </Route>
            <Route path="/repo/:key/permissions" element={<ACBankPermissions/>}/>
            <Route path="/repo/:key/:id/timeline/new" element={<AddTimeLine/>}/>
            <Route path="/repo/:key/:id/timeline/view" element={<TimeLineView/>}/>
        </Routes>
    )
}