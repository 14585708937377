import {action, makeAutoObservable, toJS} from "mobx";
import {clone} from "lodash";
import {makeid_alpha} from "../helpers";
import TrieSearch from 'trie-search';
import moment from "moment";
import {defaultC3ActivePersonObj} from "../features";

export class C3Store {

    rootStore: any;

    party_workers_master = []
    party_workers_filtered_by_search = []
    party_workers_filtered_by_booth = []
    party_worker_active: object = defaultC3ActivePersonObj
    party_worker_edit_overlay: boolean = false
    party_worker_overlay_mode: string = 'add'

    hierarchyData: object = {}
    boothDataForDropdown = []
    selected_booths = []
    selected_office_persons = []
    start_date=""
    end_date = ""

    c3FiltersModal: boolean = false;

    demographics_electoral = {}

    date_filetrs = {
        start_date : "",
        end_date: ""
    }

    query = ''
    trie = new TrieSearch(['name', 'phone', 'comments', 'profession']);

    constructor(rootStore: any) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async set_party_workers_master(data) {
        this.party_workers_master = data
        const response = await this.rootStore.api.post("/c3/facet/partyworkers_update", {
            'projectid': this.rootStore.projectStore.activeProject["id"],
            'payload': this.party_workers_master,
        });
        //this.party_workers_filtered_by_search = data
        //this.party_worker_trie_update()
    }

    @action
    set_party_worker_active(obj) {
        this.party_worker_active = obj
    }

    @action
    edit_save_party_worker_active() {
        const tempData = clone(this.party_workers_master)
        tempData.map((obj) => {
            if (obj['id'] === this.party_worker_active['id']) {
                Object.keys(obj).map((attr) => {
                    obj[attr] = this.party_worker_active[attr]
                })
            }
        })
        this.set_party_workers_master(clone(tempData))
    }

    @action
    toggle_party_worker_edit_overlay() {
        this.party_worker_edit_overlay = !this.party_worker_edit_overlay
    }

    @action
    update_party_worker_overlay_mode(addOrEdit: string) {
        this.party_worker_overlay_mode = addOrEdit
    }

    @action
    add_commit_party_worker_active() {
        this.party_worker_active['id'] = makeid_alpha(4)
        this.set_party_workers_master([...this.party_workers_master, {...this.party_worker_active}])
    }

    @action
    setHierarchyData(data) {
        this.hierarchyData = data
    }

    @action
    setBoothDataForDropdown(data) {
        this.boothDataForDropdown = data
    }

    @action
    update_selected_booths(data) {
        this.selected_booths = data
    }

    @action
    update_selected_office_persons(data) {
        this.selected_office_persons= data
    }

    @action
    update_date_filters(start_date, end_date = moment().format("YYYY-MM-DD")){
        this.date_filetrs.start_date = start_date;
        this.date_filetrs.end_date = end_date;
    }

    @action
    async get_electoral_demographics() {
        const response = await this.rootStore.api.post("/c3/facet/demographics", {
            'projectid': this.rootStore.projectStore.activeProject["id"],
            'booths': this.selected_booths,
        });
        this.demographics_electoral = response.data;
        return  response;
    }

    @action
    async get_party_workers() {
        const response = await this.rootStore.api.post("/c3/facet/partyworkers", {
            'projectid': this.rootStore.projectStore.activeProject["id"]
        });
        this.party_workers_master = response.data
    }

    @action
    async party_workers_refresh() {
        this.filter_master_data_on_booths_selected()

        //this.party_workers_master = response.data
        //this.party_workers_filtered_by_search = response.data
        this.party_worker_trie_update()
    }

    @action
    filter_master_data_on_booths_selected() {
        if (this.party_workers_master.length > 0 && this.selected_booths.length > 0) {
            this.party_workers_filtered_by_booth = this.party_workers_master.filter((obj) => this.selected_booths.includes(obj.booth))
        } else {
            this.party_workers_filtered_by_booth = this.party_workers_master
        }
    }

    @action
    setQuery(this, query: string) {
        this.query = query
    }

    @action
    setFilteredPartyWorkers(this, filteredData) {
        this.filteredPartyWorkers = filteredData
    }

    @action
    party_worker_trie_update() {
        this.trie = new TrieSearch(['name', 'phone', 'comments', 'profession'])
        //this.trie.addAll([])
        this.trie.addAll(this.party_workers_filtered_by_booth)
        this.party_workers_search_update()
    }

    @action
    party_workers_search_update() {
        if (this.query == null || this.query.trim() == '') {
            this.party_workers_filtered_by_search = this.party_workers_filtered_by_booth
        } else {
            this.party_workers_filtered_by_search = this.trie.search(this.query)
        }
        console.log('party_workers_filtered_by_search', toJS(this.party_workers_filtered_by_search))
    }

    async get_beneficiries_summary(payload) {
        return await this.rootStore.api.post("fsdutils/beneficiarysummary", payload)
    }
    async get_grieviance_summary(payload) {
        return await this.rootStore.api.post("fsdutils/grievancesummary", payload)
    }

    @action
    update_start_date(date){
        this.start_date = date;
    }

    @action
    update_end_date(date){
        this.end_date = date;
    }

    update_c3FilterModal(val){
        this.c3FiltersModal = val;
    }


}