import { action, makeAutoObservable } from "mobx";
import {beneficiaryDefaultValues, BeneficiaryType} from "../../features/beneficiary-management-v2/beneficiary-v2-types";

export class BeneficiaryStoreV2 {
  rootStore: any;
  add_request_modal: boolean = false;
  add_beneficiary_modal: boolean = false;
  beneficiary_edit_mode: boolean = false;
  activeBeneficiary: BeneficiaryType = beneficiaryDefaultValues;
  requests: Array<any> = [];
  familyMembers: Array<any> = [];

  constructor(rootStore: any) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  add_beneficiary_store_request_v2 = async (payload) => {
    console.log(payload);
    return await this.rootStore.api.post("beneficiary/request/", payload);
  };


  @action
  createBeneficiary = async (payload) => {
    return await this.rootStore.api.post("fs/fsdnew", payload);
  };

  // open and close tailwind modal box state
  @action
  update_add_request_modal(status) {
    this.add_request_modal = status;
  }

  @action
  update_add_beneficiary_modal(status) {
    this.add_beneficiary_modal = status;
  }

  @action
  update_edit_beneficiary_mode(status) {
    this.beneficiary_edit_mode = status;
  }

  @action
  update_requests(val) {
    this.requests = val;
  }

  @action
  update_family_members(val) {
    this.familyMembers = val;
  }

}
