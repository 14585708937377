import React, { useEffect, useMemo } from "react";
import { useStore } from "../../helpers";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addEditGrievanceFormSchema } from "./addEditGrievanceFormSchema";
import { AddEngineer } from "./add-engineer";
import { FormButtons, SideOverlay } from "../../components";
import { observer } from "mobx-react-lite";
import {
    useAddNewComplaint,
    useCreateGrievance,
    useGrievanceOptions,
    useCookGrievancePayload,
    useUpdateGrievance,
} from "./grievance-v2-hooks";
import {
    AttachmentsAndMedia,
    ConcernedPerson,
    GrievanceSummary,
    ReportedPerson,
} from "./grievance-add";
import { safe_get } from "../../report-visuals";
import { grievanceSummaryDefaultValues } from "./grivance-models";
import {useParams} from "react-router";

//---------------------------------------------------------------------------------
export const AddEditGrievanceV2 = observer((props: any) => {
    //--------------------------------------DECLARATIONS-------------------------------------------
    const { grievanceStore, voterListStore, grievanceStoreV2, flexiStore: { current_fsd } } = useStore();
    const { addNewGrievanceModal, activeGrievance } = grievanceStoreV2;
    const {fsdid} = useParams();
    //--------------------------------DATA INITIALIZATION-------------------------------------------------

    const grievanceOptions = useGrievanceOptions();
    const { addPayloadGetter, editPayloadGetter } = useCookGrievancePayload();
    const { mutate: addNewComplaint, isLoading: isAddingNewComplaint } = useAddNewComplaint();
    const { mutate: createGrievance, isLoading, isError } = useCreateGrievance();
    const {
        mutate: updateGrievance,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
    } = useUpdateGrievance();

    const methods = useForm({
        // @ts-ignore
        resolver: yupResolver(addEditGrievanceFormSchema),
        defaultValues: grievanceSummaryDefaultValues,
    });

    useEffect(() => {
        //TODO: current fsd is nothing but active grievance

        const villages = safe_get(current_fsd?.summary, "piv_level_2", [])?.map(village => village.value)

        const def = {
            ...current_fsd?.summary,
            assigned: safe_get(current_fsd?.summary, "assigned.name", ""),
            assigned_office_person: safe_get(current_fsd?.summary, "assigned_office_person.name", ""),
            piv_level_2: villages || [],
            attachments: safe_get(current_fsd?.r1, "attachments", []),
            media: safe_get(current_fsd?.r1, "richmedia", []),
        }
        grievanceStoreV2.updateGrievanceMgmtV2Prop("attachments", safe_get(current_fsd, "r1.attachments", []))
        grievanceStoreV2.updateGrievanceMgmtV2Prop("richMedia", safe_get(current_fsd, "r1.richmedia", []))
        methods.reset(fsdid ? def : { ...grievanceSummaryDefaultValues });
    }, [current_fsd])

    //---------------------------------STATES------------------------------------------------

    const closeModal = () => {
        grievanceStoreV2.updateGrievanceMgmtV2Prop("addNewGrievanceModal", false);
        if(methods.formState.isSubmitted){
            methods.reset({ ...grievanceSummaryDefaultValues });
        }
    }

    useEffect(() => {
        //TODO: Resetting store variables, when this component un-mounting.
        return () => {
            voterListStore.update_active_voter({});
            grievanceStore.update_grievance_store_property("selected_voters", []);
        }
    }, [])

    //-------------------------------HOOK FORM METHODS--------------------------------------------------
    const onSubmit = async (data) => {
        // //TODO: If user adds new complaint to the list, then send this to server and continue with usual flow.
        if (data?.specific_complaint) {
            const payload = {
                complaint: data?.specific_complaint,
                department: data?.department,
            };
            await addNewComplaint(payload);
        }

        const fsdid = safe_get(current_fsd, "id", null);

        if (fsdid) {
            const formData = await addPayloadGetter(data);
            if (formData) {
                const { r1Payload, summaryPayload } = editPayloadGetter(
                    formData.properties,
                    activeGrievance
                );
                updateGrievance({ r1Payload, summaryPayload });
            }
        } else {
            const payload = await addPayloadGetter(data);
            await createGrievance(payload);
        }

        closeModal();
    };

    const onError = (data) => {
        console.log(data);
    };
    //---------------------------------------------------------------------------------
    return (
        <SideOverlay
            show={addNewGrievanceModal}
            onClose={closeModal}
            title={"Add Grievance"}
        >
            <AddEngineer onEngSave={grievanceOptions.onEngSave} />
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
                    <GrievanceSummary />
                    <ConcernedPerson />
                    <ReportedPerson />
                    <AttachmentsAndMedia />
                    <FormButtons
                        onSave={() => {}}
                        onCancel={closeModal}
                    />
                </form>
            </FormProvider>
        </SideOverlay>
    );
});
