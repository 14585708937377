import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {safe_get} from "../../report-visuals";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {FilterHeader} from "../voter-list";
import {useStore} from "../../helpers";
import {RNCheckBoxTree} from "../../components";
import {useLocalStorage} from "react-use";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {VortexSpinner} from "../digital-repository";


export const Booths = observer((props: any) => {

    const {projectStore, userStore} = useStore();
    const {activeUser} = userStore;
    const [t] = useTranslation("user_management");
    const hierarchy = projectStore.getProjectProperty("hierarchy", []);
    const [userPartsandPerms] = useLocalStorage("userpartsandperms");
    const [checked, setChecked] = useState(safe_get(userPartsandPerms, "parts", []));
    const hasPermission = activeUser.role === "root" || activeUser.role === "client"
    const queryClient = useQueryClient();

    // useEffect(() => {
    //     if (hasPermission) {
    //         const booths = [];
    //         hierarchy.map((x) => {
    //             x["children"].map((y) => {
    //                 booths.push(y["id"])
    //             });
    //         });
    //         setChecked(booths);
    //         if (checked.length !== 0) {
    //             // update_assigned_booths_to_server();
    //         }
    //     }
    // }, []);

    const update_assigned_booths_to_server = async () => {
        const res = await userStore.updateUserHierarchy({
            projectid: projectStore.activeProject["id"],
            userid: userStore.activeUser["id"],
            hierarchy: checked
        });
        if (!res.ok) {
            throw new Error("Something went wrong");
        }
        return res;
    };

    const {mutate, isLoading} = useMutation({
        mutationFn: update_assigned_booths_to_server,
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["user_permissions", userStore.activeUser['id']])
            }, 1000);
            toast.success("Booths have been assigned successfully");
            await userStore.setUserDetailsMode(false);
        },
        onError: async () => {
            toast.error("Something went wrong");
        },
    })



    return (
        <div className="flex flex-col justify-between bg-white px-4 py-2 my-4 rounded-lg">
            <VortexSpinner visible={isLoading} />
            <FilterHeader title={"BOOTH FILTER"} content={"Filter by Booth number"}/>
            {checked.length === 0 &&
            <div className="text-red-300 text-md text-bold p-2">
                {t("assign_booths")}
            </div>
            }
            <div className="flex flex-col">
                <RNCheckBoxTree
                    data={hierarchy}
                    checked_nodes={checked}
                    on_node_selection={(nodes) => {
                        setChecked(nodes);
                        console.log({checked})
                    }}
                />
                <button
                    className="px-4 flex py-2 rounded-md bg-indigo-700 text-white self-end"
                    onClick={() => mutate()}
                >
                    {t("assign")}
                </button>
            </div>
        </div>
    );
});
