import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import {observer} from "mobx-react-lite";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    ArrowLeftOnRectangleIcon,
    CircleStackIcon,
    DocumentTextIcon,
    HomeIcon,
    WrenchScrewdriverIcon,
} from "@heroicons/react/24/solid";
import {theme} from "../pages";
import {useStore} from "../helpers";

const drawerWidth = 280;

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window?: () => Window;
}

export const AppDrawer = observer((props: Props) => {

    const {window} = props;
    const rootStore = useStore();
    const {appDrawerToggle} = rootStore;
    const {userStore} = rootStore;
    const navigate = useNavigate();
    const [isClosing, setIsClosing] = useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        rootStore.update_appDrawerToggle(false)
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            rootStore.update_appDrawerToggle(!appDrawerToggle)
        }
    };

    const navigation = [
        {name: 'DashBoard', href: `/`, icon: HomeIcon, current: true},
        {name: 'Data Bank', href: '/databank', icon: CircleStackIcon, current: false},
        {name: 'Reports', href: '/reports/manage', icon: DocumentTextIcon, current: false},
        // {name: 'Reports', href: '/reports', icon: DocumentTextIcon, current: false},
        // {name: 'Tools', href: '/tools', icon: AdjustmentsVerticalIcon, current: false},
        {name: 'Settings', href: '/settings', icon: WrenchScrewdriverIcon, current: false},
        {name: 'Logout', href: '/login', icon: ArrowLeftOnRectangleIcon, current: false}
    ]

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <Box
                component="nav"
                sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={appDrawerToggle}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth, backgroundColor: theme},
                        color: "primary",
                    }}
                >

                    <div className={"flex flex-1 flex-col"}>
                        <div className={"flex flex-row items-center justify-between p-4"}>
                            <img src={"/img/ftp_ai.png"} className={"h-20 w-20"}/>
                            <h2 className={"text-white"}>For The People</h2>
                        </div>
                        <Divider/>
                        <List className={"flex flex-1 flex-col"}>
                            {navigation.map((item, index) => (
                                <ListItem key={index} disablePadding>
                                    <ListItemButton
                                        onClick={async () => {
                                            navigate(item.href)
                                            rootStore.update_appDrawerToggle(false)
                                            if (item.name === "Logout") {
                                                await userStore.logout()
                                            }
                                        }}
                                    >
                                        <ListItemIcon sx={{minWidth: "35px", color: "white"}}>
                                            {<item.icon/>}
                                        </ListItemIcon>
                                        <ListItemText className={"px-2 text-white font-bold"} primary={item.name}/>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                        <Divider/>
                        <div className={"flex flex-col"}>
                            <div className={"flex flex-row items-center justify-evenly p-4"}>
                                <Avatar
                                    alt={userStore.user.name}
                                    src={userStore.user.profileimageuri}
                                    sx={{width: 72, height: 72}}
                                />
                                <div>
                                    <div className={"flex flex-row items-center"}>
                                        <p className="text-white font-bold text-xl"
                                           aria-hidden="true"> {userStore.user.name}</p>
                                        <i className={"fas fa-edit px-2 text-white cursor-pointer"}
                                           onClick={() => {
                                               rootStore.userStore.set_profile_update(true)
                                               handleDrawerToggle();
                                           }}/>
                                    </div>
                                    <p className="text-white italic">{userStore.user.role}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Drawer>
            </Box>
        </Box>
    );
})