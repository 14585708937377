import {observer} from "mobx-react-lite";
import {SubHeading} from "../../../helpers";
import {RNInput, RNSelect} from "../../../components/form-components";
import {GENDERS} from "../../beneficiary-management-v2";
import {Divider} from "@mui/material";
import React from "react";
import {useGrievanceOptions} from "../grievance-v2-hooks";


export const ReportedPerson = observer((props) => {
    const grievanceOptions = useGrievanceOptions();
    return(
        <>
            <SubHeading title={"User Details"}/>
            <div className={"flex flex-col gap-y-3"}>
                <RNInput
                    label={"Name"}
                    name={"reported_by.name"}
                />
                {/* Phone Number */}
                <RNInput
                    label={"Phone Number"}
                    name={"reported_by.phone"}
                />
                {/* Whatsapp Number */}
                <RNInput
                    label={"Whatsapp Number"}
                    name={"reported_by.whatsapp"}
                />
                {/* Voter ID */}
                <RNInput
                    label={"Voter ID"}
                    name={"reported_by.voter_id"}
                />
                {/* Aadhar ID */}
                <RNInput
                    label={"Aadhar ID"}
                    name={"reported_by.aadhar"}
                />
                {/* Date Of Birth */}
                <RNInput
                    label={"Date of Birth"}
                    name={"reported_by.dob"}
                    type="date" // Custom input type as date
                />
                {/* Age */}
                <RNInput
                    label={"Age"}
                    name={"reported_by.age"}
                    type="text" // Custom input type as number
                    defaultValue={0}
                />
                {/* Address */}
                <RNInput
                    label={"Address"}
                    name={"reported_by.address"}
                    multiline
                    rows={4}
                />
                {/* Caste (Custom Select) */}
                <RNSelect
                    label={"Caste"}
                    name={"reported_by.caste"}
                    options={grievanceOptions.casteOptions}
                />
                {/* Gender (Custom Select) */}
                <RNSelect
                    label={"Gender"}
                    name={"reported_by.gender"}
                    options={GENDERS}
                />
                <Divider/>
            </div>
        </>
    )
})