import React from "react";
import { List, ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import GetIcons from "../GetIcons";
import {useStore} from "../../../helpers";
import {formatName} from "../../utitlity/formatName";

const LeaderParty = () => {

  const rootStore = useStore();
  const {influenceLeaderProjectProperty} = useStore();
  const title = influenceLeaderProjectProperty.activeTab.title;  // Get the active tab title
  const tabs = influenceLeaderProjectProperty.tabs;  // Get the list of tab

  return (
    <List>
        {tabs.map((leadersParty) => {
         let title = formatName(leadersParty);
          return (
            <ListItemButton
              onClick={() => {
                influenceLeaderProjectProperty.showOptions(leadersParty);  // Show options for the selected party
              }}
              alignItems="flex-start"
              // Mark as selected if it's the active tab
              selected = {title === leadersParty}
              key={leadersParty}  // Ensure each list item has a unique key
            >
              <ListItemIcon>
                <GetIcons icon_name={title} />  {/* Render the icon */}
              </ListItemIcon>
              <ListItemText>
                <h5>{title}</h5>  {/* Render the formatted party name */}
              </ListItemText>
            </ListItemButton>
          );
        })}
    </List>
  );
};

export default LeaderParty;
