import {observer} from "mobx-react-lite";
import React, {useState} from "react"
import {useStore} from "../../helpers";
import {safe_get} from "../../report-visuals";
import {toJS} from "mobx";
import {useTranslation} from "react-i18next";
import {CMTable, DonutChart3d, DownloadAsExcelSheet, LoadingState} from "../../components";
import {AgGridReact} from "@ag-grid-community/react";
import {Typography, Stack} from "@mui/material";
import {Skeleton} from "@mui/lab";
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {VortexSpinner} from "../digital-repository";

function get_total(data, index) {
    try {
        let totalSurveys = 0
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                totalSurveys += data[i][index];
            }
        }
        return totalSurveys
    } catch (e) {
        return 0
    }
}

const TableSkeleton = () => {
    return (
        <>
            <Stack spacing={1}>
                {/* For variant="text", adjust the height via font-size */}
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="rectangular" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
            </Stack>
        </>
    )

}

const withLoadingSkeleton = (WrappedComponent) => {
    const isLoading = (props) => props.isLoading;

    const WithLoadingSkeleton = (props) => {
        if (isLoading(props)) {
            return <TableSkeleton />;
        }

        return <WrappedComponent {...props} />;
    };

    WithLoadingSkeleton.displayName = `WithLoadingSkeleton(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    return WithLoadingSkeleton;
};

const RenderDemoGraphicsTable = withLoadingSkeleton((props) => {
    return <CMTable{...props}/>
})

const ElectoralDemographics = observer((props: any) => {

    const {c3Store, userStore} = useStore();
    const {selected_booths, demographics_electoral} = c3Store;
    const {projectid} = useParams();
    const [t] = useTranslation("constituency_command_center")
    const header = safe_get(toJS(demographics_electoral), 'booth_gender.header', [])
    const rows = safe_get(toJS(demographics_electoral), 'booth_gender.rows', [])
    const footer = safe_get(toJS(demographics_electoral), 'booth_gender.footer', [])
    const by_age_rows = safe_get(toJS(demographics_electoral), 'booth_age_range.rows', [])
    const party_support = safe_get(toJS(demographics_electoral), 'party_support.rows', [])
    const caste_summary = safe_get(toJS(demographics_electoral), 'caste_summary.rows', [])
    const outsideVoters = safe_get(toJS(demographics_electoral), 'outside_voters.rows', [])
    const isAllowed = userStore.isClient() || userStore.isRoot();

    async function getData() {
        const res = await c3Store.get_electoral_demographics();
        if (!res.ok) {
            throw new Error(res.statusText);
        }
        return res
    }

    const {isLoading} = useQuery({
        queryKey: ["c3-demographics", selected_booths, projectid],
        queryFn: getData
    })

    const booth_cols = [
        {
            Header: "Booth#",
            accessor: (row) => row[0],
            Footer: "Total"
        },
        {
            Header: "Male",
            accessor: (row) => row[1],
            Footer: get_total(rows, 1)
        },
        {
            Header: "Female",
            accessor: (row) => row[2],
            Footer: get_total(rows, 2)
        },
        {
            Header: "Total",
            accessor: (row) => row[3],
            Footer: get_total(rows, 3)
        }
    ]

    const age_cols = [
        {
            Header: "Booth#",
            accessor: (row) => row[0],
            Footer: "Total"
        },
        {
            Header: "18-24",
            accessor: (row) => row[1],
            Footer: get_total(by_age_rows, 1)
        },
        {
            Header: "24-36",
            accessor: (row) => row[2],
            Footer: get_total(by_age_rows, 2)
        },
        {
            Header: "36-51",
            accessor: (row) => row[3],
            Footer: get_total(by_age_rows, 3)
        },
        {
            Header: "51-66",
            accessor: (row) => row[4],
            Footer: get_total(by_age_rows, 4)
        },
        {
            Header: "Above 66",
            accessor: (row) => row[5],
            Footer: get_total(by_age_rows, 5)
        },
        {
            Header: "Total",
            accessor: (row) => row[6],
            Footer: get_total(by_age_rows, 6)
        }
    ]

    const partySupportColumnDefs = [
        {field: "Party", valueGetter: params => params.data[0], flex: 1},
        {field: "Voters", valueGetter: params => params.data[1]},
        {field: "Voter Percentage", valueGetter: params => params.data[2]}
    ];

    const casteColumnDefs = [
        {field: "Caste", valueGetter: params => params.data[0], flex: 1},
        {field: "Voters", valueGetter: params => params.data[1]},
        {field: "Voter Percentage", valueGetter: params => params.data[2]}
    ];

    const ovColumnDefs = [
        {field: "City", valueGetter: params => params.data[0], flex: 1},
        {field: "Voters", valueGetter: params => params.data[1]},
        {field: "Voter Percentage", valueGetter: params => params.data[2]}
    ];

    if(isLoading) return <VortexSpinner visible={isLoading} />;

    //TODO: combining different arrays into a sinlge array of arrays for exporting to an excel file.
    const genderData = [[...demographics_electoral["booth_age_range"]["header"]], ...by_age_rows, [...demographics_electoral["booth_age_range"]["footer"]]];
    const ageData = [...header, ...rows, [...demographics_electoral["booth_gender"]["footer"]]];
    const partyData = [[...demographics_electoral["party_support"]["header"]], ...party_support, [...demographics_electoral["party_support"]["footer"]]];
    const casteData = [[...demographics_electoral["caste_summary"]["header"]], ...caste_summary, [...demographics_electoral["caste_summary"]["footer"]]];
    const ov = [[...demographics_electoral["outside_voters"]["header"]], ...outsideVoters, [...demographics_electoral["outside_voters"]["footer"]]];

    return (
        <>
            <div className={"flex flex-row items-center justify-between"}>
                <div className='font-bold'>{t("voters")}</div>
                <VortexSpinner visible={isLoading}/>
            </div>
            <section className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 my-4">
                <div>
                    <div className={"flex flex-row items-center justify-between"}>
                        <p className="font-bold fp-text">Gender</p>
                        {isAllowed && <DownloadAsExcelSheet data={genderData} name={"Gender Data"}/>}
                    </div>
                    <RenderDemoGraphicsTable
                        isLoading={isLoading}
                        data={rows}
                        columns={booth_cols}
                        pagesize={10}
                    />
                </div>
                {!isLoading ?<DonutChart3d
                    data={safe_get(demographics_electoral, "booth_gender.donut", [])}
                    _label={"label"}
                    value={"value"}
                    title={"Gender"}
                />: <Skeleton variant={"circular"} height={"100%"} width={"75%"} className={"mx-auto"}/>}
                <DonutChart3d
                    data={safe_get(demographics_electoral, "booth_age_range.donut", [])}
                    _label={"label"}
                    value={"value"}
                    title={"Age"}
                />
                <div>
                    <div className={"flex flex-row items-center justify-between"}>
                        <p className="font-bold fp-text">Age Group</p>
                        {isAllowed && <DownloadAsExcelSheet data={ageData} name={"Age Wise Data"}/>}
                    </div>
                    <RenderDemoGraphicsTable
                        isLoading={isLoading}
                        data={by_age_rows}
                        columns={age_cols}
                        pagesize={10}
                    />
                </div>
                <div className="ag-theme-quartz" style={{height: 500}}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <Typography className={"font-bold"} color={"primary"}>Party Support</Typography>
                        {isAllowed && <DownloadAsExcelSheet data={partyData} name={"Party Support"}/>}
                    </div>
                    <AgGridReact
                        //@ts-ignore
                        columnDefs={partySupportColumnDefs}
                        rowData={party_support}
                        pagination
                        paginationPageSize={10}
                    />
                </div>
                <DonutChart3d
                    data={safe_get(demographics_electoral, "party_support.donut", [])}
                    _label={"label"}
                    value={"value"}
                    title={"Party Support"}
                />

                <DonutChart3d
                    data={safe_get(demographics_electoral, "caste_summary.donut", [])}
                    _label={"label"}
                    value={"value"}
                    title={"Caste"}
                />

                <div className="ag-theme-quartz" style={{height: 500}}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <Typography className={"font-bold"} color={"primary"}>Caste Summary</Typography>
                        {isAllowed && <DownloadAsExcelSheet data={casteData} name={"Caste Summary"}/>}
                    </div>
                    <AgGridReact
                        //@ts-ignore
                        columnDefs={casteColumnDefs}
                        rowData={caste_summary}
                        pagination
                        paginationPageSize={10}

                    />
                </div>


                <div className="ag-theme-quartz" style={{height: 500}}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <Typography className={"font-bold"} color={"primary"}>Outside Voters</Typography>
                        {isAllowed && <DownloadAsExcelSheet data={ov} name={"outside-voters"}/>}
                    </div>
                    <AgGridReact
                        //@ts-ignore
                        columnDefs={ovColumnDefs}
                        rowData={outsideVoters}
                        pagination
                        paginationPageSize={10}
                    />
                </div>
                <DonutChart3d
                    data={safe_get(demographics_electoral, "outside_voters.donut", [])}
                    _label={"label"}
                    value={"value"}
                    title={"Outside Voters"}
                />
            </section>
        </>
    )
})

export const C3Demographics = observer((props: any) => {
    const [t] = useTranslation("constituency_command_center");

    return (
        <div className={"pb-4"}>
            <div className='uppercase text-xl font-bold'>{t("demographics")}</div>
            <ElectoralDemographics/>
        </div>
    )
})