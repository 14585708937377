import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useStore} from "../../../helpers/helpers";
import {htmlValidation, TextError} from "../../../helpers/utils";
import {safe_get} from "../../../report-visuals/report-utils";
import {CreateModel, FormButtons} from "../../../components";

export const AddEditCampaign = observer((props: any) => {
    const params = useParams();
    const projectId = params.projectid;
    const {voterReachOutStore} = useStore();
    const {reachOutList, reachOutTargetList} = voterReachOutStore;

    const [messageAutoCompleteOptions, setMessageAutoCompleteOptions] = useState(
        []
    );
    const [targetAutoCompleteOptions, setTargetAutoCompleteOptions] = useState(
        []
    );
    const [selectedMessage, setSelectedMessage] = useState([] as any);
    const [selectedTarget, setSelectedTarget] = useState([]);
    const [selectedName, setSelectedName] = useState("");
    const [isSubmit, setSubmit] = useState(false);
    const [IsCreateModalOpen, setIsCreateModalOpen] = useState(false);

    useEffect(() => {
        if (reachOutList && reachOutList?.length > 0) {
            setMessageAutoCompleteOptions(reachOutList);
        }
    }, []);

    useEffect(() => {
        if (reachOutTargetList && reachOutTargetList?.length > 0) {
            setTargetAutoCompleteOptions(reachOutTargetList);
        }
    }, []);

    const createVroc = async () => {
        const msg = selectedMessage && selectedMessage.map((item) => item.id);
        const tgt = selectedTarget && selectedTarget.map((item) => item.id);
        const msgId = msg.toString();
        const tgtId = tgt.toString();

        const payload = {
            projectid: projectId,
            name: selectedName,
            msgid: +msgId,
            tgtid: +tgtId,
        };

        const res = await voterReachOutStore.create_reachout_Campaign(payload);
        if (res.status === 200) {
            toast.success("Campaign message added successfully");
            props.getReachOutCampaign();
            voterReachOutStore.setAddCampaignStatus(false);
            setIsCreateModalOpen(false);
        }
    };

    const handleSubmit = () => {
        setSubmit(true);
        if (
            selectedName?.length > 0 &&
            selectedMessage?.length > 0 &&
            selectedTarget?.length > 0
        )
            setIsCreateModalOpen(true);
    };

    return (
        <>
            <div className="rounded-lg text-center">
                <div
                    className={
                        "form-control sm:grid sm:grid-cols  sm:items-start d-flex flex-column text-left"
                    }
                >
                    <label
                        htmlFor="name"
                        className="block text-gray-700 rounded-lg text-left font-semibold"
                    >
                        Name of The Campaign
                    </label>
                    <div className="sm:grid sm:grid-cols-1  sm:items-start d-flex flex-column text-left">
                        <TextField
                            value={selectedName}
                            className="w-full"
                            onChange={(event) => {
                                htmlValidation(event);
                                setSelectedName(event.target.value);
                            }}
                        />
                        {isSubmit && selectedName.length <= 0 && (
                            <div className="flex flex-row">
                                <TextError error={"Please Enter the Campaign Name"}/>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row"/>
                </div>
                <div className="pt-3 sm:grid sm:grid-cols-1 sm:items-start d-flex flex-column text-left">
                    <label
                        htmlFor="message"
                        className="block text-gray-700  rounded-lg text-left font-semibold"
                    >
                        Message
                    </label>
                    <Autocomplete
                        multiple={true}
                        id="select-message"
                        value={selectedMessage}
                        options={messageAutoCompleteOptions}
                        autoHighlight={true}
                        getOptionLabel={(option) => safe_get(option, "ds.name", "")}
                        onChange={(event, v) => {
                            htmlValidation(event);
                            setSelectedMessage([...v]);
                        }}
                        renderOption={(props, option) => (
                            <Box
                                key={option["id"]}
                                component="li"
                                sx={{"& > img": {mr: 2, flexShrink: 0}}}
                                {...props}
                            >
                                <img
                                    loading="lazy"
                                    width="40"
                                    src={option["profileimageuri"]}
                                />
                                <div>
                                    <p>{option["ds"]["name"]}</p>
                                    <p>{option["phonenum"]}</p>
                                </div>
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Message"
                                onChange={(event) => {
                                    htmlValidation(event);
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                }}
                            />
                        )}
                    />
                    {isSubmit && selectedMessage.length <= 0 && (
                        <div className="flex flex-row bg-red">
                            <TextError error={"Please Select a Target List"}/>
                        </div>
                    )}
                </div>

                <div className="pt-3 sm:grid sm:grid-cols-1 sm:items-start d-flex flex-column text-left">
                    <label
                        htmlFor="message"
                        className="block text-gray-700  rounded-lg text-left font-semibold"
                    >
                        Target
                    </label>
                    <Autocomplete
                        multiple={true}
                        id="select-target"
                        value={selectedTarget}
                        options={targetAutoCompleteOptions}
                        autoHighlight={true}
                        getOptionLabel={(option) => safe_get(option, "name", "")}
                        onChange={(event, v) => {
                            htmlValidation(event);

                            setSelectedTarget([...v]);
                        }}
                        renderOption={(props, option) => (
                            <Box
                                key={option["id"]}
                                component="li"
                                sx={{"& > img": {mr: 2, flexShrink: 0}}}
                                {...props}
                            >
                                <img
                                    loading="lazy"
                                    width="40"
                                    src={option["profileimageuri"]}
                                />
                                <div>
                                    <p>{option["name"]}</p>
                                    <p>{option["phonenum"]}</p>
                                </div>
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Target"
                                onChange={(event) => {
                                    htmlValidation(event);
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                }}
                            />
                        )}
                    />
                    {isSubmit && selectedTarget.length <= 0 && (
                        <div className="flex flex-row bg-red">
                            <TextError error={"Please Select a Target List"}/>
                        </div>
                    )}
                </div>
                <FormButtons
                    onSave={handleSubmit}
                    onCancel={() => {
                        voterReachOutStore.setAddCampaignStatus(false);
                    }}
                />
            </div>
            {IsCreateModalOpen && (
                <CreateModel
                    isOpen={IsCreateModalOpen}
                    desc={`Are you sure you want to Create a New Campaign ?`}
                    onCreateClick={createVroc}
                    onCancelClick={() => setIsCreateModalOpen(false)}
                />
            )}
        </>
    );
});
