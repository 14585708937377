import {observer} from "mobx-react-lite";
import React, {useMemo} from "react"
import {useTranslation} from "react-i18next";
import {useStore} from "../../helpers/helpers";
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {safe_get} from "../../report-visuals/report-utils";
import HorizontalStackedBarChart from "../../components/Charts/HorizontalStackedBarChart";
import {theme} from "../../pages";
import {DonutChart3d} from "../../components/Charts";
import {CMTable, LoadingState} from "../../components";
import {getIcon} from "./c3-grievance";

export const VisualHeader = ({title}) => {
    return <h3 className="text-base text-lg font-bold leading-6 fp-text">{title}</h3>
}

export const C3Header = ({title, start_date, end_date}) => {
    return (
        <>
            <div className="py-2 text-2xl text-[#009EA6] font-bold text-center">{title}</div>
            <p className="text-sm py-2 italic code">** Click on the filter and choose a date from the calendar.</p>
            {start_date !== "" && <p className={"fp-text"}>Showing the Results from: <strong>{start_date}</strong> to <strong>{end_date}</strong> </p>}
        </>
    )
}

export const C3Beneficiaries = observer((props: any) => {
    const {t} = useTranslation("constituency_command_center");
    const rootStore = useStore();
    const {projectid} = useParams();
    const {c3Store, projectStore} = rootStore;
    const {date_filetrs:{start_date, end_date}, selected_booths, selected_office_persons} = c3Store;
    const propertyValue = projectStore.getProjectProperty("beneficiary", {});
    const status_config = safe_get(propertyValue, "status_config", [])

    const getStatusCols = () => {
        const total = {
            Header: "Total",
            accessor: "total"
        };
        const complaint_cols = status_config.length > 0 ? status_config.map(x => {
            return {
                Header: x.status,
                accessor: x.status,
            }
        }): []

        return [...complaint_cols, total]
    }

    // Define a query function that fetches data from an API
    const fetchData = async () => {
        const payload = {
            projectid: parseInt(projectid),
            start_date: start_date,
            end_date: end_date,
            booths: selected_booths,
            selected_office_persons: selected_office_persons.map((project) => project.id)
        }
        return await c3Store.get_beneficiries_summary(payload);
    };

    // Use the useQuery hook to execute the fetchData function
    const { data, isLoading, error } = useQuery({
        queryKey: ["beneficeries", projectid, start_date, end_date, selected_booths,selected_office_persons],
        queryFn: fetchData
    })

    const by_age = safe_get(data, "data.by_age", []);
    const summary =  safe_get(data, "data.summary", {});
    const by_request_type = safe_get(data, "data.request_type", [])
    const by_caste = safe_get(data, "data.by_caste", [])
    const by_booth = safe_get(data, "data.by_booth", [])
    const by_piv_level1 = safe_get(data, "data.by_piv_level1", [])

    const columns = useMemo(() => {

        const statusCols = getStatusCols();

        return [
            {
                Header: "Beneficiary Request",
                accessor: 'req_type',
            },
            ...statusCols
        ]

    }, [data])

    const by_booth_cols = useMemo(() => {

        const statusCols = getStatusCols();

        return [
            {
                Header: "Booth#",
                accessor: 'booth_number',
            },
            ...statusCols
        ]
    }, [data])

    const summary_donut = useMemo(() => {
        return Object.keys(summary).map(x => {
            if(x === "Total" || x === "oac") return ;
            const y = status_config.find(q => q['status'] === x)
            return {
                label: x,
                value: summary[x],
                color: safe_get(y, "color", "")
            }
        })
    }, [summary])

    if (isLoading) {
        return <LoadingState />
    }

    const stacked_bar_chart_colors = {};
    status_config.forEach(status => stacked_bar_chart_colors[status.status] = status.color);

    console.log({summary_donut})

    if(data.status !== 200) {
        return <div className={"font-bold text-xl p-4 italic text-gray-500"}>Beneficiary data is not available for this project.</div>
    }

    return(
        <>
            <C3Header title={"Beneficiary Insights: A Comprehensive Summary Dashboard"} start_date={start_date} end_date={end_date} />
            <div>
                <VisualHeader title={"Summary"}/>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    {(() => {
                        const keys = Object.keys(summary);
                        if (keys.length > 1) {
                            const secondLastItem = keys.splice(keys.length - 2, 1);
                            keys.unshift(...secondLastItem);
                        }
                        return keys.map((item) => {
                            const index = status_config.findIndex((x) => x.status === item);
                            if (item === "oac" && summary[item] === 0) return null;
                            return (
                                <div
                                    key={item}
                                    className="relative overflow-hidden rounded-lg bg-gray-100 px-4 py-5 shadow"
                                >
                                    <dt>
                                        <div
                                            className="absolute rounded-md p-3"
                                            style={{backgroundColor: theme}}
                                        >
                                            {getIcon(item, status_config)}
                                        </div>
                                        <p className="ml-16 truncate text-sm font-medium text-gray-500">
                                            {item}
                                        </p>
                                    </dt>
                                    <dd className="ml-16 flex items-baseline pb-4">
                                        <p className="text-2xl font-semibold text-gray-900">
                                            {summary[item]}
                                        </p>
                                    </dd>
                                </div>
                            );
                        });
                    })()}
                </dl>
            </div>

            <div className="flex flex-col gap-y-5">
                <DonutChart3d data={summary_donut} _label="label" value="value" title={"Summary"}/>
                <div className="mt-5">
                    <VisualHeader title={"Request Types"}/>
                    <CMTable columns={columns} data={by_request_type} onRowClick={() => {
                    }} pagesize={10}/>
                </div>
                <DonutChart3d data={by_age} _label="age_band" value="val" title="Age"/>
                <div className="">
                    <VisualHeader title={"Booth Wise"}/>
                    <CMTable columns={by_booth_cols} data={by_booth} onRowClick={() => {
                    }} pagesize={10}/>
                </div>
                <DonutChart3d data={by_caste} _label="caste" value="val" title="Caste"/>
                <div className="">
                    <VisualHeader title={"Hobli/ZP"}/>
                    {/*<VerticalStackedBarChart data={by_piv_level1} id="piv_level1" seriesColors={stacked_bar_chart_colors} />*/}
                    <HorizontalStackedBarChart data={by_piv_level1} id="piv_level1"
                                               seriesColors={stacked_bar_chart_colors}/>
                </div>
            </div>
        </>
    )
})