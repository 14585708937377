import React, {memo, useEffect, useState} from "react";
import {safe_get} from "../../report-visuals";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import {Chrono} from "react-chrono";


export const TimelineLog = memo(({timeline_log, mode}: any) => {

    const [chrono, setChrono] = useState(false);

    useEffect(() => {
        setChrono(!chrono);
    }, [timeline_log]);

    return (
        <>
            <div
                className="task-view min-w-full"
                style={{height: "80vh", zIndex: 0}}
            >
                {/* this trick is to add items dynamically to overcome issue with chrono package */}
                {chrono && (
                    <Chrono mode={mode}>
                        {timeline_log
                            ?.map((x, i) => {
                                const image = safe_get(x, "who.profileImage", "");
                                return (
                                    <div key={i} className="flex flex-col w-full">
                                        <div className="flex flex-row items-center">
                                            <Avatar
                                                alt={safe_get(x, "who.name", "K")}
                                                src={image}
                                                sx={{width: "auto", height: 56}}
                                            />
                                            <div className="flex flex-col px-4">
                                                <p className="mt-1 font-bold flex items-center text-md text-purple-600 mr-2 flex-nowrap">
                                                    {safe_get(x, "who.name", "")}
                                                </p>
                                                <p className="mt-1 flex items-center text-sm text-gray-600 mr-2 flex-nowrap">
                                                    {safe_get(x, "status", "")}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            {x["req_title"] && (
                                                <p className="mt-1 flex font-bold  items-center text-sm text-gray-600 mr-2 flex-nowrap">
                                                    {safe_get(x, "req_title", "")}
                                                </p>
                                            )}
                                            <p className="mt-1 flex items-center text-sm text-gray-600 mr-2 flex-nowrap">
                                                {safe_get(x, "comments", "")}
                                            </p>
                                            <p className="mt-1 italic flex items-center text-sm text-gray-400 mr-2 flex-nowrap">
                                                {moment(x["ts"], "DD/MM/YYYY h:mm:ss").format(
                                                    "MMMM Do YYYY, h:mm:ss A"
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                            .reverse()}
                    </Chrono>
                )}

                {/* this trick is to add items dynamically to overcome issue with chrono package */}

                {!chrono && (
                    <Chrono mode={mode}>
                        {timeline_log
                            ?.map((x, i) => {
                                return (
                                    <div key={i} className="flex flex-row w-full">
                                        <div className="flex-shrink-0">
                                            <Avatar
                                                alt={safe_get(x, "who.name", "K")}
                                                src={safe_get(
                                                    x,
                                                    "who.profileImage",
                                                    "/images/user.png"
                                                )}
                                                sx={{width: 56, height: 56}}
                                            />
                                        </div>
                                        <div className="flex flex-col pl-6">
                                            <p className="mt-1 font-bold flex items-center text-md text-purple-600 mr-2 flex-nowrap">
                                                {safe_get(x, "who.name", "")}
                                            </p>
                                            <p className="mt-1 flex items-center text-smtext-gray-500 mr-2 flex-nowrap">
                                                {safe_get(x, "status", "")}
                                            </p>
                                            <p className="mt-1 flex items-center text-sm text-gray-500 mr-2 flex-nowrap">
                                                {safe_get(x, "comments", "")}
                                            </p>
                                            <p className="mt-1 flex items-center text-sm text-gray-400 italic mr-2 flex-nowrap">
                                                {moment(x["ts"], "DD/MM/YYYY h:mm:ss").format(
                                                    "MMMM Do YYYY, h:mm:ss A"
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                            .reverse()}
                    </Chrono>
                )}
            </div>
        </>
    );
})