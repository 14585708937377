import React, {useEffect} from 'react'
import { observer } from "mobx-react-lite";
import { safe_get } from "../../report-visuals";
import { useStore } from "../../helpers";
import { useTranslation } from "react-i18next";
import {DeleteModal, SideOverlay} from "../../components";
import {RenderFamilyMembersV2} from "./beneficiary-family-members";
import {RenderBeneficiaryProfile} from "../beneficiary-management";
import {RenderBeneficiaryRequestsV2} from "./beneficiary-requests";
import {useDeleteBeneficiary} from "./beneficiary-hooks";
import {VortexSpinner} from "../digital-repository";
import {BeneficiaryLogs} from "./beneficiary-logs";


export const BeneficiaryViewV2 = observer(props => {

    const rootStore = useStore();
    const { t } = useTranslation("beneficiary");
    const { beneficiaryStore } = rootStore;
    const { activeBeneficiary, beneficiary_delete, show_benefic } = beneficiaryStore;
    const { r1 } = activeBeneficiary;
    const logs = safe_get(r1, "logs", []);
    const {isLoading, deleteBeneficiary} = useDeleteBeneficiary();

    const close_modal = async () => {
        beneficiaryStore.update_show_benefic(false);
        beneficiaryStore.update_edit_beneficiary_mode(false)
    }

    useEffect(()=>{
        return () => beneficiaryStore.set_active_beneficiary({})
    }, [])

    const onDeleteBeneficiary = async () => {
        await deleteBeneficiary(activeBeneficiary.id)
    }

    const onCancel = () => {
        beneficiaryStore.update_beneficiary_delete(false);
        beneficiaryStore.update_edit_beneficiary_mode(false);
    }

    return (
        <>
            <SideOverlay
                onClose={close_modal}
                show={show_benefic}
                title={t("beneficiary_info")}
            >
                <VortexSpinner visible={isLoading}/>
                <RenderBeneficiaryProfile />
                <RenderBeneficiaryRequestsV2 requests={safe_get(activeBeneficiary, "r1.requests", [])}/>
                <RenderFamilyMembersV2 />
                <BeneficiaryLogs logs={logs}/>
            </SideOverlay>
            <DeleteModal
                onDeleteClick={onDeleteBeneficiary}
                onCancelClick={onCancel}
                isOpen={beneficiary_delete}
                desc={t("delete_beneficiary")}
            />
        </>
    )
})