import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from "./i18n/english.json"
import hindi  from "./i18n/hindi.json"
import kannada  from "./i18n/kannada.json"
import Marathi from "./i18n/marathi.json"
import telugu from "./i18n/telugu.json"

import LanguageDetector from 'i18next-browser-languagedetector';

export const defaultNS = 'english';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
     resources: {
      en: english,
      hi: hindi,
      kn: kannada,
      mr: Marathi,
      tl: telugu
     },
    react: {
    useSuspense: false
  },
    debug: false,
    fallbackLng: 'en',
    defaultNS
  });

export default i18n;