import React from "react";
import {useStore} from "../../helpers";
import {observer} from "mobx-react-lite";
import {useWindowSize} from "react-use";
import {useTranslation} from "react-i18next";
import {VoterCard} from "../manage-voters";
import {FormButtons} from "../../components/cm-components";

export const VoterListWithCheckBox = observer((props:any) => {

    const {voterListStore} = useStore()
    const {Voterlist} = voterListStore;
    const {height} = useWindowSize();
    const {onSelection, onSave, onCancel, selected} = props;
    const[t]=useTranslation("voter_list");

    const isChecked = data => {
        if(selected && selected.length > 0){
            const index = selected.findIndex(x => x.id === data.id)
            return index !== -1
        }
    }
    return (
        <section style={{height: height * 0.70}}>
            <div className="overflow-x-hidden overflow-y-auto h-full ">
                {Voterlist && Voterlist.length > 0 && Voterlist.map((data) => {
                    return (
                        <div key={data["id"]} className="relative mb-2 voter-list">
                            <input
                                type="checkbox"
                                onChange={() =>onSelection(data)}
                                className={"absolute top-8 right-2 p-2 w-8 h-8 bg-red-600 chkbox"}
                                defaultChecked={isChecked(data)}

                                // checked={isChecked(data)}
                            />
                            <VoterCard voter={data}/>
                        </div>
                    );
                })}
            </div>

            <FormButtons
                onSave={onSave}
                onCancel={onCancel}
            />
        </section>
    )
})