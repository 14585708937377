import React, {useEffect, useState} from "react";
import {TailwindModal} from "../../../components";
import {useForm} from "react-hook-form";
import {TextField} from "@mui/material";

const pass_code = "Sharath";

type passCodeType = {
    passCode: string
}
export const HoskoteDashBoard = () => {

    const [open, setOpen] = useState(true);

    useEffect(() => {   
        const codeFromSessionStorage = window.sessionStorage.getItem("code")
        if(codeFromSessionStorage === pass_code) {
            setOpen(false)
        }
    }, [])

    const {register, handleSubmit, formState: {errors}} = useForm<passCodeType>()

    const customValidation = (value) => {
        return value === pass_code || "Passcode is incorrect";
    };

    const onSubmit = (data) => {
        if (data.passCode === pass_code) {
            setOpen(false);
            window.sessionStorage.setItem("code", data.passCode);
        }
    }


    return (
        <>
            <TailwindModal
                isOpen={open}
                children={
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full p-4">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={"mb-2 "}>
                                <TextField
                                    type={"password"}
                                    label={"PassCode"}
                                    variant="filled"
                                    fullWidth
                                    {...register("passCode", {required: true, validate: customValidation})}
                                    error={!!errors.passCode}
                                    helperText={errors.passCode ? "" : ""}
                                />
                                {errors.passCode && <p className={'text-red-600 text-sm text-right py-2'}>{errors.passCode.message}</p>}
                            </div>

                            <div className={"flex justify-end"}>
                                <button
                                    type={"submit"}
                                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() =>{}}
                                >
                                    Authorise
                                </button>
                            </div>
                        </form>
                    </div>
                }
            />
            {
                !open ? <div className={"h-screen"} dangerouslySetInnerHTML={{__html: `<iframe title="Hoskote Analytics" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiYzJjZDNiNzUtNzdhYS00YmYxLTlmYjMtNDI1YzRhZDkwN2IzIiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`}}>

                </div> : null
            }
        </>
    )
}